import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { VIEW, maxItems } from "../../constants";
import Loading from "../Loading";
import Paginator from "../Paginator";
import Request from "../Request";
import { exportRequestAudit, fetchRequestAudit } from "../../sideEffects";

import Sidebar from "./Sidebar";

function RequestAudit({ createAlert }) {
  const [searchParams] = useSearchParams();
  const page = new URLSearchParams(searchParams).get("page"); // extract "page" param value, if it is present
  const currentPage =
    page !== null && !isNaN(parseInt(page, 10)) ? parseInt(page, 10) : 1;

  const [requestState, setRequestState] = useState({ requests: null });
  // Map of request IDs to a boolean indicating whether the request UI item is open
  const [openItems, setOpenItems] = useState({});
  const [fetchIsPending, setFetchIsPending] = useState(false);

  // Isolate filter search params (return a search param string containing only filter search params)
  const filters = useMemo(() => {
    const urlSearchParams = new URLSearchParams(searchParams);
    urlSearchParams.forEach((_, key) => {
      if (
        ![
          "access-type",
          "requested-at-start",
          "requested-at-end",
          "duration",
          "status",
          "last-updated-at-start",
          "last-updated-at-end",
          "end-time-start",
          "end-time-end",
        ].includes(key)
      ) {
        urlSearchParams.delete(key);
      }
    });
    return urlSearchParams.toString();
  }, [searchParams]);

  const handleExport = () => {
    exportRequestAudit({ filters });
  };

  useEffect(() => {
    setFetchIsPending(true);

    const offset = (currentPage - 1) * maxItems[VIEW.REQUEST_AUDIT];
    fetchRequestAudit({ offset, filters })
      .then(({ requests, total }) => {
        setRequestState({ requests, total });
        setOpenItems({});
      })
      .catch((e) => {
        createAlert({
          content: e.message,
          theme: "danger",
          duration: 10000,
        });
      })
      .finally(() => {
        setFetchIsPending(false);
      });
  }, [createAlert, currentPage, filters]);

  if (requestState.requests === null) {
    return <Loading />;
  }

  const toggleItemState = (itemId) => {
    setOpenItems((items) => ({ ...items, [itemId]: !items[itemId] }));
  };

  const lastPage = Math.ceil(requestState.total / maxItems[VIEW.REQUEST_AUDIT]);

  return (
    <div
      data-testid="request-audit"
      className="w-100"
      style={{
        height:
          "calc(100vh - 60px - 32px)" /* full viewport height - navbar height - app padding */,
      }}
    >
      <div className="row h-100">
        <div className="col-3 h-100">
          <Sidebar
            defaultFilters={filters}
            fetchIsPending={fetchIsPending}
            onExport={handleExport}
            requestCount={requestState.total}
          />
        </div>
        {requestState.total === 0 ? (
          <div className="col-md-9 h-100 d-flex flex-column justify-content-center">
            <div className="card text-bg-light mx-auto request-container has-sidebar">
              <div className="card-body text-center">No requests found.</div>
            </div>
          </div>
        ) : (
          <div className="col-md-9 h-100">
            <div
              className="accordion mx-auto request-container has-sidebar"
              style={{
                height:
                  "calc(100% - 50px)" /* full height - paginator height */,
                overflowY: "scroll",
              }}
            >
              {requestState.requests.map(({ id, ...request }) => (
                <Request
                  key={id}
                  id={id}
                  {...request}
                  itemIsOpen={!!openItems[id]}
                  onItemClick={() => toggleItemState(id)}
                  view={VIEW.REQUEST_AUDIT}
                />
              ))}
            </div>
            <Paginator
              ariaLabel="request audit page"
              currentPage={currentPage}
              lastPage={lastPage}
              route="../request_audit"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default RequestAudit;
