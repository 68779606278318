import { Modal } from "bootstrap";
import { useContext, useEffect, useMemo, useRef, useState } from "react";

import Loading from "../Loading";
import RejectionReasonModal from "./RejectionReasonModal";
import Request from "../Request";
import UserIdContext from "../../contexts/UserIdContext";

import { REQUEST_ACTIONS, VIEW } from "../../constants";
import {
  fetchRequestsPendingApproval,
  updateRequestStatus,
} from "../../sideEffects";
import { getTargetedRequestId } from "../../utils";

function Approvals({ createAlert }) {
  const currentUserId = useContext(UserIdContext);

  const [requests, setRequests] = useState(null);
  const [statusUpdateInProgress, setStatusUpdateInProgress] = useState(null);
  // Map of request IDs to a boolean indicating whether the request UI item is open
  const [openItems, setOpenItems] = useState({});

  const [rejectionRequestContext, setRejectionRequestContext] = useState(null);
  const rejectionReasonModalRef = useRef(null);

  useEffect(() => {
    fetchRequestsPendingApproval()
      .then((fetchedRequests) => {
        setRequests(fetchedRequests);
      })
      .catch((e) => {
        createAlert({
          content: e.message,
          theme: "danger",
          duration: 10000,
        });
      });
  }, [createAlert]);

  // Identify requests where requested approver is current user
  const [requestsForUser, requestsForOthers] = useMemo(() => {
    if (requests === null) return [null, null];

    const requestsForUser = [];
    const requestsForOthers = [];

    for (const request of requests) {
      if (request.requested_approver.id === currentUserId) {
        requestsForUser.push(request);
      } else {
        requestsForOthers.push(request);
      }
    }

    if (requestsForUser?.length) {
      // Open the request item in the location, or the first request item for current user
      const requestIdToOpen = getTargetedRequestId(requestsForUser);
      setOpenItems((openItems) => ({ [requestIdToOpen]: true, ...openItems }));
    }

    return [requestsForUser, requestsForOthers];
  }, [requests, currentUserId]);

  if (requests === null) {
    return <Loading />;
  }

  if (!requests.length) {
    return (
      <div className="card text-bg-light request-container position-absolute top-50 start-50 translate-middle">
        <div className="card-body text-center">
          There are no requests waiting for approval.
        </div>
      </div>
    );
  }

  const toggleItemState = (itemId) => {
    setOpenItems((items) => ({ ...items, [itemId]: !items[itemId] }));
  };

  const updateRequest = async ({ requestId, action, rejectionReason }) => {
    if (statusUpdateInProgress !== null) {
      return;
    }

    // If there is an open rejection reason modal, close it.
    const modal = Modal.getInstance(rejectionReasonModalRef.current);
    modal?.hide();

    if (!requests.some((request) => request.id === requestId)) {
      throw Error(`Unable to find request with id ${requestId}`);
    }

    setStatusUpdateInProgress({ requestId, action });
    try {
      await updateRequestStatus({ requestId, action, rejectionReason });

      // Remove the updated request from the request list.
      setRequests((requests) => {
        const requestIndex = requests.findIndex(
          (request) => request.id === requestId
        );
        if (requestIndex === -1) {
          return requests;
        }
        return [
          ...requests.slice(0, requestIndex),
          ...requests.slice(requestIndex + 1),
        ];
      });

      createAlert({
        content: `Request 
          ${
            action === REQUEST_ACTIONS.APPROVE ? "approved" : "rejected"
          } successfully!`,
        theme: "success",
        duration: 10000,
      });
    } catch (e) {
      createAlert({
        content: e.message,
        theme: "danger",
        duration: 10000,
      });
    } finally {
      setStatusUpdateInProgress(null);
    }
  };

  const showRejectionReasonModal = (request, rejectButton) => {
    setRejectionRequestContext(request);
    const modal = Modal.getOrCreateInstance(rejectionReasonModalRef.current, {
      backdrop: "static",
      focus: false,
    });
    modal.show(rejectButton);
  };

  return (
    <>
      <RejectionReasonModal
        modalRef={rejectionReasonModalRef}
        rejectRequest={({ requestId, rejectionReason }) =>
          updateRequest({
            requestId,
            action: REQUEST_ACTIONS.REJECT,
            rejectionReason,
          })
        }
        request={rejectionRequestContext}
      />
      <div data-testid="approvals" className="accordion request-container">
        <div role="region" aria-label="Requests for you to approve">
          {requestsForUser.map(({ id, ...request }) => (
            <Request
              key={id}
              id={id}
              {...request}
              itemIsOpen={!!openItems[id]}
              onApprove={() =>
                updateRequest({
                  requestId: id,
                  action: REQUEST_ACTIONS.APPROVE,
                })
              }
              onItemClick={() => toggleItemState(id)}
              onReject={({ target: rejectButton }) =>
                showRejectionReasonModal({ id, ...request }, rejectButton)
              }
              statusUpdateInProgress={statusUpdateInProgress}
              view={VIEW.APPROVE}
            />
          ))}
        </div>
        {requestsForOthers.length && (
          <div role="region" aria-labelledby="requests-for-others-heading">
            <div
              id="requests-for-others-heading"
              className="card text-bg-primary mb-2 mt-4"
            >
              <div className="card-body text-center">
                <strong>Requests for other approvers</strong>
              </div>
            </div>
            {requestsForOthers.map(({ id, ...request }) => (
              <Request
                key={id}
                id={id}
                {...request}
                itemIsOpen={!!openItems[id]}
                onApprove={() =>
                  updateRequest({
                    requestId: id,
                    action: REQUEST_ACTIONS.APPROVE,
                  })
                }
                onItemClick={() => toggleItemState(id)}
                onReject={({ target: rejectButton }) =>
                  showRejectionReasonModal({ id, ...request }, rejectButton)
                }
                statusUpdateInProgress={statusUpdateInProgress}
                view={VIEW.APPROVE}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default Approvals;
