import { Fragment, useContext, useId, useMemo, useRef, useState } from "react";
import { ViewportList } from "react-viewport-list";

import UserIdContext from "../../contexts/UserIdContext";

import {
  ACCESS_TYPE,
  DATA_ACCESS,
  DATA_LABEL,
  DELTA_LAKE_ACCESS_TYPE,
  PGPARK_ACCESS_TYPE,
  REQUEST_ACTIONS,
  REQUEST_STATUS,
  VIEW,
} from "../../constants";
import { fetchDatabaseCredentials } from "../../sideEffects";
import {
  formatAccessType,
  formatDatabaseAccessType,
  formatDuration,
  formatEnvironment,
  formatISOTimestamp,
  formatRequestAction,
  formatRequestStatus,
  formatTimestamp,
  formatUser,
  sortByName,
  sortByTime,
} from "../../utils";

/* This function takes database access details (a list of partial access
   tables, read access tables, and read/write access tables) and returns
   a list of the tables/columns with default read access, non-default
   read access, and read/write access, respectively.
   
   Each list of tables, and list of columns within tables, will be sorted
   alphabetically by table or column name.
*/
const getDatabaseAccessSummaryFromAccessDetails = ({
  partial_access_tables,
  read_tables,
  read_write_tables,
}) => {
  const defaultReadTables = [];
  const nonDefaultReadTables = [];
  const writeTables = [...read_write_tables];

  for (const table of partial_access_tables) {
    const defaultReadCols = [];
    const nonDefaultReadCols = [];
    const writeCols = [];

    for (const col of table.cols) {
      if (col.access === DATA_ACCESS.READ) {
        if (
          table.default_access === DATA_ACCESS.READ ||
          col.default_access === DATA_ACCESS.READ
        ) {
          defaultReadCols.push(col);
        } else {
          nonDefaultReadCols.push(col);
        }
      } else {
        writeCols.push(col);
      }
    }

    if (defaultReadCols.length) {
      defaultReadCols.sort(sortByName);
      defaultReadTables.push({ ...table, cols: defaultReadCols });
    }
    if (nonDefaultReadCols.length) {
      nonDefaultReadCols.sort(sortByName);
      nonDefaultReadTables.push({ ...table, cols: nonDefaultReadCols });
    }
    if (writeCols.length) {
      writeCols.sort(sortByName);
      writeTables.push({ ...table, cols: writeCols });
    }
  }

  for (const table of read_tables) {
    if (table.default_access === DATA_ACCESS.READ) {
      defaultReadTables.push(table);
    } else {
      nonDefaultReadTables.push(table);
    }
  }

  defaultReadTables.sort(sortByName);
  nonDefaultReadTables.sort(sortByName);
  writeTables.sort(sortByName);

  return { defaultReadTables, nonDefaultReadTables, writeTables };
};

const getLabelBadge = (label) => {
  const colorClass = {
    [DATA_LABEL.L1]: "text-bg-success",
    [DATA_LABEL.L2]: "text-bg-warning",
    [DATA_LABEL.L3]: "text-bg-danger",
  }[label];

  return (
    <span className={`badge ${colorClass} rounded-pill ms-2`}>{label}</span>
  );
};

function DatabaseCredentials({ requestId, requestCreationTime }) {
  const FETCHING_STATUS = {
    NOT_STARTED: "NOT_STARTED",
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETE: "COMPLETE",
    FAILED: "FAILED",
  };

  const [creds, setCreds] = useState(null);
  const [credsFetchingStatus, setCredsFetchingStatus] = useState(
    FETCHING_STATUS.NOT_STARTED
  );

  const fetchCreds = async () => {
    setCredsFetchingStatus(FETCHING_STATUS.IN_PROGRESS);
    const fetchedCreds = await fetchDatabaseCredentials(requestId);
    if (fetchedCreds === null) {
      setCredsFetchingStatus(FETCHING_STATUS.FAILED);
    } else {
      setCredsFetchingStatus(FETCHING_STATUS.COMPLETE);
      setCreds(fetchedCreds);
    }
  };

  const copyToClipboard = (value) => {
    // This is asynchronous but we won't wait for it to return
    navigator.clipboard.writeText(value);
  };

  const containerId = `request-${requestId}-database-credentials`;
  const containerWidth = "750px";
  const containerHeight = "70px";

  switch (credsFetchingStatus) {
    case FETCHING_STATUS.NOT_STARTED:
      return (
        <div id={containerId} aria-live="polite">
          <button
            className="btn btn-outline-success fw-semibold"
            style={{
              height: containerHeight,
              width: containerWidth,
              fontSize: "14px",
            }}
            type="button"
            onClick={fetchCreds}
            aria-label={`Show database credentials for request at ${requestCreationTime}`}
          >
            <i className="bi bi-eye-fill" aria-hidden="true" />
            &nbsp;&nbsp;Show
          </button>
        </div>
      );

    case FETCHING_STATUS.IN_PROGRESS:
      return (
        <div
          id={containerId}
          aria-live="polite"
          className="border border-success rounded d-inline-flex align-items-center justify-content-center fw-semibold text-success"
          style={{
            height: containerHeight,
            width: containerWidth,
            fontSize: "14px",
          }}
        >
          <div
            className="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
          &nbsp;&nbsp;Loading...
        </div>
      );

    case FETCHING_STATUS.COMPLETE:
      return (
        <div
          id={containerId}
          aria-live="polite"
          className="border border-success rounded text-success"
          style={{
            height: containerHeight,
            width: containerWidth,
            fontSize: "14px",
          }}
        >
          <dl
            className="d-inline-flex flex-column"
            style={{ padding: "8px 16px 0" }}
          >
            <div
              className="d-inline-flex align-items-center"
              style={{ columnGap: "10px" }}
            >
              <dt className="fw-semibold">Username:</dt>
              <dd className="font-monospace mb-0">{creds.username}</dd>
              <button
                type="button"
                className="btn p-0 copy-credentials-button"
                aria-label={`Copy database username ${creds.username} to keyboard`}
                onClick={() => copyToClipboard(creds.username)}
              >
                <i className="bi bi-clipboard" aria-hidden="true" />
              </button>
            </div>
            <div
              className="d-inline-flex align-items-center"
              style={{ columnGap: "10px" }}
            >
              <dt className="fw-semibold">Password:</dt>
              <dd className="font-monospace mb-0">{creds.password}</dd>
              <button
                type="button"
                className="btn p-0 copy-credentials-button"
                aria-label={`Copy database password ${creds.password} to keyboard`}
                onClick={() => copyToClipboard(creds.password)}
              >
                <i className="bi bi-clipboard" aria-hidden="true" />
              </button>
            </div>
          </dl>
        </div>
      );

    case FETCHING_STATUS.FAILED:
      return (
        <div
          id={containerId}
          aria-live="polite"
          className="border border-danger rounded d-inline-flex align-items-center justify-content-center fw-semibold text-danger"
          style={{
            height: containerHeight,
            width: containerWidth,
            fontSize: "14px",
          }}
        >
          <i className="bi bi-exclamation-circle-fill" aria-hidden="true" /> Not
          found (access may have expired)
        </div>
      );

    default:
      return null;
  }
}

function Table({ name, label, cols = null, index }) {
  const listGroupItemClass = index % 2 === 0 ? "" : "list-group-item-dark";

  if (cols === null || cols.length === 0) {
    return (
      <li
        className={`list-group-item d-flex justify-content-between align-items-center ${listGroupItemClass}`}
      >
        <span className="flex-grow-1" style={{ wordBreak: "break-all" }}>
          {name}
        </span>
        {getLabelBadge(label)}
      </li>
    );
  }

  const listGroupBorderClass = index % 2 === 0 ? "" : "border-secondary";

  return (
    <li className={`list-group-item ${listGroupItemClass}`}>
      <span style={{ wordBreak: "break-all" }}>{name}</span>{" "}
      <span className="fw-semibold">columns:</span>
      <ul
        className={`list-group list-group-flush border-start ${listGroupBorderClass}`}
      >
        {cols.map(({ name: colName, label: colLabel }) => (
          <li
            key={`table-${name}-col-${colName}`}
            className={`list-group-item d-flex justify-content-between align-items-center ${listGroupItemClass} ${listGroupBorderClass} pe-0`}
            style={{ wordBreak: "break-all" }}
          >
            <span className="flex-grow-1" style={{ wordBreak: "break-all" }}>
              {colName}
            </span>
            {getLabelBadge(colLabel)}
          </li>
        ))}
      </ul>
    </li>
  );
}

function AccessGroup({ title, tables }) {
  const viewportRef = useRef(null); // Used for scrolling through the list of tables
  const accessGroupTitleId = useId();

  return (
    <div className="card p-0" style={{ height: "500px", width: "32%" }}>
      <div
        id={`access-group-${accessGroupTitleId}`}
        className="card-header text-bg-secondary"
      >
        {title}
      </div>
      <ul
        aria-labelledby={`access-group-${accessGroupTitleId}`}
        className="list-group list-group-flush px-0 py-1"
        style={{ overflowY: "auto", overflowX: "clip" }}
        ref={viewportRef}
      >
        <ViewportList viewportRef={viewportRef} items={tables}>
          {(table, index) => (
            <Table key={`table-${table.name}`} index={index} {...table} />
          )}
        </ViewportList>
      </ul>
    </div>
  );
}

function DatabaseAccessSummary({
  access_type: databaseAccessType,
  schema,
  partial_access_tables,
  read_tables,
  read_write_tables,
}) {
  const { defaultReadTables, nonDefaultReadTables, writeTables } = useMemo(
    () =>
      [PGPARK_ACCESS_TYPE.TABLE, DELTA_LAKE_ACCESS_TYPE.TABLE].includes(
        databaseAccessType
      )
        ? getDatabaseAccessSummaryFromAccessDetails({
            partial_access_tables,
            read_tables,
            read_write_tables,
          })
        : {},
    [databaseAccessType, partial_access_tables, read_tables, read_write_tables]
  );

  if (
    [PGPARK_ACCESS_TYPE.ADMIN, PGPARK_ACCESS_TYPE.SUPERUSER].includes(
      databaseAccessType
    )
  ) {
    return (
      <dd className="col-md-10 mb-2">
        {formatDatabaseAccessType(databaseAccessType)}
        {getLabelBadge(DATA_LABEL.L3)}
      </dd>
    );
  }

  // Shift the access details up so they are aligned with the "access details" <dt>
  const rowHeight =
    "calc(var(--bs-body-line-height) * var(--bs-body-font-size) + 0.5rem)";
  return (
    <dd style={{ marginTop: `calc(-1 * ${rowHeight})` }}>
      <div className="row mb-2">
        <div className="col-md-2"></div>
        <div className="col-md-10 mb-2">
          Read/write access to tables in schema "{schema}"
        </div>
      </div>
      <div className="d-flex justify-content-between mb-4">
        <AccessGroup title="Default Read Access" tables={defaultReadTables} />
        <AccessGroup
          title="Non-Default Read Access"
          tables={nonDefaultReadTables}
        />
        <AccessGroup title="Read/Write Access" tables={writeTables} />
      </div>
    </dd>
  );
}

function RequestStatusBadge({ status }) {
  const badgeStyles = { fontSize: "small", marginRight: "15px" };

  switch (status) {
    case REQUEST_STATUS.PENDING:
      return (
        <span className="badge text-bg-info" style={badgeStyles}>
          {formatRequestStatus(status)}
        </span>
      );
    case REQUEST_STATUS.APPROVED:
      return (
        <span className="badge text-bg-success" style={badgeStyles}>
          {formatRequestStatus(status)}
        </span>
      );
    case REQUEST_STATUS.REJECTED:
      return (
        <span className="badge text-bg-danger" style={badgeStyles}>
          {formatRequestStatus(status)}
        </span>
      );
    case REQUEST_STATUS.EXPIRED:
      return (
        <span className="badge text-bg-warning" style={badgeStyles}>
          {formatRequestStatus(status)}
        </span>
      );
    case REQUEST_STATUS.AWAITING_L3_APPROVAL:
      return (
        <span className="badge text-bg-primary" style={badgeStyles}>
          {formatRequestStatus(status)}
        </span>
      );
    case REQUEST_STATUS.CANCELED:
      return (
        <span className="badge text-bg-secondary" style={badgeStyles}>
          {formatRequestStatus(status)}
        </span>
      );
    default:
      return null;
  }
}

function ApproverActionsFooter({
  ariaContext,
  onApproveClick,
  onRejectClick,
  requestId,
  statusUpdateInProgress = null,
}) {
  if (statusUpdateInProgress?.requestId === requestId) {
    return (
      <>
        <hr />
        <div
          className="d-flex justify-content-center"
          style={{ padding: "0 30%", gap: "10px" }}
        >
          <span
            className={`spinner-border ${
              statusUpdateInProgress.action === REQUEST_ACTIONS.APPROVE
                ? "text-success"
                : "text-danger"
            }`}
            role="status"
            style={{ width: "38px", height: "38px" }}
          >
            <span className="visually-hidden">
              {statusUpdateInProgress.action === REQUEST_ACTIONS.APPROVE
                ? "Approving..."
                : "Rejecting..."}
            </span>
          </span>
        </div>
      </>
    );
  }

  // The status of another request is being updated, so this request's actions are disabled
  const disabled = statusUpdateInProgress != null;
  return (
    <>
      <hr />
      <div
        className="d-flex justify-content-center"
        style={{ padding: "0 30%", gap: "10px" }}
      >
        <button
          type="button"
          className={`btn btn-outline-success flex-grow-1 ${
            disabled ? "disabled" : ""
          }`}
          onClick={disabled ? () => {} : onApproveClick}
          aria-label={`Approve ${ariaContext}`}
          aria-disabled={disabled}
          style={{
            fontWeight: "bold",
            cursor: disabled ? "not-allowed" : "auto",
          }}
        >
          Approve
        </button>
        <button
          type="button"
          className={`btn btn-outline-danger flex-grow-1 ${
            disabled ? "disabled" : ""
          }`}
          onClick={disabled ? () => {} : onRejectClick}
          aria-label={`Reject ${ariaContext}`}
          aria-disabled={disabled}
          style={{
            fontWeight: "bold",
            cursor: disabled ? "not-allowed" : "auto",
          }}
        >
          Reject
        </button>
      </div>
    </>
  );
}

function RequesterActionsFooter({
  ariaContext,
  onCancelClick,
  requestId,
  statusUpdateInProgress,
}) {
  if (statusUpdateInProgress?.requestId === requestId) {
    return (
      <>
        <hr />
        <div
          className="d-flex justify-content-center"
          style={{ padding: "0 30%", gap: "10px" }}
        >
          <span
            className="spinner-border text-secondary"
            role="status"
            style={{ width: "38px", height: "38px" }}
          >
            <span className="visually-hidden">Canceling...</span>
          </span>
        </div>
      </>
    );
  }

  // The status of another request is being updated, so this request's actions are disabled
  const disabled = statusUpdateInProgress != null;
  return (
    <>
      <hr />
      <div
        className="d-flex justify-content-center"
        style={{ padding: "0 30%", gap: "10px" }}
      >
        <>
          <button
            type="button"
            className={`btn btn-outline-secondary flex-grow-1 ${
              disabled ? "disabled" : ""
            }`}
            onClick={disabled ? () => {} : onCancelClick}
            aria-label={`Cancel ${ariaContext}`}
            aria-disabled={disabled}
            style={{
              fontWeight: "bold",
              cursor: disabled ? "not-allowed" : "auto",
            }}
          >
            Cancel
          </button>
        </>
      </div>
    </>
  );
}

function Request({
  access_type: accessType,
  actions,
  end_time: endTime,
  environment,
  database_access: dbAccessDetails = null,
  duration,
  id,
  itemIsOpen,
  lease_id: leaseId,
  onApprove,
  onCancel,
  onItemClick,
  onReject,
  reason,
  rejection_reason: rejectionReason,
  requested_approver: requestedApprover,
  status,
  statusUpdateInProgress = null,
  view,
}) {
  const currentUserId = useContext(UserIdContext);

  let requestCreation = null;
  const requestUpdates = [];
  for (const action of actions) {
    if (action.action === REQUEST_ACTIONS.CREATE) {
      requestCreation = action;
    } else {
      requestUpdates.push(action);
    }
  }
  requestUpdates.sort(sortByTime);

  const headingItemId = `heading-request-${id}`;
  const panelItemId = `panel-request-${id}`;

  const headerText = [
    VIEW.APPROVE,
    VIEW.APPROVAL_HISTORY,
    VIEW.REQUEST_AUDIT,
  ].includes(view) ? (
    <>
      <strong>{formatAccessType(accessType)}</strong> access request from&nbsp;
      <strong>{requestCreation.actor.name}</strong> {"// "}
      {formatEnvironment(environment)} {"// "}
      {formatTimestamp(requestCreation.time)}
    </>
  ) : (
    `${formatAccessType(accessType)} Access Request // ${formatEnvironment(
      environment
    )} // ${formatTimestamp(requestCreation.time)}`
  );

  const accessStatusBadge =
    view !== VIEW.ACCESS_END_AUDIT && endTime ? (
      <span className="badge rounded-pill text-bg-dark me-2">
        Active access
      </span>
    ) : null;

  const fields = [
    ["requester", formatUser(requestCreation.actor, currentUserId)],
    ["access type", formatAccessType(accessType)],
    ["environment", formatEnvironment(environment)],
    ["requested at", formatTimestamp(requestCreation.time)],
    ["duration", formatDuration(duration)],
    ["reason", reason],
    ["requested approver", formatUser(requestedApprover, currentUserId)],
  ];

  if (requestUpdates.length) {
    fields.push([
      "updates",
      <ol className="mb-0 ps-3">
        {requestUpdates.map((update, index) => (
          <li key={index}>{formatRequestAction(update, currentUserId)}</li>
        ))}
      </ol>,
    ]);
  }

  let requestStatusBadge = null;
  if (view !== VIEW.ACCESS_END_AUDIT) {
    requestStatusBadge = <RequestStatusBadge status={status} />;
    fields.push(["status", requestStatusBadge]);
  }

  if (view !== VIEW.REQUEST_HISTORY && leaseId) {
    fields.push(["lease ID", leaseId]);
  }
  if (endTime) {
    fields.push(["access ends at", formatISOTimestamp(endTime)]);
  }
  if (rejectionReason) {
    fields.push(["rejection reason", rejectionReason]);
  }

  if ([ACCESS_TYPE.PGPARK, ACCESS_TYPE.DELTA_LAKE].includes(accessType)) {
    const databaseAccessSummary = (
      <DatabaseAccessSummary {...dbAccessDetails} />
    );
    fields.push(["access details", databaseAccessSummary]);
  }

  if (
    view === VIEW.REQUEST_HISTORY &&
    accessType === ACCESS_TYPE.PGPARK &&
    status === REQUEST_STATUS.APPROVED
  ) {
    const databaseCreds = (
      <DatabaseCredentials
        requestId={id}
        requestCreationTime={requestCreation.time}
      />
    );
    fields.push(["database credentials", databaseCreds]);
  }

  let actionsFooter = null;
  if (view === VIEW.APPROVE) {
    actionsFooter = (
      <ApproverActionsFooter
        ariaContext={`${formatAccessType(accessType)} request from ${formatUser(
          requestCreation.actor,
          currentUserId
        )} at ${requestCreation.time}`}
        requestId={id}
        onApproveClick={onApprove}
        onRejectClick={onReject}
        statusUpdateInProgress={statusUpdateInProgress}
      />
    );
  }
  if (
    view === VIEW.REQUEST_HISTORY &&
    [REQUEST_STATUS.PENDING, REQUEST_STATUS.AWAITING_L3_APPROVAL].includes(
      status
    )
  ) {
    actionsFooter = (
      <RequesterActionsFooter
        ariaContext={`${formatAccessType(accessType)} request created at ${
          requestCreation.time
        }`}
        requestId={id}
        onCancelClick={onCancel}
        statusUpdateInProgress={statusUpdateInProgress}
      />
    );
  }

  return (
    <div id={`request-${id}`} className="accordion-item mb-2 rounded-2">
      <h2 id={headingItemId} className="accordion-header">
        <button
          className={`d-flex rounded-2 accordion-button ${
            itemIsOpen ? "" : "collapsed"
          }`}
          type="button"
          aria-expanded={itemIsOpen}
          aria-controls={panelItemId}
          onClick={onItemClick}
        >
          <span className="flex-grow-1 fw-semibold">{headerText}</span>
          {accessStatusBadge}
          {requestStatusBadge}
        </button>
      </h2>
      <div
        id={panelItemId}
        className="accordion-collapse"
        aria-labelledby={headingItemId}
        style={{ display: itemIsOpen ? "block" : "none" }}
      >
        <div className="accordion-body">
          <dl className="row mb-0">
            {fields.map(([key, val]) => (
              <Fragment key={key}>
                <dt className="col-md-2 text-capitalize fw-semibold mb-2">
                  {key}:
                </dt>
                {key === "access details" ? (
                  val
                ) : (
                  <dd
                    className="col-md-10 mb-2"
                    style={{ whiteSpace: "pre-wrap", overflowWrap: "anywhere" }}
                  >
                    {val}
                  </dd>
                )}
              </Fragment>
            ))}
          </dl>
          {actionsFooter}
        </div>
      </div>
    </div>
  );
}

export default Request;
