import { useEffect, useMemo, useState } from "react";

import Loading from "../Loading";
import Request from "../Request";

import {
  REQUEST_ACTIONS,
  REQUEST_STATUS,
  VIEW,
  maxItems,
} from "../../constants";
import { fetchRequestHistory, updateRequestStatus } from "../../sideEffects";
import { getTargetedRequestId } from "../../utils";

function RequestHistory({ createAlert }) {
  const [requests, setRequests] = useState(null);
  const [statusUpdateInProgress, setStatusUpdateInProgress] = useState(null);
  // Map of request IDs to a boolean indicating whether the request UI item is open
  const [openItems, setOpenItems] = useState({});

  useEffect(() => {
    fetchRequestHistory()
      .then((fetchedRequests) => {
        setRequests(fetchedRequests);
      })
      .catch((e) => {
        createAlert({
          content: e.message,
          theme: "danger",
          duration: 10000,
        });
      });
  }, [createAlert]);

  // Separate pending and non-pending requests
  const [pendingRequests, nonPendingRequests] = useMemo(() => {
    if (requests === null) return [null, null];

    const pendingRequests = [];
    const nonPendingRequests = [];

    for (const request of requests) {
      if (
        [REQUEST_STATUS.PENDING, REQUEST_STATUS.AWAITING_L3_APPROVAL].includes(
          request.status
        )
      ) {
        pendingRequests.push(request);
      } else {
        nonPendingRequests.push(request);
      }
    }

    // Open the request item in the location, or the first pending request item,
    // or the first non-pending item
    const requestIdToOpen = getTargetedRequestId(
      pendingRequests,
      nonPendingRequests
    );
    setOpenItems((openItems) => ({ [requestIdToOpen]: true, ...openItems }));

    return [pendingRequests, nonPendingRequests];
  }, [requests]);

  if (requests === null) {
    return <Loading />;
  }

  if (!requests.length) {
    return (
      <div className="card text-bg-light request-container position-absolute top-50 start-50 translate-middle">
        <div className="card-body text-center">
          You have not made any requests yet.
        </div>
      </div>
    );
  }

  const toggleItemState = (itemId) => {
    setOpenItems((items) => ({ ...items, [itemId]: !items[itemId] }));
  };

  const cancelRequest = async (requestId) => {
    if (statusUpdateInProgress !== null) {
      return;
    }

    if (!requests.some((request) => request.id === requestId)) {
      throw Error(`Unable to find request with id ${requestId}`);
    }

    setStatusUpdateInProgress({ requestId });
    try {
      const canceledRequest = await updateRequestStatus({
        requestId,
        action: REQUEST_ACTIONS.CANCEL,
      });

      // Update the canceled request in the request list.
      setRequests((requests) => {
        const requestIndex = requests.findIndex(
          (request) => request.id === requestId
        );
        if (requestIndex === -1) {
          return requests;
        }
        return [
          ...requests.slice(0, requestIndex),
          canceledRequest,
          ...requests.slice(requestIndex + 1),
        ];
      });

      createAlert({
        content: "Request canceled successfully!",
        theme: "success",
        duration: 10000,
      });
    } catch (e) {
      createAlert({
        content: e.message,
        theme: "danger",
        duration: 10000,
      });
    } finally {
      setStatusUpdateInProgress(null);
    }
  };

  return (
    <div data-testid="request-history" className="accordion request-container">
      <div role="region" aria-labelledby="pending-requests-heading">
        <div
          id="pending-requests-heading"
          className="card text-bg-primary mb-2"
        >
          <div className="card-body text-center">
            <strong>Requests Waiting For Approval</strong>
          </div>
        </div>
        {pendingRequests.map(({ id, ...request }) => (
          <Request
            key={id}
            id={id}
            {...request}
            itemIsOpen={!!openItems[id]}
            onCancel={() => cancelRequest(id)}
            onItemClick={() => toggleItemState(id)}
            statusUpdateInProgress={statusUpdateInProgress}
            view={VIEW.REQUEST_HISTORY}
          />
        ))}
      </div>
      <div role="region" aria-labelledby="non-pending-requests-heading">
        <div
          id="non-pending-requests-heading"
          className="card text-bg-primary mb-2 mt-4"
        >
          <div className="card-body text-center">
            <strong>Approved / Rejected / Expired / Canceled Requests</strong>
          </div>
        </div>
        {nonPendingRequests.map(({ id, ...request }) => (
          <Request
            key={id}
            id={id}
            {...request}
            itemIsOpen={!!openItems[id]}
            onItemClick={() => toggleItemState(id)}
            view={VIEW.REQUEST_HISTORY}
          />
        ))}
      </div>
      {requests.length === maxItems[VIEW.REQUEST_HISTORY] && (
        <div className="card text-bg-light w-75 mx-auto mt-4">
          <div
            className="card-body text-center"
            style={{ fontSize: "smaller" }}
          >
            {`Only your ${
              maxItems[VIEW.REQUEST_HISTORY]
            } most recent requests are shown.`}
          </div>
        </div>
      )}
    </div>
  );
}

export default RequestHistory;
