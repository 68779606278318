import { useEffect, useState } from "react";

import Loading from "../Loading";
import Request from "../Request";

import { VIEW, maxItems } from "../../constants";
import { fetchApprovalHistory } from "../../sideEffects";
import { getTargetedRequestId } from "../../utils";

function ApprovalHistory({ createAlert }) {
  const [approvals, setApprovals] = useState(null);
  // Map of request IDs to a boolean indicating whether the request UI item is open
  const [openItems, setOpenItems] = useState({});

  useEffect(() => {
    fetchApprovalHistory()
      .then((fetchedApprovals) => {
        if (fetchedApprovals?.length) {
          const requestIdToOpen = getTargetedRequestId(fetchedApprovals);
          setOpenItems({ [requestIdToOpen]: true });
        }
        setApprovals(fetchedApprovals);
      })
      .catch((e) => {
        createAlert({
          content: e.message,
          theme: "danger",
          duration: 10000,
        });
      });
  }, [createAlert]);

  if (approvals === null) {
    return <Loading />;
  }

  if (!approvals.length) {
    return (
      <div className="card text-bg-light request-container position-absolute top-50 start-50 translate-middle">
        <div className="card-body text-center">
          You have not approved or rejected any requests yet.
        </div>
      </div>
    );
  }

  const toggleItemState = (itemId) => {
    setOpenItems((items) => ({ ...items, [itemId]: !items[itemId] }));
  };

  return (
    <div data-testid="approval-history" className="accordion request-container">
      {approvals.map(({ id, ...approval }) => (
        <Request
          key={id}
          id={id}
          {...approval}
          itemIsOpen={!!openItems[id]}
          onItemClick={() => toggleItemState(id)}
          view={VIEW.APPROVAL_HISTORY}
        />
      ))}
      {approvals.length === maxItems[VIEW.APPROVAL_HISTORY] && (
        <div className="card text-bg-light w-75 mx-auto">
          <div
            className="card-body text-center"
            style={{ fontSize: "smaller" }}
          >
            {`Only your ${
              maxItems[VIEW.APPROVAL_HISTORY]
            } most recent approvals and rejections are shown.`}
          </div>
        </div>
      )}
    </div>
  );
}

export default ApprovalHistory;
