import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import "@fontsource/source-sans-pro/latin.css";
import "./index.scss";
import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary";

import { setupServerMock } from "./testUtils";

Sentry.init({
  dsn: "https://e0d0703f571b4ed9a33a2bf9b229eea7@o197545.ingest.sentry.io/4505332105216000",
  integrations: [new Sentry.Replay()],
  environment: process.env.NODE_ENV,
  // Session Replay
  replaysSessionSampleRate: 0.1, // Set general session sampling rate to 10%.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const boundary = <ErrorBoundary />;

(async () => {
  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: "615b817dbe623f249db2ba97",
  });

  root.render(
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={boundary}>
        <BrowserRouter>
          <LaunchDarklyProvider>
            <App />
          </LaunchDarklyProvider>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
})();

// API mocking for local development
if (process.env.NODE_ENV === "development") {
  setupServerMock({
    role: process.env.REACT_APP_ROLE,
    environment: "development",
  });
}
