import { DateTime } from "luxon";
import { Tooltip } from "bootstrap";
import isEqual from "lodash.isequal";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ACCESS_DURATION, ACCESS_TYPE, REQUEST_STATUS } from "../../constants";
import {
  formatAccessType,
  formatDuration,
  formatRequestStatus,
} from "../../utils";

export const ACCESS_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const formatAccessStatus = (status) =>
  status === ACCESS_STATUS.ACTIVE ? "Active access" : "No active access";

export const localTimestampToUTC = (localTimestamp) => {
  if (!localTimestamp) return "";
  const localDateTime = DateTime.fromISO(localTimestamp);
  return localDateTime.setZone("utc").toFormat("yyyy-MM-dd'T'T");
};

export const utcTimestampToLocal = (utcTimestamp) => {
  if (!utcTimestamp) return "";
  const utcDateTime = DateTime.fromISO(utcTimestamp, { zone: "utc" });
  return utcDateTime.toLocal().toFormat("yyyy-MM-dd'T'T");
};

function NestedCheckboxes({
  name,
  valid,
  label,
  value,
  defaultChecked,
  disabled,
  suboptions,
}) {
  const containerRef = useRef(null);
  const parentRef = useRef(null);

  const parentId = `${name}-${value}`;
  const childIds = suboptions.map(
    ({ value: subvalue }) => `${parentId}-${subvalue}`
  );

  const onParentChange = (e) => {
    const children = containerRef.current.querySelectorAll(
      `input:not(#${parentId})`
    );
    for (const child of children) {
      child.checked = e.target.checked;
    }
  };

  const onChildChange = useCallback(() => {
    const checkedChildren = containerRef.current.querySelectorAll(
      `input:not(#${parentId}):checked`
    );
    if (checkedChildren.length === 0) {
      parentRef.current.checked = false;
      parentRef.current.indeterminate = false;
    } else if (checkedChildren.length === suboptions.length) {
      parentRef.current.checked = true;
      parentRef.current.indeterminate = false;
    } else {
      parentRef.current.checked = false;
      parentRef.current.indeterminate = true;
    }
  }, [containerRef, parentId, parentRef, suboptions.length]);

  // When the component first renders, update the state of the parent checkbox
  useEffect(() => {
    onChildChange();
  }, [onChildChange]);

  return (
    <div ref={containerRef} className="form-check">
      <input
        ref={parentRef}
        className={valid ? "form-check-input" : "form-check-input is-invalid"}
        type="checkbox"
        value={value}
        id={parentId}
        name={name}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onParentChange}
        aria-controls={childIds.join(" ")}
      />
      <label className="form-check-label" htmlFor={parentId}>
        {label}
      </label>
      {suboptions.map(
        (
          {
            label: sublabel,
            value: subvalue,
            defaultChecked: subdefaultChecked,
            disabled: subdisabled,
          },
          index
        ) => (
          <div key={subvalue} className="form-check nested">
            <input
              className={
                valid ? "form-check-input" : "form-check-input is-invalid"
              }
              type="checkbox"
              value={subvalue}
              id={childIds[index]}
              name={name}
              defaultChecked={subdefaultChecked}
              disabled={subdisabled}
              onChange={onChildChange}
            />
            <label className="form-check-label" htmlFor={childIds[index]}>
              {sublabel}
            </label>
          </div>
        )
      )}
    </div>
  );
}

function RequiredCheckboxGroup({ description, name, options, valid }) {
  // This UX pattern for a group of checkboxes where one must be selected comes from
  // https://blog.tenon.io/accessible-validation-of-checkbox-and-radiobutton-groups/
  // This might be a good use case for a multiselect input instead, but these come
  // with their own a11y/UX challenges.
  return (
    <fieldset className="default-fieldset">
      <legend className="col-form-label pt-0 default-legend">
        {description}
        <div role="alert" className={valid ? "d-none" : "visually-hidden"}>
          You must select at least one {description} option.
        </div>
      </legend>
      {options.map(
        ({ label, value, defaultChecked, disabled, suboptions = [] }) =>
          suboptions.length === 0 ? (
            <div key={value} className="form-check">
              <input
                className={
                  valid ? "form-check-input" : "form-check-input is-invalid"
                }
                type="checkbox"
                value={value}
                id={`${name}-${value}`}
                name={name}
                defaultChecked={defaultChecked}
                disabled={disabled}
              />
              <label className="form-check-label" htmlFor={`${name}-${value}`}>
                {label}
              </label>
            </div>
          ) : (
            <NestedCheckboxes
              key={value}
              name={name}
              label={label}
              value={value}
              defaultChecked={defaultChecked}
              disabled={disabled}
              suboptions={suboptions}
              valid={valid}
            />
          )
      )}
      <div
        aria-hidden="true"
        className="invalid-feedback"
        style={{
          display: valid ? "none" : "block",
        }}
      >
        You must select at least one option.
      </div>
    </fieldset>
  );
}

function DateTimeRangeGroup({
  defaultEndValue,
  defaultStartValue,
  description,
  name,
  valid,
}) {
  return (
    <fieldset className="default-fieldset">
      <legend className="col-form-label pt-0 default-legend">
        {description}
      </legend>
      <label className="form-label" htmlFor={`${name}-start`}>
        After
      </label>
      <input
        aria-errormessage={`${name}-start-invalid-feedback`}
        aria-invalid={!valid}
        className={valid ? "form-control mb-3" : "form-control is-invalid"}
        type="datetime-local"
        id={`${name}-start`}
        name={`${name}-start`}
        defaultValue={defaultStartValue}
      />
      <div
        id={`${name}-start-invalid-feedback`}
        className="invalid-feedback mb-2"
      >
        After time must be earlier than before time.
      </div>
      <label className="form-label" htmlFor={`${name}-end`}>
        Before
      </label>
      <input
        aria-errormessage={`${name}-end-invalid-feedback`}
        aria-invalid={!valid}
        className={valid ? "form-control mb-1" : "form-control is-invalid"}
        type="datetime-local"
        id={`${name}-end`}
        name={`${name}-end`}
        defaultValue={defaultEndValue}
      />
      <div id={`${name}-end-invalid-feedback`} className="invalid-feedback">
        After time must be earlier than before time.
      </div>
    </fieldset>
  );
}

function ButtonWithTooltip({ tooltipText, tooltipPlacement, ...props }) {
  const ref = useRef(null);

  useEffect(() => {
    Tooltip.getOrCreateInstance(ref.current);
  }, []);

  const handleDismissal = (e) => {
    if (e.key === "Esc" || e.key === "Escape") {
      e.preventDefault();
      const tooltip = Tooltip.getInstance(ref.current);
      tooltip.hide();
    }
  };

  return (
    <button
      {...props}
      data-bs-toggle="tooltip"
      data-bs-placement={tooltipPlacement}
      data-bs-title={tooltipText}
      onKeyDown={handleDismissal}
      ref={ref}
    />
  );
}

// `defaultFilters` should be a string in the URL search parameter format, containing *only*
// key-value pairs corresponding to the filter inputs
function Sidebar({
  defaultFilters = "",
  fetchIsPending,
  onExport,
  requestCount,
}) {
  const [formState, setFormState] = useState({
    dirty: false,
    invalidFields: [],
  });
  const navigate = useNavigate();
  const formRef = useRef(null);

  const parsedDefaultFilters = new URLSearchParams(defaultFilters);
  // If no default values are provided for the filter group, select all values in the group
  const defaultAccessTypes = new Set(
    parsedDefaultFilters.has("access-type")
      ? parsedDefaultFilters.getAll("access-type")
      : Object.values(ACCESS_TYPE)
  );
  const defaultRequestedAtStart = utcTimestampToLocal(
    parsedDefaultFilters.get("requested-at-start")
  );
  const defaultRequestedAtEnd = utcTimestampToLocal(
    parsedDefaultFilters.get("requested-at-end")
  );
  // If no default values are provided for the filter group, select all values in the group
  const defaultDurations = new Set(
    parsedDefaultFilters.has("duration")
      ? parsedDefaultFilters.getAll("duration")
      : ACCESS_DURATION
  );
  // If no default values are provided for the filter group, select all values in the group
  const defaultStatuses = new Set(
    parsedDefaultFilters.has("status")
      ? parsedDefaultFilters.getAll("status")
      : [...Object.values(REQUEST_STATUS), ...Object.values(ACCESS_STATUS)]
  );
  const defaultLastUpdatedAtStart = utcTimestampToLocal(
    parsedDefaultFilters.get("last-updated-at-start")
  );
  const defaultLastUpdatedAtEnd = utcTimestampToLocal(
    parsedDefaultFilters.get("last-updated-at-end")
  );

  const validateForm = () => {
    const invalidFields = [];
    const formData = new FormData(formRef.current);

    // Verify that at least one checkbox is checked in the group
    if (!formData.has("access-type")) {
      invalidFields.push("access-type");
    }

    // Verify that the start time is before the end time
    if (
      formData.get("requested-at-start") &&
      formData.get("requested-at-end")
    ) {
      const start = DateTime.fromISO(formData.get("requested-at-start"));
      const end = DateTime.fromISO(formData.get("requested-at-end"));
      if (start > end) {
        invalidFields.push("requested-at-start", "requested-at-end");
      }
    }

    // Verify that at least one checkbox is checked in the group
    if (!formData.has("duration")) {
      invalidFields.push("duration");
    }

    // Verify that at least one checkbox is checked in the group
    if (!formData.has("status")) {
      invalidFields.push("status");
    }

    // Verify that the start time is before the end time
    if (
      formData.get("last-updated-at-start") &&
      formData.get("last-updated-at-end")
    ) {
      const start = DateTime.fromISO(formData.get("last-updated-at-start"));
      const end = DateTime.fromISO(formData.get("last-updated-at-end"));
      if (start > end) {
        invalidFields.push("last-updated-at-start", "last-updated-at-end");
      }
    }

    return invalidFields;
  };

  // Validate the form and check if the current filters are different from the default filters
  const maybeUpdateFormState = () => {
    const invalidFields = validateForm();

    const formData = new FormData(formRef.current);
    const dirty =
      !isEqual(new Set(formData.getAll("access-type")), defaultAccessTypes) ||
      formData.get("requested-at-start") !== defaultRequestedAtStart ||
      formData.get("requested-at-end") !== defaultRequestedAtEnd ||
      !isEqual(new Set(formData.getAll("duration")), defaultDurations) ||
      !isEqual(new Set(formData.getAll("status")), defaultStatuses) ||
      formData.get("last-updated-at-start") !== defaultLastUpdatedAtStart ||
      formData.get("last-updated-at-end") !== defaultLastUpdatedAtEnd;

    setFormState({ dirty, invalidFields });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formState.dirty || fetchIsPending) {
      return;
    }

    const invalidFields = validateForm();
    if (invalidFields.length !== 0) {
      setFormState({ dirty: true, invalidFields });
      const firstInvalidElement = formRef.current.querySelector(
        `[name="${invalidFields[0]}"`
      );
      firstInvalidElement.focus();
      return;
    }
    setFormState({ dirty: false, invalidFields: [] });

    const formData = new FormData(formRef.current);
    const filters = new URLSearchParams(formData);
    // If all filters in the group are selected, avoid including them in the query parameters, for conciseness
    if (
      filters.getAll("access-type").length === Object.values(ACCESS_TYPE).length
    ) {
      filters.delete("access-type");
    }
    // If the filter is in use, convert the timestamp to UTC; otherwise, avoid including it in the query parameters
    if (filters.get("requested-at-start")) {
      filters.set(
        "requested-at-start",
        localTimestampToUTC(filters.get("requested-at-start"))
      );
    } else {
      filters.delete("requested-at-start");
    }
    // If the filter is in use, convert the timestamp to UTC; otherwise, avoid including it in the query parameters
    if (filters.get("requested-at-end")) {
      filters.set(
        "requested-at-end",
        localTimestampToUTC(filters.get("requested-at-end"))
      );
    } else {
      filters.delete("requested-at-end");
    }
    // If all filters in the group are selected, avoid including them in the query parameters, for conciseness
    if (filters.getAll("duration").length === ACCESS_DURATION.length) {
      filters.delete("duration");
    }
    // If all filters in the group are selected, avoid including them in the query parameters, for conciseness
    if (
      filters.getAll("status").length ===
      Object.values(REQUEST_STATUS).length + Object.values(ACCESS_STATUS).length
    ) {
      filters.delete("status");
    }
    // If the filter is in use, convert the timestamp to UTC; otherwise, avoid including it in the query parameters
    if (filters.get("last-updated-at-start")) {
      filters.set(
        "last-updated-at-start",
        localTimestampToUTC(filters.get("last-updated-at-start"))
      );
    } else {
      filters.delete("last-updated-at-start");
    }
    // If the filter is in use, convert the timestamp to UTC; otherwise, avoid including it in the query parameters
    if (filters.get("last-updated-at-end")) {
      filters.set(
        "last-updated-at-end",
        localTimestampToUTC(filters.get("last-updated-at-end"))
      );
    } else {
      filters.delete("last-updated-at-end");
    }

    navigate(`../request_audit?${filters.toString()}`);
  };

  // There is a small bug here: this does not trigger an update to the "indeterminate" attribute of the
  // parent checkbox in a `NestedCheckbox` component, so this checkbox may not accurately reflect
  // the state of its nested children after the form is cleared.
  const handleClear = () => {
    formRef.current.reset();
    maybeUpdateFormState();
  };

  const clearButton =
    formState.dirty && !fetchIsPending ? (
      <button
        type="button"
        className="btn btn-outline-primary ms-1"
        form="search-filter-form"
        onClick={handleClear}
      >
        Clear changes
      </button>
    ) : (
      <ButtonWithTooltip
        type="button"
        className="btn btn-outline-primary ms-1 disabled"
        form="search-filter-form"
        aria-disabled="true"
        style={{ cursor: "not-allowed", pointerEvents: "auto" }}
        tooltipPlacement="bottom"
        tooltipText="No filter changes to clear"
      >
        Clear changes
      </ButtonWithTooltip>
    );

  let applyButton;
  if (fetchIsPending) {
    applyButton = (
      <button
        type="submit"
        className="btn btn-primary ms-1 disabled"
        form="search-filter-form"
        aria-disabled="true"
        style={{ cursor: "not-allowed", pointerEvents: "auto" }}
      >
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        &nbsp;Loading...
      </button>
    );
  } else if (!formState.dirty) {
    applyButton = (
      <ButtonWithTooltip
        type="submit"
        className="btn btn-primary ms-1 disabled"
        form="search-filter-form"
        aria-disabled="true"
        style={{ cursor: "not-allowed", pointerEvents: "auto" }}
        tooltipPlacement="bottom"
        tooltipText="No filter changes to apply"
      >
        Apply
      </ButtonWithTooltip>
    );
  } else {
    applyButton = (
      <button
        type="submit"
        className="btn btn-primary ms-1"
        form="search-filter-form"
      >
        Apply
      </button>
    );
  }

  let exportButton;
  if (fetchIsPending) {
    exportButton = (
      <button
        type="button"
        className="btn btn-primary w-100 mt-2 disabled"
        aria-disabled="true"
        style={{ cursor: "not-allowed" }}
      >
        Export {requestCount} result{requestCount === 1 ? "" : "s"} to CSV
      </button>
    );
  } else if (requestCount === 0) {
    exportButton = (
      <button
        type="button"
        className="btn btn-primary w-100 mt-2 disabled"
        aria-disabled="true"
        style={{ cursor: "not-allowed" }}
      >
        No results to export
      </button>
    );
  } else if (formState.dirty || formState.invalidFields.length !== 0) {
    exportButton = (
      <ButtonWithTooltip
        type="button"
        className="btn btn-primary w-100 mt-2 disabled"
        aria-disabled="true"
        style={{ cursor: "not-allowed", pointerEvents: "auto" }}
        tooltipPlacement="bottom"
        tooltipText="You must apply or clear your filter changes before exporting results."
      >
        Export {requestCount} result{requestCount === 1 ? "" : "s"} to CSV
      </ButtonWithTooltip>
    );
  } else {
    exportButton = (
      <button
        type="button"
        className="btn btn-primary w-100 mt-2"
        onClick={onExport}
      >
        Export {requestCount} result{requestCount === 1 ? "" : "s"} to CSV
      </button>
    );
  }

  return (
    <div className="h-100" data-testid="request-audit-sidebar">
      <div
        className="card text-bg-light mx-auto h-100"
        style={{
          maxHeight:
            "calc(100% - 50px)" /* full height - export button height */,
        }}
      >
        <h5
          className="card-header d-flex justify-content-between"
          id="sidebar-header"
        >
          <span>Filter</span>
          <span role="status" className="badge bg-secondary">
            {requestCount} result{requestCount === 1 ? "" : "s"}
          </span>
        </h5>
        <div
          className="card-body"
          style={{
            maxHeight:
              "calc(100% - 100px)" /* full height - header+footer height */,
            overflowY: "scroll",
          }}
        >
          <form
            id="search-filter-form"
            aria-labelledby="sidebar-header"
            name="search-filter"
            ref={formRef}
            onInput={maybeUpdateFormState}
            onSubmit={handleSubmit}
          >
            <RequiredCheckboxGroup
              description="Access type"
              name="access-type"
              options={Object.values(ACCESS_TYPE).map((accessType) => ({
                value: accessType,
                label: formatAccessType(accessType),
                defaultChecked: defaultAccessTypes.has(accessType),
                disabled: fetchIsPending,
              }))}
              valid={!formState.invalidFields.includes("access-type")}
            />
            <DateTimeRangeGroup
              defaultEndValue={defaultRequestedAtEnd}
              defaultStartValue={defaultRequestedAtStart}
              description="Requested time"
              name="requested-at"
              valid={!formState.invalidFields.includes("requested-at-start")}
            />
            <RequiredCheckboxGroup
              description="Access duration"
              name="duration"
              options={ACCESS_DURATION.map((duration) => ({
                value: duration,
                label: formatDuration(duration),
                defaultChecked: defaultDurations.has(duration),
                disabled: fetchIsPending,
              }))}
              valid={!formState.invalidFields.includes("duration")}
            />
            <RequiredCheckboxGroup
              description="Status"
              name="status"
              options={Object.values(REQUEST_STATUS).map((status) => {
                const option = {
                  value: status,
                  label: formatRequestStatus(status),
                  defaultChecked: defaultStatuses.has(status),
                  disabled: fetchIsPending,
                };
                if (status === REQUEST_STATUS.APPROVED) {
                  option.suboptions = [
                    {
                      value: ACCESS_STATUS.ACTIVE,
                      label: formatAccessStatus(ACCESS_STATUS.ACTIVE),
                      defaultChecked: defaultStatuses.has(ACCESS_STATUS.ACTIVE),
                      disabled: fetchIsPending,
                    },
                    {
                      value: ACCESS_STATUS.INACTIVE,
                      label: formatAccessStatus(ACCESS_STATUS.INACTIVE),
                      defaultChecked: defaultStatuses.has(
                        ACCESS_STATUS.INACTIVE
                      ),
                      disabled: fetchIsPending,
                    },
                  ];
                }
                return option;
              })}
              valid={!formState.invalidFields.includes("status")}
            />
            <DateTimeRangeGroup
              defaultEndValue={defaultLastUpdatedAtEnd}
              defaultStartValue={defaultLastUpdatedAtStart}
              description="Last Approved / Rejected / Expired time"
              name="last-updated-at"
              valid={!formState.invalidFields.includes("last-updated-at-start")}
            />
          </form>
        </div>
        <div className="card-footer d-flex justify-content-center">
          {clearButton}
          {applyButton}
        </div>
      </div>
      {exportButton}
    </div>
  );
}

export default Sidebar;
