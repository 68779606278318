import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { VIEW, maxItems } from "../../constants";
import Paginator from "../Paginator";
import Request from "../Request";
import { fetchAccessEndAudit } from "../../sideEffects";

import Sidebar from "./Sidebar";

function AccessEndAudit({ createAlert }) {
  const [searchParams] = useSearchParams();
  const page = new URLSearchParams(searchParams).get("page"); // extract "page" param value, if it is present
  const currentPage =
    page !== null && !isNaN(parseInt(page, 10)) ? parseInt(page, 10) : 1;

  const [requestState, setRequestState] = useState({ requests: null });
  // Map of request IDs to a boolean indicating whether the request UI item is open
  const [openItems, setOpenItems] = useState({});
  const [fetchIsPending, setFetchIsPending] = useState(false);

  // Isolate filter search params (return a search param string containing only filter search params)
  const filters = useMemo(() => {
    const urlSearchParams = new URLSearchParams(searchParams);
    urlSearchParams.forEach((_, key) => {
      if (!["ended-at-start", "ended-at-end"].includes(key)) {
        urlSearchParams.delete(key);
      }
    });
    return urlSearchParams.toString();
  }, [searchParams]);

  // Separate requests with ongoing and ended access
  const [accessOngoingRequests, accessEndedRequests] = useMemo(() => {
    if (requestState.requests === null) return [null, null];

    const accessOngoingRequests = [];
    const accessEndedRequests = [...requestState.requests];

    for (const requestId of requestState.ongoingAccess) {
      const i = accessEndedRequests.findIndex(
        (request) => request.id === requestId
      );
      if (i !== -1) {
        accessOngoingRequests.push(accessEndedRequests[i]);
      }
      accessEndedRequests.splice(i, 1);
    }

    // Open any items with ongoing access
    setOpenItems(
      Object.fromEntries(
        requestState.ongoingAccess.map((requestId) => [requestId, true])
      )
    );

    return [accessOngoingRequests, accessEndedRequests];
  }, [requestState]);

  useEffect(() => {
    if (!filters) {
      return;
    }
    setFetchIsPending(true);
    const offset = (currentPage - 1) * maxItems[VIEW.ACCESS_END_AUDIT];

    fetchAccessEndAudit({ offset, filters })
      .then((fetchedRequests) => {
        setRequestState({
          requests: fetchedRequests.requests,
          total: fetchedRequests.total,
          ongoingAccess: fetchedRequests.ongoingAccess,
        });
        setFetchIsPending(false);
      })
      .catch((e) => {
        createAlert({
          content: e.message,
          theme: "danger",
          duration: 10000,
        });
      });
  }, [createAlert, currentPage, filters]);

  const toggleItemState = (itemId) => {
    setOpenItems((items) => ({ ...items, [itemId]: !items[itemId] }));
  };

  const lastPage = Math.ceil(
    requestState?.total / maxItems[VIEW.ACCESS_END_AUDIT]
  );

  return (
    <div
      data-testid="access-end-audit"
      className="w-100"
      style={{
        height:
          "calc(100vh - 60px - 32px)" /* full viewport height - navbar height - app padding */,
      }}
    >
      <div className="row h-100">
        <div className="col-3 h-100">
          <Sidebar
            defaultFilters={filters}
            fetchIsPending={fetchIsPending}
            requestCount={requestState?.total}
          />
        </div>
        {requestState?.total === 0 && (
          <div className="col-md-9 h-100 d-flex flex-column justify-content-center">
            <div className="card text-bg-success mx-auto request-container has-sidebar">
              <div className="card-body text-center">
                No access was expected to end in this period.
              </div>
            </div>
          </div>
        )}
        {requestState?.total > 0 && (
          <div className="col-md-9 h-100">
            <div
              className="accordion mx-auto request-container has-sidebar"
              style={{
                height:
                  "calc(100% - 50px)" /* full height - paginator height */,
                overflowY: "scroll",
              }}
            >
              <div
                role="region"
                aria-labelledby="requests-with-ongoing-access-heading"
              >
                <div
                  id="requests-with-ongoing-access-heading"
                  className="card text-bg-danger mb-2"
                >
                  <div className="card-body text-center">
                    <strong>Requests with Ongoing Access</strong>
                  </div>
                </div>
                {accessOngoingRequests.map(({ id, ...request }) => (
                  <Request
                    key={id}
                    id={id}
                    {...request}
                    itemIsOpen={!!openItems[id]}
                    onItemClick={() => toggleItemState(id)}
                    view={VIEW.ACCESS_END_AUDIT}
                  />
                ))}
              </div>
              <div
                role="region"
                aria-labelledby="requests-with-no-ongoing-access-heading"
              >
                <div
                  id="requests-with-no-ongoing-access-heading"
                  className="card text-bg-success mb-2 mt-4"
                >
                  <div className="card-body text-center">
                    <strong>Requests without Ongoing Access</strong>
                  </div>
                </div>
                {accessEndedRequests.map(({ id, ...request }) => (
                  <Request
                    key={id}
                    id={id}
                    {...request}
                    itemIsOpen={!!openItems[id]}
                    onItemClick={() => toggleItemState(id)}
                    view={VIEW.ACCESS_END_AUDIT}
                  />
                ))}
              </div>
            </div>
            <Paginator
              ariaLabel="access end audit page"
              currentPage={currentPage}
              lastPage={lastPage}
              route="../access_end_audit"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AccessEndAudit;
