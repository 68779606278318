import { useEffect, useRef, useState } from "react";

import { formatAccessType, formatTimestamp } from "../../utils";

function RejectionReasonModal({ modalRef, rejectRequest, request }) {
  const [triggerEl, setTriggerEl] = useState(null);

  const inputRef = useRef(null);
  const firstFocusableElRef = useRef(null);
  const lastFocusableElRef = useRef(null);

  useEffect(() => {
    const modal = modalRef.current;

    const onShown = ({ relatedTarget }) => {
      inputRef.current?.focus();
      setTriggerEl(relatedTarget);
    };
    modal.addEventListener("shown.bs.modal", onShown);

    const onHidden = () => {
      // Clear the input value
      if (inputRef.current) {
        inputRef.current.value = "";
      }

      // Return focus to the triggering element, if it still exists
      if (triggerEl && document.body.contains(triggerEl)) {
        triggerEl.focus();
      }
    };
    modal.addEventListener("hidden.bs.modal", onHidden);

    return () => {
      modal.removeEventListener("shown.bs.modal", onShown);
      modal.removeEventListener("hidden.bs.modal", onHidden);
    };
  }, [modalRef, triggerEl]);

  const onConfirm = () => {
    const rejectionReason = inputRef.current.value;
    rejectRequest({
      requestId: request.id,
      rejectionReason,
    });
  };

  const trapFocus = (event) => {
    // Focus trap the user in the modal
    if (event.key === "Tab") {
      if (
        event.shiftKey &&
        document.activeElement === firstFocusableElRef.current
      ) {
        event.stopPropagation();
        event.preventDefault();
        lastFocusableElRef.current.focus();
      } else if (
        !event.shiftKey &&
        document.activeElement === lastFocusableElRef.current
      ) {
        event.stopPropagation();
        event.preventDefault();
        firstFocusableElRef.current.focus();
      }
    }
  };

  let formattedAccessType, accessTypeArticle;
  if (request !== null) {
    formattedAccessType = formatAccessType(request.access_type);
    accessTypeArticle =
      formattedAccessType.match(/^[aeiou]/i) === null ? "a" : "an";
  }

  return (
    <div
      className="modal fade"
      id="rejection-reason-modal"
      tabIndex="-1"
      aria-labelledby="rejection-reason-modal-title"
      aria-hidden="true"
      data-testid="rejection-reason-modal"
      onKeyDown={trapFocus}
      ref={modalRef}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="rejection-reason-modal-title">
              Reject Request
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={firstFocusableElRef}
            ></button>
          </div>
          {request !== null && (
            <div className="modal-body">
              You are rejecting {accessTypeArticle} {formattedAccessType}{" "}
              request from {request.requester.name}, which was made on{" "}
              {formatTimestamp(request.requested_at)}.
              <div className="mt-3">
                <label htmlFor="rejection-reason" className="form-label">
                  Provide a rejection reason (optional):
                </label>
                <textarea
                  className="form-control"
                  id="rejection-reason"
                  maxLength="250"
                  rows="3"
                  ref={inputRef}
                ></textarea>
              </div>
            </div>
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={onConfirm}
              ref={lastFocusableElRef}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RejectionReasonModal;
