import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";

import background from "./images/background.png";

import {
  AUTH_CHECK_INTERVAL,
  LOGIN_STEP,
  SESSION_STORAGE_IDENTITY_KEY,
  SESSION_STORAGE_LOGIN_FROM_KEY,
} from "./constants";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import UserIdContext from "./contexts/UserIdContext";
import { checkAuthed } from "./sideEffects";
import { getUserId } from "./utils";

function RequireAuth({ userIsAuthed = false, children }) {
  const location = useLocation();
  if (!userIsAuthed) {
    // NOTE: This doesn't work as expected in Firefox 104.0.2 (except in Private Browsing Mode).
    // Specifically, this code does not appear to be called, so the requested page is not saved
    // and returned to after login.
    // It's not clear whether the issue is with the browser itself or with react-router-dom.
    sessionStorage.setItem(
      SESSION_STORAGE_LOGIN_FROM_KEY,
      JSON.stringify(location)
    );
    return <Navigate to="/login" replace />;
  }

  return children;
}

function App() {
  const [user, setUser] = useState(getUserId);
  const [alert, setAlert] = useState(null);
  const ldClient = useLDClient();

  const navigate = useNavigate();

  // Login by setting the user token's TTL in state
  const completeLogin = ({ userId, policies }) => {
    ldClient.identify({
      key: userId,
      kind: "pacman-user",
      policies,
    });
    setUser(userId);
    // Return to the user's previously-requested page, or default to the request history page
    let loginDestination = "/request_history";
    const loginFrom = sessionStorage.getItem(SESSION_STORAGE_LOGIN_FROM_KEY);
    if (loginFrom !== null) {
      const location = JSON.parse(loginFrom);
      if (location?.pathname !== "/") {
        loginDestination = `${location.pathname}${location.search}${location.hash}`;
      }
      sessionStorage.removeItem(SESSION_STORAGE_LOGIN_FROM_KEY);
    }
    navigate(loginDestination, { replace: true });
  };

  /* Logout by removing stored identity credentials.
   * Note that the identity token will not be revoked.
   */
  const logout = () => {
    sessionStorage.removeItem(SESSION_STORAGE_IDENTITY_KEY);
    setAlert(null);
    setUser(null);
  };

  useEffect(() => {
    // Check that the user is still authenticated; if not, log them out
    const updateAuth = async () => {
      try {
        const authTTL = await checkAuthed();
        // Show warning if user will be logged out in <2 minutes
        if (authTTL !== null && authTTL < 120) {
          setAlert({
            content: (
              <>
                <span style={{ fontSize: "larger" }}>⚠</span>
                You are about to be logged out
              </>
            ),
            theme: "warning",
          });
        }
      } catch (e) {
        logout();
      }
    };

    // Check immediately and then poll
    updateAuth();
    const interval = setInterval(updateAuth, AUTH_CHECK_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  // Remove alert after duration (in ms)
  useEffect(() => {
    if (!alert?.duration) {
      return;
    }
    const timeoutId = setTimeout(() => {
      setAlert(null);
    }, alert.duration);
    return () => clearTimeout(timeoutId);
  }, [alert]);

  return (
    <div
      style={{
        padding: "16px",
        minHeight: "100vh",
        width: "100vw",
        backgroundPosition: "top",
        backgroundRepeat: "repeat",
        backgroundAttachment: "scroll",
        backgroundImage: `url(${background})`,
        backgroundSize: "1700px",
      }}
    >
      {user !== null && alert && (
        <div
          className={`position-fixed top-0 start-50 translate-middle-x text-center fw-bold alert alert-${alert.theme}`}
          role="alert"
          style={{ zIndex: "9999", minWidth: "30%", maxWidth: "60%" }}
        >
          {alert.content}
        </div>
      )}
      <Routes>
        <Route
          path="/login"
          element={
            <Login
              loginStep={LOGIN_STEP.INITIATE}
              completeLogin={completeLogin}
            />
          }
        />
        <Route
          path="/login/callback"
          element={
            <Login
              loginStep={LOGIN_STEP.AUTHORIZE}
              completeLogin={completeLogin}
            />
          }
        />
        <Route
          path="*"
          element={
            <RequireAuth userIsAuthed={user !== null}>
              <UserIdContext.Provider value={user}>
                <Dashboard logout={logout} createAlert={setAlert} />
              </UserIdContext.Provider>
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
