import { useEffect } from "react";

import { ReactComponent as Logo } from "../../images/logo.svg";

import { LOGIN_STEP } from "../../constants";
import { login, loginCallback } from "../../sideEffects";

function Login({ loginStep, completeLogin = () => {} }) {
  useEffect(() => {
    const authorize = async () => {
      const authParams = new URL(window.location).searchParams;
      const { userId, policies } = await loginCallback({
        code: authParams.get("code"),
        state: authParams.get("state"),
      });

      completeLogin({ userId, policies });
    };

    if (loginStep === LOGIN_STEP.INITIATE) {
      login();
    } else {
      authorize();
    }
  }, [loginStep, completeLogin]);

  return (
    <div className="bg-light rounded-2 p-4 d-flex flex-column justify-content-center align-items-center position-absolute top-50 start-50 translate-middle">
      <Logo title="Unit 21" />
      <h3 className="text-center mt-4">Production Access Control Manager</h3>
      <hr className="w-100" />
      <div className="spinner-border text-secondary mb-2" />
      <div role="status" className="text-center">
        {loginStep === LOGIN_STEP.INITIATE
          ? "Redirecting to login page..."
          : "Completing login..."}
        {loginStep === LOGIN_STEP.AUTHORIZE && (
          <div className="mt-2" style={{ fontSize: "smaller" }}>
            (Not loading? <a href="/login">Click here to retry</a>)
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
