import React from "react";

import errorImage from "../images/error.png";

class ErrorBoundary extends React.Component {
  render() {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          background: `no-repeat 50%/cover url(${errorImage})`,
        }}
      >
        <span role="alert" style={{ opacity: 0 }}>
          Something went wrong! Please reload and try again
        </span>
      </div>
    );
  }
}

export default ErrorBoundary;
