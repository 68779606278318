export const AUTH_CHECK_INTERVAL = 60000; // 1 minute

export const LOGIN_STEP = {
  INITIATE: "INITIATE",
  AUTHORIZE: "AUTHORIZE",
};

export const SESSION_STORAGE_IDENTITY_KEY = "identity";
export const SESSION_STORAGE_AUTH_NONCE_KEY = "oidcnonce";
export const SESSION_STORAGE_LOGIN_FROM_KEY = "loginfrom";

export const VIEW = {
  REQUEST_HISTORY: "REQUEST_HISTORY",
  CREATE_REQUEST: "CREATE_REQUEST",
  APPROVAL_HISTORY: "APPROVAL_HISTORY",
  APPROVE: "APPROVE",
  REQUEST_AUDIT: "REQUEST_AUDIT",
  ACCESS_END_AUDIT: "ACCESS_END_AUDIT",
};

export const maxItems = {
  [VIEW.REQUEST_HISTORY]: 25,
  [VIEW.APPROVAL_HISTORY]: 25,
  [VIEW.REQUEST_AUDIT]: 50,
  [VIEW.ACCESS_END_AUDIT]: 50,
};

export const ACCESS_TYPE = {
  IMPERSONATION: "IMPERSONATION",
  PGPARK: "PGPARK",
  DELTA_LAKE: "DELTA_LAKE",
};

// List of possible access duration field values, in seconds
export const ACCESS_DURATION = [
  "14400", // 4 hours
  "86400", // 1 day
  "172800", // 2 days
  "259200", // 3 days
  "604800", // 1 week
  "1209600", // 2 weeks
  "2419200", // 4 weeks
];

export const PGPARK_ACCESS_TYPE = {
  TABLE: "PGPARK_TABLE",
  // PGPARK_ADMIN IS DEPRECATED: requests can no longer be created with this database access type.
  // However, it is attached to some existing access requests, so we can't remove it.
  ADMIN: "PGPARK_ADMIN",
  SUPERUSER: "PGPARK_SUPERUSER",
};

export const DELTA_LAKE_ACCESS_TYPE = {
  TABLE: "DELTA_LAKE_TABLE",
};

export const IMPERSONATION_APPROVER_POLICY_ID =
  "approve-impersonation-access-request";
export const L2_DATA_APPROVER_POLICY_ID = "approve-l2-data-access-request";
export const L3_DATA_APPROVER_POLICY_ID = "approve-l3-data-access-request";
export const AUDITOR_POLICY_ID = "auditor";

export const REQUEST_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  EXPIRED: "EXPIRED",
  AWAITING_L3_APPROVAL: "AWAITING_L3_APPROVAL",
  CANCELED: "CANCELED",
};

export const REQUEST_ACTIONS = {
  APPROVE: "APPROVE",
  REJECT: "REJECT",
  CREATE: "CREATE",
  EXPIRE: "EXPIRE",
  CANCEL: "CANCEL",
};

export const DELTA_LAKE_ENV =
  process.env.REACT_APP_DELTA_LAKE_ENV?.split(",") || [];

export const IMPERSONATION_ENV =
  process.env.REACT_APP_IMPERSONATION_ENV?.split(",") || [];

export const PGPARK_ENV = process.env.REACT_APP_PGPARK_ENV?.split(",") || [];

export const PGPARK_COLS_WITH_FORBIDDEN_WRITE_ACCESS = {
  agent: ["can_impersonate"],
};

export const DATA_ACCESS = {
  READ: "R",
  READ_WRITE: "RW",
};

export const DATA_LABEL = {
  L1: "L1",
  L2: "L2",
  L3: "L3",
};
export const REQUEST_JANITOR_USER_ID = "Chomp-Chomp";
