import { useEffect, useRef, useState } from "react";

function ConfirmationModal({ modalRef, submitRequest }) {
  const [triggerEl, setTriggerEl] = useState(null);

  const firstFocusableElRef = useRef(null);
  const lastFocusableElRef = useRef(null);

  useEffect(() => {
    const modal = modalRef.current;

    const onShown = ({ relatedTarget }) => {
      firstFocusableElRef.current?.focus();
      setTriggerEl(relatedTarget);
    };
    modal.addEventListener("shown.bs.modal", onShown);

    const onHidden = () => {
      // Return focus to the triggering element, if it still exists
      if (triggerEl && document.body.contains(triggerEl)) {
        triggerEl.focus();
      }
    };
    modal.addEventListener("hidden.bs.modal", onHidden);

    return () => {
      modal.removeEventListener("shown.bs.modal", onShown);
      modal.removeEventListener("hidden.bs.modal", onHidden);
    };
  }, [modalRef, triggerEl]);

  const trapFocus = (event) => {
    // Focus trap the user in the modal
    if (event.key === "Tab") {
      if (
        event.shiftKey &&
        document.activeElement === firstFocusableElRef.current
      ) {
        event.stopPropagation();
        event.preventDefault();
        lastFocusableElRef.current.focus();
      } else if (
        !event.shiftKey &&
        document.activeElement === lastFocusableElRef.current
      ) {
        event.stopPropagation();
        event.preventDefault();
        firstFocusableElRef.current.focus();
      }
    }
  };

  return (
    <div
      className="modal fade"
      id="confirmation-modal"
      tabIndex="-1"
      aria-labelledby="confirmation-modal-title"
      aria-hidden="true"
      onKeyDown={trapFocus}
      ref={modalRef}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="confirmation-modal-title">
              Is your request ready to submit?
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={firstFocusableElRef}
            ></button>
          </div>
          <div className="modal-body">
            <p>✅&nbsp;&nbsp;Your reason includes all relevant details</p>
            <p>
              ✅&nbsp;&nbsp;You included <strong>at least one link</strong> to a
              support ticket, Shortcut ticket, Slack conversation, or Pagerduty
              alert
            </p>
            <p>✅&nbsp;&nbsp;You selected your manager as approver</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              No, go back and edit
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={submitRequest}
              ref={lastFocusableElRef}
            >
              Yes, submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
