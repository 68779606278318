import { NavLink, Routes, Route } from "react-router-dom";

import { ReactComponent as Logo } from "../../images/logo.svg";

import AccessEndAudit from "../AccessEndAudit";
import ApprovalHistory from "../ApprovalHistory";
import Approvals from "../Approvals";
import RequestAudit from "../RequestAudit";
import RequestForm from "../RequestForm";
import RequestHistory from "../RequestHistory";
import { VIEW } from "../../constants";
import { userIsApprover, userIsAuditor } from "../../utils";

function NavItem({ id, path, text }) {
  return (
    <li className="nav-item">
      <NavLink
        to={path}
        className={({ isActive }) =>
          `px-2 px-md-3 text-center nav-link ${isActive ? "active" : ""}`
        }
      >
        {text}
      </NavLink>
    </li>
  );
}

function DropdownNavItem({ id, dropdownItems, text }) {
  const menuId = `nav-menu-${id}`;

  return (
    <li className="nav-item dropdown">
      <button
        className="btn btn-link px-2 px-md-3 text-center nav-link dropdown-toggle"
        type="button"
        style={{ paddingTop: "7px" }}
        data-bs-toggle="dropdown"
        aria-expanded="false"
        aria-controls={menuId}
      >
        {text}
      </button>
      <ul id={menuId} className="dropdown-menu">
        {dropdownItems.map(({ id: itemId, path: itemPath, text: itemText }) => (
          <li key={itemId}>
            <NavLink
              to={itemPath}
              className={({ isActive }) =>
                `dropdown-item ${isActive ? "active" : ""}`
              }
            >
              {itemText}
            </NavLink>
          </li>
        ))}
      </ul>
    </li>
  );
}

function Navbar({ views, logout }) {
  return (
    <nav className="w-100 fixed-top navbar navbar-expand bg-light">
      <div className="container-fluid">
        <div className="navbar-brand pt-0">
          <Logo title="Unit 21" />
        </div>
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          {views.map(({ dropdownItems = [], id, path, text }) =>
            dropdownItems.length ? (
              <DropdownNavItem
                key={id}
                dropdownItems={dropdownItems}
                id={id}
                path={path}
                text={text}
              />
            ) : (
              <NavItem key={id} id={id} path={path} text={text} />
            )
          )}
        </ul>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={logout}
          >
            Log Out
          </button>
        </div>
      </div>
    </nav>
  );
}

function Dashboard({ logout, createAlert }) {
  const views = [
    {
      id: VIEW.REQUEST_HISTORY,
      text: "My Requests",
      path: "/request_history",
      navPath: "/request_history",
      element: <RequestHistory createAlert={createAlert} />,
    },
    {
      id: VIEW.CREATE_REQUEST,
      text: "Submit New Request",
      path: "/request",
      navPath: "/request",
      element: <RequestForm createAlert={createAlert} />,
    },
  ];

  if (userIsApprover()) {
    views.push(
      {
        id: VIEW.APPROVAL_HISTORY,
        text: "My Approvals",
        path: "/approval_history",
        navPath: "/approval_history",
        element: <ApprovalHistory createAlert={createAlert} />,
      },
      {
        id: VIEW.APPROVE,
        text: "Pending Requests",
        path: "/approval",
        navPath: "/approval",
        element: <Approvals createAlert={createAlert} />,
      }
    );
  }

  if (userIsAuditor()) {
    views.push({
      id: "AUDIT",
      text: "Audit",
      dropdownItems: [
        {
          id: VIEW.REQUEST_AUDIT,
          text: "All Requests",
          path: "/request_audit",
          element: <RequestAudit createAlert={createAlert} />,
        },
        {
          id: VIEW.ACCESS_END_AUDIT,
          text: "Access End",
          path: "/access_end_audit",
          element: <AccessEndAudit createAlert={createAlert} />,
        },
      ],
    });
  }

  return (
    <>
      <Navbar views={views} logout={logout} />
      <div
        className="w-100 d-sm-flex flex-column justify-content-top align-items-center"
        style={{ marginTop: "60px" /* navbar height */ }}
      >
        <Routes>
          {views.flatMap(({ dropdownItems = [], path, element }) =>
            dropdownItems.length ? (
              dropdownItems.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))
            ) : (
              <Route key={path} path={path} element={element} />
            )
          )}
        </Routes>
      </div>
    </>
  );
}

export default Dashboard;
