import {
  DndContext,
  DragOverlay,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core";
import { ViewportList } from "react-viewport-list";
import { useEffect, useId, useState, useRef } from "react";

import {
  ACCESS_TYPE,
  DATA_ACCESS,
  DELTA_LAKE_ACCESS_TYPE,
  PGPARK_ACCESS_TYPE,
  PGPARK_COLS_WITH_FORBIDDEN_WRITE_ACCESS,
} from "../../constants";
import { formatDatabaseAccessType } from "../../utils";

import { ACCESS_GROUP, applySearch } from "./databaseAccessReducer";

// We need to set an absolute table width in order to render the draggable overlay correctly
const containerWidth = "1200px";
const accessGroupWidth = `calc((${containerWidth} - 120px) / 3)`; // 1/3 of container + 60px gap
const tableWidth = `calc((${containerWidth} - 120px) / 3 - 40px)`; // same width as access group + 20px margin

const accessGroupAriaDescription = {
  [ACCESS_GROUP.NONE]: "no access",
  [ACCESS_GROUP.READ]: "read access",
  [ACCESS_GROUP.READ_WRITE]: "read and write access",
};

// https://docs.dndkit.com/guides/accessibility#screen-reader-announcements-using-live-regions
const dndAnnouncements = {
  onDragStart({ active }) {
    return `Picked up draggable item ${active.id}.`;
  },
  onDragOver({ active, over }) {
    if (over) {
      const overDescription = accessGroupAriaDescription[over.id];
      return `Draggable item ${active.id} was moved over droppable area ${overDescription}.`;
    }

    return `Draggable item ${active.id} is no longer over a droppable area.`;
  },
  onDragEnd({ active, over }) {
    if (over) {
      const overDescription = accessGroupAriaDescription[over.id];
      return `Draggable item ${active.id} was dropped over droppable area ${overDescription}`;
    }

    return `Draggable item ${active.id} was dropped.`;
  },
  onDragCancel({ active }) {
    return `Dragging was cancelled. Draggable item ${active.id} was dropped.`;
  },
};

function BulkTableActions({
  accessGroupLeft,
  accessGroupRight,
  bulkMoveTables,
}) {
  const accessGroupLeftAria = accessGroupAriaDescription[accessGroupLeft];
  const accessGroupRightAria = accessGroupAriaDescription[accessGroupRight];

  return (
    <div className="d-flex flex-column justify-content-center">
      <button
        type="button"
        className="btn btn-secondary mb-3"
        onClick={() =>
          bulkMoveTables({ from: accessGroupRight, to: accessGroupLeft })
        }
      >
        <i
          className="bi bi-chevron-double-left"
          aria-label={`Move all ${accessGroupRightAria} tables to ${accessGroupLeftAria}`}
        ></i>
      </button>
      <button
        type="button"
        className="btn btn-secondary"
        onClick={() =>
          bulkMoveTables({ from: accessGroupLeft, to: accessGroupRight })
        }
      >
        <i
          className="bi bi-chevron-double-right"
          aria-label={`Move all ${accessGroupLeftAria} tables to ${accessGroupRightAria}`}
        ></i>
      </button>
    </div>
  );
}

function SchemaSelector({ accessType, schema, schemaNames, setSchema }) {
  const labelText =
    accessType === ACCESS_TYPE.PGPARK
      ? "Database schema"
      : "Unity Catalog schema";

  return (
    <div
      className="input-group"
      style={{ minWidth: "500px", width: "fit-content" }}
    >
      <label
        htmlFor="schema"
        className="input-group-text bg-secondary text-white"
      >
        {labelText}
      </label>
      <select
        id="schema"
        name="schema"
        className="form-select"
        aria-describedby="schema-help"
        required
        value={schema}
        onChange={({ target: { value } }) => {
          setSchema(value);
        }}
      >
        {schemaNames.map((name) => (
          <option key={name}>{name}</option>
        ))}
      </select>
    </div>
  );
}

function Searchbar({ searchTerm, setSearchTerm }) {
  const preventSubmissionWithEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <div className="input-group col-auto" style={{ width: "500px" }}>
      <span className="input-group-text" id="table-search-label">
        <i className="bi bi-search" aria-hidden="true"></i>&nbsp; Search in the
        schema
      </span>
      <input
        id="table-search"
        type="text"
        className="form-control"
        aria-labelledby="table-search-label"
        aria-describedby="table-search-description"
        placeholder="Results will update as you type..."
        onChange={({ target: { value } }) => {
          setSearchTerm(value);
        }}
        onKeyDown={preventSubmissionWithEnter}
        value={searchTerm}
      />
      <div className="visually-hidden" id="table-search-description">
        Results will update as you type
      </div>
    </div>
  );
}

function Table({
  name,
  cols,
  default_access: tableDefaultAccess,
  isOpen = false,
  setIsOpen,
  currentAccessGroup,
}) {
  const headingId = `heading-table-${name}`;
  const subheadingId = `subheading-table-${name}`;
  const bodyId = `body-table-${name}`;

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: name,
    data: { currentAccessGroup, name, cols, isOpen },
  });

  return (
    <div
      className="card rounded-2"
      style={{ margin: "10px 20px", width: tableWidth }}
    >
      <div className="card-header d-inline-flex align-items-center p-1">
        <button
          className="btn draggable-table-button"
          type="button"
          aria-label={`Drag table ${name}`}
          style={{
            cursor: "grab",
            touchAction: "none",
            border: "none",
          }}
          ref={setNodeRef}
          {...listeners}
          {...attributes}
        >
          <i className="bi-grip-vertical" aria-hidden={true} />
        </button>
        <span
          id={headingId}
          className="flex-grow-1"
          style={{ wordBreak: "break-all" }}
        >
          {name}
        </span>
        <button
          className="btn btn-light"
          type="button"
          aria-label={
            isOpen
              ? `Hide columns of table ${name}`
              : `Show columns of table ${name}`
          }
          aria-expanded={isOpen}
          aria-controls={bodyId}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className={isOpen ? "bi-chevron-up" : "bi-chevron-down"} />
        </button>
      </div>
      <ul
        id={bodyId}
        className="list-group list-group-flush"
        aria-labelledby={subheadingId}
        aria-describedby={headingId}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <li
          id={subheadingId}
          className="list-group-item list-group-item-info fw-bolder"
        >
          Columns:
        </li>
        {Object.entries(cols).map(
          ([colName, { default_access: colDefaultAccess, label }]) => (
            <li
              key={`table-${name}-col-${colName}`}
              className="list-group-item"
              aria-describedby={`l1-icon-${colName} forbidden-write-icon-${colName}`}
              style={{ wordBreak: "break-all" }}
            >
              {colName}&nbsp;&nbsp;
              {currentAccessGroup === ACCESS_GROUP.NONE &&
                (tableDefaultAccess === DATA_ACCESS.READ ||
                  colDefaultAccess === DATA_ACCESS.READ) && (
                  <i
                    id={`l1-icon-${colName}`}
                    title="Read access granted by default"
                    aria-label="Read access granted by default"
                    className="bi bi-book"
                  />
                )}
              {currentAccessGroup === ACCESS_GROUP.READ_WRITE &&
                PGPARK_COLS_WITH_FORBIDDEN_WRITE_ACCESS[name]?.includes(
                  colName
                ) && (
                  <i
                    id={`forbidden-write-icon-${colName}`}
                    title="Write access not permitted"
                    aria-label="Write access not permitted"
                    className="bi bi-exclamation-diamond"
                  />
                )}
            </li>
          )
        )}
      </ul>
    </div>
  );
}

function AccessGroup({ id, title, icon, tables }) {
  // Map of table names to a boolean indicating whether the table UI item is open
  const [openItems, setOpenItems] = useState({});
  const setItemIsOpen = (itemId, itemIsOpen) => {
    setOpenItems((items) => ({ ...items, [itemId]: itemIsOpen }));
  };

  const viewportRef = useRef(null); // Used for scrolling through the list of tables
  const regionLabelId = useId();
  const { setNodeRef } = useDroppable({ id }); // Used for dropping into the group

  return (
    <div
      className="card"
      role="region"
      aria-labelledby={`access-group-${regionLabelId}`}
      style={{ height: "500px", width: accessGroupWidth }}
      ref={setNodeRef}
    >
      <div
        id={`access-group-${regionLabelId}`}
        className="card-header text-bg-secondary"
      >
        <span>
          {title}&nbsp;&nbsp;
          <i className={`bi bi-${icon}`} aria-hidden={true} />
        </span>
      </div>
      <div
        className="card-body px-0 py-1"
        style={{ overflowY: "auto", overflowX: "clip" }}
        ref={viewportRef}
      >
        <ViewportList viewportRef={viewportRef} items={tables}>
          {(table) => (
            <Table
              key={`table-${table.name}`}
              isOpen={!!openItems[table.name]}
              setIsOpen={(isOpen) => setItemIsOpen(table.name, isOpen)}
              currentAccessGroup={id}
              {...table}
            />
          )}
        </ViewportList>
      </div>
    </div>
  );
}

function TableRWAccessInput({
  accessType,
  schema,
  noAccessTables,
  readAccessTables,
  readWriteAccessTables,
  schemaNames,
  updateAccess,
  bulkUpdateAccess,
  resetAccessToDefault,
  setSchema,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  // Apply the search to the current tables
  const [noAccessTablesWithSearch, setNoAccessTablesWithSearch] =
    useState(noAccessTables);
  const [readAccessTablesWithSearch, setReadAccessTablesWithSearch] =
    useState(readAccessTables);
  const [readWriteAccessTablesWithSearch, setReadWriteAccessTablesWithSearch] =
    useState(readWriteAccessTables);
  useEffect(() => {
    setNoAccessTablesWithSearch(
      applySearch({ searchTerm, tables: noAccessTables })
    );
  }, [noAccessTables, searchTerm]);
  useEffect(() => {
    setReadAccessTablesWithSearch(
      applySearch({ searchTerm, tables: readAccessTables })
    );
  }, [readAccessTables, searchTerm]);
  useEffect(() => {
    setReadWriteAccessTablesWithSearch(
      applySearch({ searchTerm, tables: readWriteAccessTables })
    );
  }, [readWriteAccessTables, searchTerm]);

  const setSchemaAndResetSearchTerm = (newSchema) => {
    if (newSchema !== schema) {
      setSearchTerm("");
      setSchema(newSchema);
    }
  };

  const bulkMoveTables = ({ from, to }) =>
    bulkUpdateAccess({ from, to, withSearchTerm: searchTerm });

  // Store the table being dragged in order to create a draggable overlay for it
  const [activeDragTable, setActiveDragTable] = useState(null);
  const handleDragStart = ({ active }) => {
    setActiveDragTable({ name: active.id, ...active.data.current });
  };

  // Change access group of dragged table
  const handleDragEnd = ({ active, over }) => {
    const tableName = active.id;
    setActiveDragTable(null);

    if (over) {
      const toAccessGroup = over.id;
      const fromAccessGroup = active.data.current.currentAccessGroup;

      if (fromAccessGroup !== toAccessGroup) {
        updateAccess({ tableName, from: fromAccessGroup, to: toAccessGroup });
      }
    }
  };

  return (
    <div data-testid="db-table-rw-access-input">
      <div className="form-text mt-3 mb-2">
        <strong>
          Drag and drop tables into different groups to indicate the type of
          access you would like.
        </strong>
        <br />
        If a table is in the "No Access" group, you are not requesting any
        access to it (except for read access to its L1 columns).
        <br />
        If a table is in the "Read Access" group, you are requesting read access
        to all of its columns.
        <br />
        If a table is in the "Read/Write Access" group, you are requesting read
        and write access to all of its columns.
      </div>
      <div className="d-flex align-items-center mx-0 my-1">
        <strong className="form-text me-2 my-0">
          By default, you are requesting read access to all L1 tables in the
          schema, and to all L1 columns in non-L1 tables.
        </strong>
        <button
          type="button"
          className="btn btn-sm btn-outline-danger"
          style={{ width: "fit-content" }}
          onClick={resetAccessToDefault}
        >
          Reset access to default
        </button>
      </div>
      <div className="hstack mx-0 my-3 justify-content-between">
        <SchemaSelector
          accessType={accessType}
          schema={schema}
          schemaNames={schemaNames}
          setSchema={setSchemaAndResetSearchTerm}
        />
        <Searchbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
      <div
        className="d-flex justify-content-between"
        style={{ width: containerWidth }}
      >
        <DndContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          autoScroll={false}
          accessibility={{ announcements: dndAnnouncements }}
        >
          <AccessGroup
            id={ACCESS_GROUP.NONE}
            title="No Access"
            icon="x-circle"
            tables={noAccessTablesWithSearch}
          />
          <BulkTableActions
            accessGroupLeft={ACCESS_GROUP.NONE}
            accessGroupRight={ACCESS_GROUP.READ}
            bulkMoveTables={bulkMoveTables}
          />
          <AccessGroup
            id={ACCESS_GROUP.READ}
            title="Read Access"
            icon="book"
            tables={readAccessTablesWithSearch}
          />
          <BulkTableActions
            accessGroupLeft={ACCESS_GROUP.READ}
            accessGroupRight={ACCESS_GROUP.READ_WRITE}
            bulkMoveTables={bulkMoveTables}
          />
          <AccessGroup
            id={ACCESS_GROUP.READ_WRITE}
            title="Read/Write Access"
            icon="pencil"
            tables={readWriteAccessTablesWithSearch}
          />
          <DragOverlay>
            {activeDragTable ? <Table {...activeDragTable} /> : null}
          </DragOverlay>
        </DndContext>
      </div>
    </div>
  );
}

function DatabaseAccessInput({
  accessType,
  schema,
  noAccessTables,
  readAccessTables,
  readWriteAccessTables,
  schemaNames,
  updateAccess,
  bulkUpdateAccess,
  resetAccessToDefault,
  setSchema,
}) {
  const tableRWDbAccessType = {
    [ACCESS_TYPE.PGPARK]: PGPARK_ACCESS_TYPE.TABLE,
    [ACCESS_TYPE.DELTA_LAKE]: DELTA_LAKE_ACCESS_TYPE.TABLE,
  }[accessType];
  const [dbAccessType, setDbAccessType] = useState(tableRWDbAccessType);

  const dbAccessTypeOptions = {
    [ACCESS_TYPE.PGPARK]: Object.values(PGPARK_ACCESS_TYPE).filter(
      (val) =>
        val !==
        PGPARK_ACCESS_TYPE.ADMIN /* PGPARK_ACCESS_TYPE.ADMIN is deprecated so we need to exclude it */
    ),
    [ACCESS_TYPE.DELTA_LAKE]: Object.values(DELTA_LAKE_ACCESS_TYPE),
  }[accessType];

  return (
    <div className="mb-4">
      <label htmlFor="db-access-type" className="form-label">
        Access details
      </label>
      <select
        id="db-access-type"
        name="db-access-type"
        className="form-select"
        aria-describedby="db-access-type-help"
        required
        value={dbAccessType}
        onChange={({ target: { value } }) => {
          setDbAccessType(value);
        }}
      >
        {dbAccessTypeOptions.map((dbAccessTypeOption) => (
          <option key={dbAccessTypeOption} value={dbAccessTypeOption}>
            {formatDatabaseAccessType(dbAccessTypeOption)}
          </option>
        ))}
      </select>
      <div
        id="db-access-type-help"
        className="form-text"
        style={{
          maxWidth: dbAccessType === tableRWDbAccessType ? "initial" : "750px",
        }}
      >
        {accessType === ACCESS_TYPE.PGPARK && (
          <>
            Select "Superuser access" if you need to be an owner of a schema
            object, if you need <code>rds_superuser</code> privileges (for
            example, to
            <br />
            create indexes or kill queries), or if you need access to the&nbsp;
            <code>link_analysis</code> schema.
          </>
        )}
      </div>
      {dbAccessType === tableRWDbAccessType && (
        <TableRWAccessInput
          accessType={accessType}
          schema={schema}
          noAccessTables={noAccessTables}
          readAccessTables={readAccessTables}
          readWriteAccessTables={readWriteAccessTables}
          schemaNames={schemaNames}
          updateAccess={updateAccess}
          bulkUpdateAccess={bulkUpdateAccess}
          resetAccessToDefault={resetAccessToDefault}
          setSchema={setSchema}
        />
      )}
    </div>
  );
}

export default DatabaseAccessInput;
