import {
  ACCESS_TYPE,
  AUDITOR_POLICY_ID,
  DATA_ACCESS,
  DATA_LABEL,
  DELTA_LAKE_ACCESS_TYPE,
  IMPERSONATION_APPROVER_POLICY_ID,
  L2_DATA_APPROVER_POLICY_ID,
  L3_DATA_APPROVER_POLICY_ID,
  PGPARK_ACCESS_TYPE,
  REQUEST_ACTIONS,
  REQUEST_JANITOR_USER_ID,
  REQUEST_STATUS,
  VIEW,
  maxItems,
} from "../constants";

export const getMockLookupTokenResponse = (ttl = 1500) => ({
  ttl,
  policies: ["default"],
});
export const getMockLookupTokenApproverResponse = (ttl = 1500) => ({
  ttl,
  policies: [
    "default",
    IMPERSONATION_APPROVER_POLICY_ID,
    L2_DATA_APPROVER_POLICY_ID,
    L3_DATA_APPROVER_POLICY_ID,
  ],
});
export const getMockLookupTokenAuditorResponse = (ttl = 1500) => ({
  ttl,
  policies: ["default", AUDITOR_POLICY_ID],
});

const mockClientId = "123";
const mockCodeChallenge = "456";
const mockNonce = "n_789";
const mockRedirectURI = "https://pacman.unit21.com/login/callback";
const mockState = "st_000";
export const mockLoginResponse = `https://unit21.okta.com/oauth2/v1/authorize?client_id=${mockClientId}&code_challenge=${mockCodeChallenge}&code_challenge_method=S256&nonce=${mockNonce}&redirect_uri=${mockRedirectURI}&response_type=code&scope=openid profile groups email&state=${mockState}`;

const mockUser1 = {
  id: "3",
  name: "Harry Potter",
  email: "fake-user-2@example.com",
};
export const mockApproverUser1 = {
  id: "1",
  name: "Albus Dumbledore",
  email: "fake-approver-user-1@example.com",
};
const mockApproverUser2 = {
  id: "2",
  name: "Remus Lupin",
  email: "fake-approver-user-2@example.com",
};
const mockApproverUser3 = {
  id: "4",
  name: "Nymphadora Tonks",
  email: "fake-approver-user-3@example.com",
};
const mockAuditorUser1 = mockApproverUser2;
const mockRequestJanitorUser = { id: REQUEST_JANITOR_USER_ID };

export const mockLoginCallbackResponse = {
  token: "hvs.123abc",
  entityId: mockUser1.id,
  leaseDuration: 1800,
  policies: ["default"],
};

export const mockLoginCallbackApproverResponse = {
  token: "hvs.456def",
  entityId: mockApproverUser1.id,
  leaseDuration: 1800,
  policies: [
    "default",
    IMPERSONATION_APPROVER_POLICY_ID,
    IMPERSONATION_APPROVER_POLICY_ID,
    L2_DATA_APPROVER_POLICY_ID,
    L3_DATA_APPROVER_POLICY_ID,
  ],
};

export const mockLoginCallbackAuditorResponse = {
  token: "hvs.789ghi",
  entityId: mockAuditorUser1.id,
  leaseDuration: 1800,
  policies: ["default", AUDITOR_POLICY_ID],
};

export const mockGetApproversResponse = [
  mockApproverUser1,
  mockApproverUser2,
  mockApproverUser3,
];

const mockPgParkL3TableAccessDetails1 = {
  max_data_classification: DATA_LABEL.L3,
  access_type: PGPARK_ACCESS_TYPE.TABLE,
  schema: "public",
  partial_access_tables: [
    {
      name: "tag",
      cols: [
        {
          name: "color",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
  ],
  read_tables: [{ name: "audit_trail", label: DATA_LABEL.L2 }],
  read_write_tables: [{ name: "alert_hit", label: DATA_LABEL.L3 }],
};

const mockPgParkL3TableAccessDetails2 = {
  max_data_classification: DATA_LABEL.L3,
  access_type: PGPARK_ACCESS_TYPE.TABLE,
  schema: "public",
  partial_access_tables: [
    {
      name: "action_event",
      cols: [
        {
          name: "device_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "entity_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "txn_instrument_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "updated_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "agent",
      cols: [
        {
          name: "can_impersonate",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ_WRITE,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "email",
          access: DATA_ACCESS.READ_WRITE,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "full_name",
          access: DATA_ACCESS.READ_WRITE,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ_WRITE,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "is_default_login",
          access: DATA_ACCESS.READ_WRITE,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "is_reviewer",
          access: DATA_ACCESS.READ_WRITE,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ_WRITE,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "picture",
          access: DATA_ACCESS.READ_WRITE,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "predefined_roles",
          access: DATA_ACCESS.READ_WRITE,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "status",
          access: DATA_ACCESS.READ_WRITE,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "updated_at",
          access: DATA_ACCESS.READ_WRITE,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "alert_action",
      cols: [
        {
          name: "agent_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "alert_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "alert_queue_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "assigned_to_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "is_bulk_action",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "status_changed_to",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "work_started_on",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
  ],
  read_tables: [
    { name: "action_trigger", label: DATA_LABEL.L3 },
    {
      name: "action_trigger_display_tag_association",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    { name: "audit_trail", label: DATA_LABEL.L2 },
    { name: "audit_trail_agent_association", label: DATA_LABEL.L2 },
    { name: "audit_trail_alert_association", label: DATA_LABEL.L2 },
    {
      name: "audit_trail_alert_narrative_association",
      label: DATA_LABEL.L2,
    },
    {
      name: "audit_trail_alert_queue_association",
      label: DATA_LABEL.L2,
    },
    { name: "subdisposition", label: DATA_LABEL.L3 },
    {
      name: "subdisposition_action_trigger_association",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    { name: "subdisposition_option", label: DATA_LABEL.L3 },
    { name: "tag", label: DATA_LABEL.L3 },
    { name: "u21_agent_auth", label: DATA_LABEL.L3 },
    { name: "unit", label: DATA_LABEL.L1, default_access: DATA_ACCESS.READ },
    { name: "verification_form", label: DATA_LABEL.L3 },
    { name: "webhook_callback_attempt", label: DATA_LABEL.L3 },
    {
      name: "alert_action_subdisposition_association",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "alert_action_trigger",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "alert_action_trigger_queue_association",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "alert_associated_alerts",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "alert_component_action_trigger",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "alert_entity_association",
      label: DATA_LABEL.L3,
    },
    {
      name: "alert_event_association",
      label: DATA_LABEL.L3,
    },
    {
      name: "alert_hit_device_association",
      label: DATA_LABEL.L3,
    },
  ],
  read_write_tables: [
    {
      name: "action_trigger_masking_tag_association",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "admin_task",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "alert_history",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    { name: "alert_hit", label: DATA_LABEL.L3 },
    { name: "alert_relevance_rating", label: DATA_LABEL.L2 },
    { name: "article", label: DATA_LABEL.L3 },
    { name: "webhook", label: DATA_LABEL.L3 },
  ],
};

const mockPgParkL1TableAccessDetails1 = {
  max_data_classification: DATA_LABEL.L1,
  access_type: PGPARK_ACCESS_TYPE.TABLE,
  schema: "public",
  partial_access_tables: [
    {
      name: "tag",
      cols: [
        {
          name: "color",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
  ],
  read_tables: [
    {
      name: "verification_service",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
  ],
  read_write_tables: [
    {
      name: "rule_alert_association",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
  ],
};

const mockPgParkL1TableAccessDetails2 = {
  max_data_classification: DATA_LABEL.L1,
  access_type: PGPARK_ACCESS_TYPE.TABLE,
  schema: "public",
  partial_access_tables: [
    {
      name: "tag",
      cols: [
        {
          name: "color",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "team",
      cols: [
        {
          name: "alert_assignment_limit",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "allow_assign_others_alerts",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "allow_listing_unassigned_alerts",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "allow_listing_unassigned_entities",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "allow_rule_deployment",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "allow_self_assigning_alerts",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "allow_tag_create",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "allow_tag_edit",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "allow_viewing_entity_page_of_unassigned_alerts",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "allow_viewing_unassigned_alerts",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "roles_list",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "self_signup_enabled",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "txn_event",
      cols: [
        {
          name: "digital_data_link",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "receiver_entity_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "receiver_instrument_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "receiver_source",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "sender_entity_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "sender_instrument_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "sender_source",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "status",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "updated_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "txn_instrument",
      cols: [
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "from_datafile_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "gateway",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "hot_storage_expires_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "parent_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "source",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "u21_status",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "update_hash",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "updated_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "u21_agent_auth",
      cols: [
        {
          name: "agent_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "expires_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "upload",
      cols: [
        {
          name: "archived_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "stored_in",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "type",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "u21_status",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "updated_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "uploaded_by_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "verification_form",
      cols: [
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "entity_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "session_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "verification_result",
      cols: [
        {
          name: "content",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "continuous_monitoring",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "entity_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "object_source",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "override_key_used",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "source",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "suppressed_provider_ids",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "type",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "u21_status",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "updated_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "verification_form_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "verification_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "verification_workflow_execution_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "verification_workflow",
      cols: [
        {
          name: "alert_queue_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "content",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "entity_type",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "flow_chart",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "forms_config",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "is_enabled",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_setting_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "synchronous",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "verification_workflow_execution",
      cols: [
        {
          name: "alert_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "end_action",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "end_time",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "entity_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "is_success",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "start_time",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "verification_form_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "verification_workflow_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "webhook",
      cols: [
        {
          name: "action_types",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "callback_count",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_by_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "is_active",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "is_deleted",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "webhook_callback_attempt",
      cols: [
        {
          name: "action_type",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "event_created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "retries",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "status_code",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "webhook_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
  ],
  read_tables: [
    {
      name: "risk_metric_stats_by_org",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "subdisposition_action_trigger_association",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "team_alert_assignment_tag_association",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "team_allowed_tag_association",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "undirected_entity_entity_to_entity_relationship",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    { name: "unit", label: DATA_LABEL.L1, default_access: DATA_ACCESS.READ },
    {
      name: "verification_service",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
  ],
  read_write_tables: [
    {
      name: "rule_alert_association",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "rule_blacklist_association",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "rule_case_association",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "rule_execution_schedule_association",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "rule_metric",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "rule_relevance_metric",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "rule_schedule",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "rule_schedule_shadow",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "rule_scheduler_run_per_rule",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "rule_template",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "rule_validation_window",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "sar_filing_history",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "scenario",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "scheduled_polling_log",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    {
      name: "scheduled_polling_type",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    { name: "session", label: DATA_LABEL.L1, default_access: DATA_ACCESS.READ },
    {
      name: "sixtran_jobs",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
  ],
};

const mockPgParkAdminAccessDetails = {
  max_data_classification: DATA_LABEL.L3,
  access_type: PGPARK_ACCESS_TYPE.ADMIN,
};

const mockPgParkSuperuserAccessDetails = {
  max_data_classification: DATA_LABEL.L3,
  access_type: PGPARK_ACCESS_TYPE.SUPERUSER,
};

const mockDeltaLakeL3TableAccessDetails1 = {
  max_data_classification: DATA_LABEL.L3,
  access_type: DELTA_LAKE_ACCESS_TYPE.TABLE,
  schema: "silver",
  partial_access_tables: [
    {
      name: "device",
      cols: [
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
  ],
  read_tables: [{ name: "action_event", label: DATA_LABEL.L3 }],
  read_write_tables: [{ name: "tag", label: DATA_LABEL.L3 }],
};

const mockDeltaLakeL3TableAccessDetails2 = {
  max_data_classification: DATA_LABEL.L3,
  access_type: DELTA_LAKE_ACCESS_TYPE.TABLE,
  schema: "silver",
  partial_access_tables: [
    {
      name: "device",
      cols: [
        {
          name: "bronze_table_object",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "bronze_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_offset",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_partition",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "physical_id",
      cols: [
        {
          name: "bronze_table_object",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "bronze_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "entity_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_offset",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_partition",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "primary_object_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "primary_object_type",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "silver_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "updated_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "txn_event",
      cols: [
        {
          name: "bronze_table_object",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "bronze_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "device_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "from_datafile_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_offset",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_partition",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "receiver_entity_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "receiver_instrument_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "receiver_source",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "sender_entity_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "sender_instrument_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "sender_source",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "silver_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "status",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "u21_status",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "updated_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
  ],
  read_tables: [
    {
      name: "action_event",
      label: DATA_LABEL.L3,
    },
    { name: "address", label: DATA_LABEL.L3 },
    {
      name: "client_fingerprint",
      label: DATA_LABEL.L3,
    },
    { name: "entity", label: DATA_LABEL.L3 },
    { name: "entity_consortium", label: DATA_LABEL.L3 },
    { name: "entity_device_association", label: DATA_LABEL.L3 },
    { name: "entity_entity_relationship", label: DATA_LABEL.L3 },
    { name: "entity_instrument_relationship", label: DATA_LABEL.L3 },
    { name: "entity_tag_association", label: DATA_LABEL.L3 },
    { name: "entity_txn_event_association", label: DATA_LABEL.L3 },
    { name: "instrument", label: DATA_LABEL.L3 },
    { name: "instrument_tag_association", label: DATA_LABEL.L3 },
    { name: "instrument_txn_event_association", label: DATA_LABEL.L3 },
    { name: "ip_city", label: DATA_LABEL.L1, default_access: DATA_ACCESS.READ },
    { name: "parsed_historical_email_address", label: DATA_LABEL.L3 },
    { name: "tag", label: DATA_LABEL.L3 },
    { name: "txn_event", label: DATA_LABEL.L3 },
  ],
  read_write_tables: [
    { name: "action_event_tag_association", label: DATA_LABEL.L3 },
    { name: "device_tag_association", label: DATA_LABEL.L3 },
    { name: "email_address", label: DATA_LABEL.L3 },
    { name: "geolocation", label: DATA_LABEL.L3 },
    { name: "ip_address", label: DATA_LABEL.L3 },
    {
      name: "ip_asn",
      label: DATA_LABEL.L1,
      default_access: DATA_ACCESS.READ,
    },
    { name: "phone_number", label: DATA_LABEL.L3 },
  ],
};

const mockDeltaLakeL1TableAccessDetails1 = {
  max_data_classification: DATA_LABEL.L1,
  access_type: DELTA_LAKE_ACCESS_TYPE.TABLE,
  schema: "silver",
  partial_access_tables: [
    {
      name: "device",
      cols: [
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
  ],
  read_tables: [
    { name: "ip_asn", label: DATA_LABEL.L1, default_access: DATA_ACCESS.READ },
  ],
  read_write_tables: [
    { name: "ip_city", label: DATA_LABEL.L1, default_access: DATA_ACCESS.READ },
  ],
};

const mockDeltaLakeL1TableAccessDetails2 = {
  max_data_classification: DATA_LABEL.L1,
  access_type: DELTA_LAKE_ACCESS_TYPE.TABLE,
  schema: "silver",
  partial_access_tables: [
    {
      name: "device",
      cols: [
        {
          name: "bronze_table_object",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "bronze_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_offset",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_partition",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "physical_id",
      cols: [
        {
          name: "bronze_table_object",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "bronze_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "entity_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_offset",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_partition",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "primary_object_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "primary_object_type",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "silver_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "updated_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
    {
      name: "txn_event",
      cols: [
        {
          name: "bronze_table_object",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "bronze_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "created_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "device_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "from_datafile_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_offset",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_partition",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "kafka_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "org_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "receiver_entity_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "receiver_instrument_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "receiver_source",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "sender_entity_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "sender_instrument_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "sender_source",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "silver_timestamp",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "status",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "u21_status",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "unit_id",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
        {
          name: "updated_at",
          access: DATA_ACCESS.READ,
          label: DATA_LABEL.L1,
          default_access: DATA_ACCESS.READ,
        },
      ],
    },
  ],
  read_tables: [
    { name: "ip_asn", label: DATA_LABEL.L1, default_access: DATA_ACCESS.READ },
  ],
  read_write_tables: [
    { name: "ip_city", label: DATA_LABEL.L1, default_access: DATA_ACCESS.READ },
  ],
};

export const mockPendingImpersonationRequest1 = {
  access_type: ACCESS_TYPE.IMPERSONATION,
  environment: "multitenant:ca-central-1:prod3",
  reason: "new impersonation request",
  duration: 2419200,
  requested_approver: mockApproverUser1,
  id: "8ad9a4b4-be79-47cb-9bfa-68a6e6eb0e29",
  requester: mockUser1,
  status: REQUEST_STATUS.PENDING,
  requested_at: "2022-08-17 16:21:38",
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-17 16:21:38",
    },
  ],
};

export const mockPendingImpersonationRequest2 = {
  access_type: ACCESS_TYPE.IMPERSONATION,
  environment: "customerx:us-east-2:prod1",
  reason: "another new impersonation request for 2 days",
  duration: 172800,
  requested_approver: mockApproverUser1,
  id: "0ad9a4b4-be79-47de-9bfe-68a6e6eb0e29",
  requester: mockUser1,
  status: REQUEST_STATUS.PENDING,
  requested_at: "2022-08-19 06:01:08",
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-19 06:01:08",
    },
  ],
};

export const mockPendingImpersonationRequest3 = {
  access_type: ACCESS_TYPE.IMPERSONATION,
  environment: "multitenant:ca-central-1:prod3",
  reason: "another new impersonation request for 1 day",
  duration: 86400,
  requested_approver: mockApproverUser2,
  id: "fd2fadf3-48fa-4bd0-a708-e1f24bef6e79",
  requester: mockUser1,
  status: REQUEST_STATUS.PENDING,
  requested_at: "2022-08-20 15:42:30",
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-20 15:42:30",
    },
  ],
};

export const mockPendingImpersonationRequest4 = {
  access_type: ACCESS_TYPE.IMPERSONATION,
  environment: "multitenant:us-west-1:prod1",
  reason: "another another new impersonation request for 1 day",
  duration: 86400,
  requested_approver: mockApproverUser2,
  id: "ee1fedf3-88fa-4bd0-a708-e1f24bef6a73",
  requester: mockUser1,
  status: REQUEST_STATUS.PENDING,
  requested_at: "2022-08-21 10:12:11",
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-21 10:12:11",
    },
  ],
};

export const mockPendingPgParkRequest1 = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:ca-central-1:prod3",
  reason: "new pgpark access request",
  duration: 2419200,
  requested_approver: mockApproverUser1,
  id: "0ad9a4b4-be79-47cb-9bfa-68a6e6eb0e20",
  requester: mockUser1,
  status: REQUEST_STATUS.PENDING,
  requested_at: "2022-08-22 16:21:38",
  database_access: mockPgParkL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-22 16:21:38",
    },
  ],
};

export const mockPendingPgParkRequest2 = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:ca-central-1:prod3",
  reason: "new pgpark access request",
  duration: 2419200,
  requested_approver: mockApproverUser1,
  id: "4fd5f4a4-ae75-47ca-5aff-68f6e6ea4e24",
  requester: mockUser1,
  status: REQUEST_STATUS.PENDING,
  requested_at: "2022-08-22 16:21:38",
  database_access: mockPgParkAdminAccessDetails,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-22 21:21:38",
    },
  ],
};

export const mockPendingPgParkRequest3 = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:ca-central-1:prod3",
  reason: "new pgpark access request",
  duration: 2419200,
  requested_approver: mockApproverUser1,
  id: "9ad9a9a9-aa99-99ca-9aaa-68a6a6aa9a29",
  requester: mockUser1,
  status: REQUEST_STATUS.PENDING,
  requested_at: "2022-08-22 16:21:38",
  database_access: mockPgParkSuperuserAccessDetails,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-22 22:21:38",
    },
  ],
};

export const mockPendingPgParkRequest4 = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "customerx:us-east-2:prod1",
  reason: "another new pgpark request for 2 days",
  duration: 172800,
  requested_approver: mockApproverUser1,
  id: "99d9a4b4-be79-47de-9999-68a6e6eb0e99",
  requester: mockUser1,
  status: REQUEST_STATUS.PENDING,
  requested_at: "2022-08-23 06:01:08",
  database_access: mockPgParkL3TableAccessDetails2,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-23 06:01:08",
    },
  ],
};

export const mockPendingPgParkRequest5 = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:ca-central-1:prod3",
  reason: "another new pgpark request for 1 day",
  duration: 86400,
  requested_approver: mockApproverUser2,
  id: "64b6146c-48fa-4bd0-a708-e1f24bef6e79",
  requester: mockUser1,
  status: REQUEST_STATUS.PENDING,
  requested_at: "2022-08-24 15:42:30",
  database_access: mockPgParkL1TableAccessDetails2,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-24 15:42:30",
    },
  ],
};

export const mockPendingDeltaLakeRequest1 = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "multitenant:ca-central-1:prod3",
  reason: "new delta lake access request",
  duration: 2419200,
  requested_approver: mockApproverUser1,
  id: "1a1026df-c65b-4417-b04e-d36509540d34",
  requester: mockUser1,
  status: REQUEST_STATUS.PENDING,
  requested_at: "2024-02-22 16:21:38",
  database_access: mockDeltaLakeL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-02-22 16:21:38",
    },
  ],
};

export const mockPendingDeltaLakeRequest2 = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "customerx:us-east-2:prod1",
  reason: "another new delta lake request for 2 days",
  duration: 172800,
  requested_approver: mockApproverUser1,
  id: "2069ddf7-e9e4-4760-a79f-c36c79e41f4c",
  requester: mockUser1,
  status: REQUEST_STATUS.PENDING,
  requested_at: "2024-02-23 06:01:02",
  database_access: mockDeltaLakeL3TableAccessDetails2,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-02-23 06:01:02",
    },
  ],
};

export const mockPendingDeltaLakeRequest3 = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "multitenant:ca-central-1:prod3",
  reason: "another new delta lake request for 1 day",
  duration: 86400,
  requested_approver: mockApproverUser2,
  id: "3192dadd-e464-4971-a8b6-3e70bfe74fe4",
  requester: mockUser1,
  status: REQUEST_STATUS.PENDING,
  requested_at: "2024-02-24 15:42:30",
  database_access: mockDeltaLakeL1TableAccessDetails2,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-02-24 15:42:30",
    },
  ],
};

export const mockExpiredImpersonationRequest = {
  access_type: ACCESS_TYPE.IMPERSONATION,
  environment: "multitenant:us-west-1:prod1",
  reason:
    "I need impersonation access because of an urgent issue with Binance!",
  duration: 14400,
  requested_approver: mockApproverUser2,
  id: "f33ec86d-a7fb-46e2-a759-48129cbfaab3",
  requester: mockUser1,
  status: REQUEST_STATUS.EXPIRED,
  requested_at: "2022-08-16 16:09:15",
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-16 16:09:15",
    },
    {
      actor: mockRequestJanitorUser,
      action: REQUEST_ACTIONS.EXPIRE,
      new_status: REQUEST_STATUS.EXPIRED,
      time: "2022-08-25 00:18:34",
    },
  ],
};

export const mockExpiredPgParkRequestWithoutApproval = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:us-west-1:prod1",
  reason: "I need pgpark access because of an urgent issue with Binance!",
  duration: 14400,
  requested_approver: mockApproverUser2,
  id: "bb2f1ggc-76a1-49de-ace6-88d806c78d83",
  requester: mockUser1,
  status: REQUEST_STATUS.EXPIRED,
  requested_at: "2022-08-16 16:09:15",
  database_access: mockPgParkL1TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-16 16:09:15",
    },
    {
      actor: mockRequestJanitorUser,
      action: REQUEST_ACTIONS.EXPIRE,
      new_status: REQUEST_STATUS.EXPIRED,
      time: "2022-08-26 00:18:34",
    },
  ],
};

export const mockExpiredPgParkRequestWithApproval = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:us-west-1:prod1",
  reason: "I need pgpark access because of an urgent issue with Binance!",
  duration: 14400,
  requested_approver: mockApproverUser2,
  id: "cc1f1ggd-76b1-49ef-bdf6-44e406d74e43",
  requester: mockUser1,
  status: REQUEST_STATUS.EXPIRED,
  requested_at: "2022-08-16 16:09:15",
  database_access: mockPgParkL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-16 16:09:15",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2022-08-17 20:28:30",
    },
    {
      actor: mockRequestJanitorUser,
      action: REQUEST_ACTIONS.EXPIRE,
      new_status: REQUEST_STATUS.EXPIRED,
      time: "2022-08-27 00:18:34",
    },
  ],
};

export const mockExpiredDeltaLakeRequestWithoutApproval = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "multitenant:us-west-1:prod1",
  reason: "I need delta lake access because of an urgent issue with Binance!",
  duration: 14400,
  requested_approver: mockApproverUser2,
  id: "45745b25-83e3-4fba-a617-73793fc4fc65",
  requester: mockUser1,
  status: REQUEST_STATUS.EXPIRED,
  requested_at: "2024-02-16 16:09:15",
  database_access: mockDeltaLakeL1TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-02-16 16:09:15",
    },
    {
      actor: mockRequestJanitorUser,
      action: REQUEST_ACTIONS.EXPIRE,
      new_status: REQUEST_STATUS.EXPIRED,
      time: "2024-02-26 00:18:34",
    },
  ],
};

export const mockExpiredDeltaLakeRequestWithApproval = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "multitenant:us-west-1:prod1",
  reason: "I need delta lake access because of an urgent issue with Binance!",
  duration: 14400,
  requested_approver: mockApproverUser2,
  id: "827c18fd-269d-4a83-bb63-1ee49206136b",
  requester: mockUser1,
  status: REQUEST_STATUS.EXPIRED,
  requested_at: "2024-02-16 16:09:15",
  database_access: mockDeltaLakeL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-02-16 16:09:15",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2024-02-17 20:28:30",
    },
    {
      actor: mockRequestJanitorUser,
      action: REQUEST_ACTIONS.EXPIRE,
      new_status: REQUEST_STATUS.EXPIRED,
      time: "2024-02-27 00:18:34",
    },
  ],
};

export const mockApprovedImpersonationRequest1 = {
  access_type: ACCESS_TYPE.IMPERSONATION,
  environment: "multitenant:us-west-1:prod1",
  reason: "I really need 2 days of impersonation access",
  duration: 172800,
  requested_approver: mockApproverUser2,
  id: "71c121f6-a488-484f-8611-66c98f53027a",
  requester: mockUser1,
  status: REQUEST_STATUS.APPROVED,
  requested_at: "2022-08-11 20:28:14",
  lease_id: "AzFE6GkGLDbrvDKCkddoaGYB",
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-11 20:28:14",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2022-08-28 20:28:30",
    },
  ],
};

export const mockApprovedImpersonationRequest2 = {
  access_type: ACCESS_TYPE.IMPERSONATION,
  environment: "multitenant:us-east-2:prod2",
  reason: "Moar access plz",
  duration: 86400,
  requested_approver: mockApproverUser2,
  id: "33c131f3-a438-383f-3631-63c98f33023a",
  requester: mockUser1,
  status: REQUEST_STATUS.APPROVED,
  requested_at: "2022-08-10 10:28:14",
  lease_id: "EeFE1GkELDervEKCkedeaGeB",
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-10 10:28:14",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2022-08-29 10:28:30",
    },
  ],
};

export const mockApprovedImpersonationRequest3 = {
  access_type: ACCESS_TYPE.IMPERSONATION,
  environment: "multitenant:ca-central-1:prod10",
  reason: "I really need 2 days of impersonation access",
  duration: 172800,
  requested_approver: mockApproverUser2,
  id: "60b010e5-a488-484f-8611-66c98f53027a",
  requester: mockApproverUser1,
  status: REQUEST_STATUS.APPROVED,
  requested_at: "2022-08-11 20:28:14",
  lease_id: "ZzZE6GkGLDbrvDKCkddoaXYZ",
  actions: [
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-11 20:28:14",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2022-08-30 20:28:30",
    },
  ],
};

export const mockApprovedPgParkRequestWithL3Approval1 = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:us-west-1:prod1",
  reason: "I really need 2 days of pgpark access",
  duration: 172800,
  requested_approver: mockApproverUser2,
  id: "cccccccc-a488-484f-8611-66c98f53027a",
  requester: mockUser1,
  status: REQUEST_STATUS.APPROVED,
  requested_at: "2022-08-11 20:28:14",
  lease_id: "AzFE6GkGLDbrvDKCkddoaGYB",
  database_access: mockPgParkL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-11 20:28:14",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2022-08-11 20:38:30",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2022-08-31 20:48:30",
    },
  ],
};

export const mockApprovedPgParkRequestWithNonL3Approval = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:ca-central-1:prod10",
  reason: "I really need 2 days of pgpark access",
  duration: 172800,
  requested_approver: mockApproverUser1,
  id: "f020a05e-a488-484f-8611-66c98f53027a",
  requester: mockApproverUser2,
  status: REQUEST_STATUS.APPROVED,
  requested_at: "2022-09-01 20:28:14",
  lease_id: "ZzZE6GkGLDbrvDKCkddoaXYZ",
  database_access: mockPgParkL1TableAccessDetails1,
  actions: [
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-09-01 20:28:14",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2022-09-02 20:28:30",
    },
  ],
};

export const mockApprovedPgParkRequestWithL3Approval2 = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:us-east-2:prod2",
  reason: "Moar access plz",
  duration: 86400,
  requested_approver: mockApproverUser2,
  id: "33c131f3-a438-383f-3631-aaaaaaaaaaa",
  requester: mockUser1,
  status: REQUEST_STATUS.APPROVED,
  requested_at: "2022-08-10 10:28:14",
  lease_id: "EeFE1GkELDervEKCkedeaGeB",
  database_access: mockPgParkL3TableAccessDetails2,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-10 10:28:14",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2022-08-10 11:28:14",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2022-09-01 12:28:30",
    },
  ],
};

export const mockApprovedPgParkRequestWithL3Approval3 = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:us-east-2:prod2",
  reason: "Moar access plz",
  duration: 86400,
  requested_approver: mockApproverUser2,
  id: "88c181f8-b488-888f-8681-bbbbbbbbbbb",
  requester: mockUser1,
  status: REQUEST_STATUS.APPROVED,
  approver: mockApproverUser2,
  requested_at: "2022-08-10 10:28:14",
  updated_at: "2022-09-01 12:28:30",
  lease_id: "EeFE1GkELDervEKCkedeaGeB",
  database_access: mockPgParkAdminAccessDetails,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-10 10:28:14",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2022-08-10 11:28:14",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2022-09-01 12:28:30",
    },
  ],
};

export const mockApprovedPgParkRequestWithL3Approval4 = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:us-east-2:prod2",
  reason: "Moar access plz",
  duration: 86400,
  requested_approver: mockApproverUser2,
  id: "11c111f1-b411-111f-1611-bbbbbcccccc",
  requester: mockUser1,
  status: REQUEST_STATUS.APPROVED,
  approver: mockApproverUser2,
  requested_at: "2022-08-10 10:28:14",
  updated_at: "2022-09-02 12:28:30",
  lease_id: "EeFE1GkELDervEKCkedeaGeB",
  database_access: mockPgParkSuperuserAccessDetails,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-08-10 10:28:14",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2022-08-10 11:28:14",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2022-09-02 12:28:30",
    },
  ],
};

export const mockApprovedDeltaLakeRequestWithL3Approval1 = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "multitenant:us-west-1:prod1",
  reason: "I really need 2 days of delta lake access",
  duration: 172800,
  requested_approver: mockApproverUser2,
  id: "cbd2bc65-2ea3-46f5-b4cb-8f0ac9058e3d",
  requester: mockUser1,
  status: REQUEST_STATUS.APPROVED,
  requested_at: "2024-01-11 20:28:14",
  lease_id: "AzFE6GkGLDbrvDKCkddoaGYB",
  database_access: mockDeltaLakeL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-01-11 20:28:14",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2024-01-11 20:38:30",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2024-01-11 20:48:30",
    },
  ],
};

export const mockApprovedDeltaLakeRequestWithNonL3Approval = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "multitenant:ca-central-1:prod10",
  reason: "I really need 2 days of delta lake access",
  duration: 172800,
  requested_approver: mockApproverUser1,
  id: "9264a55b-b194-4d62-8222-a0e65ead567b",
  requester: mockApproverUser2,
  status: REQUEST_STATUS.APPROVED,
  requested_at: "2024-01-01 20:28:14",
  lease_id: "ZzZE6GkGLDbrvDKCkddoaXYZ",
  database_access: mockDeltaLakeL1TableAccessDetails1,
  actions: [
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-01-01 20:28:14",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2024-01-02 20:28:30",
    },
  ],
};

export const mockApprovedDeltaLakeRequestWithL3Approval2 = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "multitenant:us-east-2:prod2",
  reason: "Moar access plz",
  duration: 86400,
  requested_approver: mockApproverUser2,
  id: "93059284-b396-42e3-8e9f-5606e52f7b98",
  requester: mockUser1,
  status: REQUEST_STATUS.APPROVED,
  requested_at: "2024-01-10 10:28:14",
  lease_id: "EeFE1GkELDervEKCkedeaGeB",
  database_access: mockDeltaLakeL3TableAccessDetails2,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-01-10 10:28:14",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2024-01-10 11:28:14",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2024-01-11 12:28:30",
    },
  ],
};

export const mockApprovedImpersonationRequestWithActiveAccess = {
  access_type: ACCESS_TYPE.IMPERSONATION,
  environment: "multitenant:us-west-1:prod1",
  reason: "I really need 2 days of impersonation access",
  duration: 172800,
  requested_approver: mockApproverUser2,
  id: "11111116-1418-181f-1611-16191f13121a",
  requester: mockUser1,
  status: REQUEST_STATUS.APPROVED,
  requested_at: "2022-09-01 20:28:14",
  lease_id: "VzUV6GkGLMbrvDKGkddoaGYJ",
  end_time: "2022-08-13T20:28:30.552719004Z",
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-09-01 20:28:14",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2022-09-03 20:28:30",
    },
  ],
};

export const mockApprovedPgParkRequestWithActiveAccess = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:us-west-1:prod1",
  reason: "I really need 2 days of pgpark access",
  duration: 172800,
  requested_approver: mockApproverUser2,
  id: "12345116-1418-181f-1611-16191f13121a",
  requester: mockUser1,
  status: REQUEST_STATUS.APPROVED,
  requested_at: "2022-09-01 20:28:14",
  lease_id: "PzUP6ZkZLMbrpFKZkffoaZYJ",
  end_time: "2022-08-13T20:28:30.552719004Z",
  database_access: mockPgParkL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-09-01 20:28:14",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2022-09-02 20:29:30",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2022-09-04 20:30:30",
    },
  ],
};

export const mockApprovedDeltaLakeRequestWithActiveAccess = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "multitenant:us-west-1:prod1",
  reason: "I really need 2 days of delta lake access",
  duration: 172800,
  requested_approver: mockApproverUser2,
  id: "65752897-f331-4f28-bdeb-691938e9c7f6",
  requester: mockUser1,
  status: REQUEST_STATUS.APPROVED,
  requested_at: "2024-01-01 20:28:14",
  lease_id: "PzUP6ZkZLMbrpFKZkffoaZYJ",
  end_time: "2022-08-13T20:28:30.552719004Z",
  database_access: mockDeltaLakeL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-01-01 20:28:14",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2024-01-02 20:29:30",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.APPROVED,
      time: "2024-01-04 20:30:30",
    },
  ],
};

export const mockRejectedImpersonationRequest = {
  access_type: ACCESS_TYPE.IMPERSONATION,
  environment: "customerx:us-east-2:prod1",
  reason: "1 week",
  duration: 172800,
  requested_approver: mockApproverUser1,
  id: "91de9698-e57b-4467-a0c8-54cea39ea70e",
  requester: mockUser1,
  status: REQUEST_STATUS.REJECTED,
  requested_at: "2022-09-01 15:59:18",
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-09-01 15:59:18",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.REJECT,
      new_status: REQUEST_STATUS.REJECTED,
      time: "2022-09-05 19:55:55",
    },
  ],
};

export const mockRejectedPgParkRequestWithoutApproval = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "customerx:us-east-2:prod1",
  reason: "1 week",
  duration: 172800,
  requested_approver: mockApproverUser1,
  id: "19de9999-e57b-4467-a0c8-54cea39ea70e",
  requester: mockUser1,
  status: REQUEST_STATUS.REJECTED,
  requested_at: "2022-09-01 15:59:18",
  database_access: mockPgParkL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-09-01 15:59:18",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.REJECT,
      new_status: REQUEST_STATUS.REJECTED,
      time: "2022-09-06 19:55:55",
    },
  ],
};

export const mockRejectedPgParkRequestWithApproval = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "customerx:us-east-2:prod1",
  reason: "1 week",
  duration: 172800,
  requested_approver: mockApproverUser1,
  id: "e8de8888-e57b-4467-e0c8-54cee38ee70e",
  requester: mockUser1,
  status: REQUEST_STATUS.REJECTED,
  requested_at: "2022-09-01 15:59:18",
  database_access: mockPgParkL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-09-01 15:59:18",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2022-09-07 19:55:55",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.REJECT,
      new_status: REQUEST_STATUS.REJECTED,
      time: "2022-09-07 19:55:55",
    },
  ],
};

export const mockRejectedDeltaLakeRequestWithoutApproval = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "customerx:us-east-2:prod1",
  reason: "1 week",
  duration: 172800,
  requested_approver: mockApproverUser1,
  id: "229649bb-6aba-45a4-bf09-922432943ea4",
  requester: mockUser1,
  status: REQUEST_STATUS.REJECTED,
  requested_at: "2024-01-01 15:59:18",
  database_access: mockDeltaLakeL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-01-01 15:59:18",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.REJECT,
      new_status: REQUEST_STATUS.REJECTED,
      time: "2024-01-06 19:55:55",
    },
  ],
};

export const mockRejectedDeltaLakeRequestWithApproval = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "customerx:us-east-2:prod1",
  reason: "1 week",
  duration: 172800,
  requested_approver: mockApproverUser1,
  id: "e8d759ec-cc92-491d-b53d-cfebf7d12e0a",
  requester: mockUser1,
  status: REQUEST_STATUS.REJECTED,
  requested_at: "2024-01-01 15:59:18",
  database_access: mockDeltaLakeL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-01-01 15:59:18",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2024-01-07 19:55:55",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.REJECT,
      new_status: REQUEST_STATUS.REJECTED,
      time: "2024-01-07 19:55:55",
    },
  ],
};

export const mockRejectedImpersonationRequestWithRejectionReason = {
  access_type: ACCESS_TYPE.IMPERSONATION,
  environment: "customerx:us-east-2:prod1",
  reason: "1 week",
  duration: 172800,
  requested_approver: mockApproverUser1,
  id: "010e9098-0570-0460-a0c0-50ce0390a000",
  requester: mockUser1,
  status: REQUEST_STATUS.REJECTED,
  requested_at: "2022-09-08 15:59:18",
  rejection_reason: "Rejected for reasons",
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-09-08 15:59:18",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.REJECT,
      new_status: REQUEST_STATUS.REJECTED,
      time: "2022-09-08 19:55:55",
    },
  ],
};

export const mockRejectedPgParkRequestWithRejectionReason = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "customerx:us-east-2:prod1",
  reason: "1 week",
  duration: 172800,
  requested_approver: mockApproverUser1,
  id: "313e9398-3573-3463-a3c3-53ce3393a333",
  requester: mockUser1,
  status: REQUEST_STATUS.REJECTED,
  requested_at: "2022-09-09 15:59:18",
  rejection_reason: "I don't want anyone to have access to my precious pgpark",
  database_access: mockPgParkL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-09-09 15:59:18",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2022-09-09 17:55:55",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.REJECT,
      new_status: REQUEST_STATUS.REJECTED,
      time: "2022-09-09 19:55:55",
    },
  ],
};

export const mockRejectedDeltaLakeRequestWithRejectionReason = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "customerx:us-east-2:prod1",
  reason: "1 week",
  duration: 172800,
  requested_approver: mockApproverUser1,
  id: "06afe69c-293a-4896-806e-9e9c424a0a24",
  requester: mockUser1,
  status: REQUEST_STATUS.REJECTED,
  requested_at: "2022-09-09 15:59:18",
  rejection_reason:
    "I don't want anyone to have access to my precious delta lake",
  database_access: mockDeltaLakeL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-01-09 15:59:18",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2024-01-09 17:55:55",
    },
    {
      actor: mockApproverUser1,
      action: REQUEST_ACTIONS.REJECT,
      new_status: REQUEST_STATUS.REJECTED,
      time: "2024-01-09 19:55:55",
    },
  ],
};

export const mockAwaitingL3ApprovalPgParkRequest = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:us-west-1:prod1",
  reason: "I really need 2 days of pgpark access",
  duration: 172800,
  requested_approver: mockApproverUser2,
  id: "fedcbacc-a488-484f-8611-66c98f53027a",
  requester: mockUser1,
  status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
  requested_at: "2022-10-10 20:28:14",
  database_access: mockPgParkL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-10-10 20:28:14",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2022-10-10 20:38:30",
    },
  ],
};

export const mockAwaitingL3ApprovalDeltaLakeRequest = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "multitenant:us-west-1:prod1",
  reason: "I really need 2 days of delta lake access",
  duration: 172800,
  requested_approver: mockApproverUser2,
  id: "d12f3f81-4b59-4f3b-b29e-51a352cccdbe",
  requester: mockUser1,
  status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
  requested_at: "2024-02-10 20:28:14",
  database_access: mockDeltaLakeL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-02-10 20:28:14",
    },
    {
      actor: mockApproverUser2,
      action: REQUEST_ACTIONS.APPROVE,
      new_status: REQUEST_STATUS.AWAITING_L3_APPROVAL,
      time: "2024-02-10 20:38:30",
    },
  ],
};

export const mockCanceledImpersonationRequest = {
  access_type: ACCESS_TYPE.IMPERSONATION,
  environment: "multitenant:ca-central-1:prod3",
  reason: "new impersonation request",
  duration: 2419200,
  requested_approver: mockApproverUser1,
  id: "6fc9f4b4-be79-47cb-9bff-76f7e7eb0e29",
  requester: mockUser1,
  status: REQUEST_STATUS.CANCELED,
  requested_at: "2022-10-17 16:21:38",
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-10-17 16:21:38",
    },
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CANCEL,
      new_status: REQUEST_STATUS.CANCELED,
      time: "2022-10-17 16:25:38",
    },
  ],
};

export const mockCanceledPgParkRequest = {
  access_type: ACCESS_TYPE.PGPARK,
  environment: "multitenant:ca-central-1:prod3",
  reason: "new pgpark access request",
  duration: 2419200,
  requested_approver: mockApproverUser1,
  id: "1fd9f4b4-be79-47cb-9bff-28f2e2eb1e21",
  requester: mockUser1,
  status: REQUEST_STATUS.CANCELED,
  requested_at: "2022-10-22 16:21:38",
  database_access: mockPgParkL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2022-10-22 16:21:38",
    },
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CANCEL,
      new_status: REQUEST_STATUS.CANCELED,
      time: "2022-10-22 16:25:38",
    },
  ],
};

export const mockCanceledDeltaLakeRequest = {
  access_type: ACCESS_TYPE.DELTA_LAKE,
  environment: "multitenant:ca-central-1:prod3",
  reason: "new delta lake access request",
  duration: 2419200,
  requested_approver: mockApproverUser1,
  id: "e8bdf8f3-cea0-4b96-a7ea-9af2d2b60bec",
  requester: mockUser1,
  status: REQUEST_STATUS.CANCELED,
  requested_at: "2024-02-22 16:21:38",
  database_access: mockDeltaLakeL3TableAccessDetails1,
  actions: [
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CREATE,
      new_status: REQUEST_STATUS.PENDING,
      time: "2024-02-22 16:21:38",
    },
    {
      actor: mockUser1,
      action: REQUEST_ACTIONS.CANCEL,
      new_status: REQUEST_STATUS.CANCELED,
      time: "2024-02-22 16:25:38",
    },
  ],
};

export const allRequests = [
  mockPendingImpersonationRequest1,
  mockPendingImpersonationRequest2,
  mockPendingImpersonationRequest3,
  mockPendingImpersonationRequest4,
  mockPendingPgParkRequest1,
  mockPendingPgParkRequest2,
  mockPendingPgParkRequest3,
  mockPendingPgParkRequest4,
  mockPendingPgParkRequest5,
  mockExpiredImpersonationRequest,
  mockExpiredPgParkRequestWithoutApproval,
  mockExpiredPgParkRequestWithApproval,
  mockApprovedImpersonationRequest1,
  mockApprovedImpersonationRequest2,
  mockApprovedImpersonationRequest3,
  mockApprovedPgParkRequestWithL3Approval1,
  mockApprovedPgParkRequestWithL3Approval2,
  mockApprovedPgParkRequestWithNonL3Approval,
  mockApprovedPgParkRequestWithL3Approval3,
  mockApprovedPgParkRequestWithL3Approval4,
  mockApprovedImpersonationRequestWithActiveAccess,
  mockApprovedPgParkRequestWithActiveAccess,
  mockRejectedImpersonationRequest,
  mockRejectedPgParkRequestWithoutApproval,
  mockRejectedPgParkRequestWithApproval,
  mockRejectedImpersonationRequestWithRejectionReason,
  mockRejectedPgParkRequestWithRejectionReason,
  mockAwaitingL3ApprovalPgParkRequest,
  mockCanceledImpersonationRequest,
  mockCanceledPgParkRequest,
  mockPendingDeltaLakeRequest1,
  mockPendingDeltaLakeRequest2,
  mockPendingDeltaLakeRequest3,
  mockExpiredDeltaLakeRequestWithoutApproval,
  mockExpiredDeltaLakeRequestWithApproval,
  mockApprovedDeltaLakeRequestWithL3Approval1,
  mockApprovedDeltaLakeRequestWithNonL3Approval,
  mockApprovedDeltaLakeRequestWithL3Approval2,
  mockApprovedDeltaLakeRequestWithActiveAccess,
  mockRejectedDeltaLakeRequestWithoutApproval,
  mockRejectedDeltaLakeRequestWithApproval,
  mockRejectedDeltaLakeRequestWithRejectionReason,
  mockAwaitingL3ApprovalDeltaLakeRequest,
  mockCanceledDeltaLakeRequest,
].toSorted((a, b) => (a.requested_at > b.requested_at ? -1 : 1)); // sort in reverse chronological order

export const mockGetRequestsResponse = allRequests
  .filter(({ actions }) => {
    const creationAction = actions.find(
      ({ action }) => action === REQUEST_ACTIONS.CREATE
    );
    return creationAction.actor.id === mockUser1.id;
  })
  .slice(0, maxItems[VIEW.REQUEST_HISTORY]);

export const mockGetRequestsApproverResponse = allRequests
  .filter(({ actions }) => {
    const creationAction = actions.find(
      ({ action }) => action === REQUEST_ACTIONS.CREATE
    );
    return creationAction.actor.id === mockApproverUser1.id;
  })
  .slice(0, maxItems[VIEW.REQUEST_HISTORY]);

export const mockGetRequestsAuditorResponse = allRequests
  .filter(({ actions }) => {
    const creationAction = actions.find(
      ({ action }) => action === REQUEST_ACTIONS.CREATE
    );
    return creationAction.actor.id === mockAuditorUser1.id;
  })
  .slice(0, maxItems[VIEW.REQUEST_HISTORY]);

export const mockGetRequestsPendingApprovalResponse = allRequests.filter(
  ({ actions, status }) => {
    const approverIsRequester =
      actions.findIndex(
        ({ action, actor }) =>
          action === REQUEST_ACTIONS.CREATE && actor.id === mockApproverUser1.id
      ) !== -1;

    const requestIsPending = [
      REQUEST_STATUS.PENDING,
      REQUEST_STATUS.AWAITING_L3_APPROVAL,
    ].includes(status);

    const approverIsApprover =
      actions.findIndex(
        ({ action, actor }) =>
          action === REQUEST_ACTIONS.APPROVE &&
          actor.id === mockApproverUser1.id
      ) !== -1;

    return !approverIsRequester && requestIsPending && !approverIsApprover;
  }
);

export const mockGetApprovalHistoryResponse = allRequests
  .filter(({ actions, requested_approver, status }) => {
    const approvalAction = actions.find(
      ({ action }) => action === REQUEST_ACTIONS.APPROVE
    );
    return (
      (approvalAction !== undefined &&
        [requested_approver.id, approvalAction.actor.id].includes(
          mockApproverUser1.id
        )) ||
      (status === REQUEST_STATUS.CANCELED &&
        requested_approver.id === mockApproverUser1.id)
    );
  })
  .slice(0, maxItems[VIEW.APPROVAL_HISTORY]);

export const mockGetRequestAuditResponse = {
  requests: allRequests.slice(0, maxItems[VIEW.REQUEST_AUDIT]),
  total: allRequests.length,
};

export const mockGetAccessEndAuditResponse = {
  requests: [
    mockApprovedImpersonationRequest1,
    mockApprovedImpersonationRequest2,
    mockApprovedImpersonationRequest3,
    mockApprovedImpersonationRequestWithActiveAccess,
    mockApprovedPgParkRequestWithL3Approval1,
    mockApprovedPgParkRequestWithNonL3Approval,
  ],
  total: 4,
  ongoing_access: [mockApprovedImpersonationRequestWithActiveAccess.id],
};

export const mockGetRequestDatabaseCredentials = {
  username: "v_multitenant_useast1_prod2_harry_potter_2023_05_16",
  password: "password1234",
};

export const mockGetDataLabelsPgParkResponse = JSON.parse(`
{
  "public": {
    "action_event": {
      "cols": {
        "action_content": {
          "label": "L3"
        },
        "action_type": {
          "label": "L3"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "event_time": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "label": "L3"
        },
        "txn_instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "action_trigger": {
      "cols": {
        "color": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "disposition": {
          "label": "L3"
        },
        "disposition_id": {
          "default_access": "R",
          "label": "L1"
        },
        "external_id": {
          "label": "L3"
        },
        "icon": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "is_active": {
          "default_access": "R",
          "label": "L1"
        },
        "is_deleted": {
          "default_access": "R",
          "label": "L1"
        },
        "notes": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "text": {
          "label": "L3"
        },
        "type": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "whitelist_durations": {
          "default_access": "R",
          "label": "L1"
        },
        "whitelist_type": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "action_trigger_display_tag_association": {
      "cols": {
        "action_trigger_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "action_trigger_masking_tag_association": {
      "cols": {
        "action_trigger_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "admin_task": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "status": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "agent": {
      "cols": {
        "can_impersonate": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "email": {
          "label": "L1"
        },
        "full_name": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "is_default_login": {
          "label": "L1"
        },
        "is_reviewer": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "picture": {
          "label": "L1"
        },
        "predefined_roles": {
          "label": "L1"
        },
        "status": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "agent_alert_allowed_tag_association": {
      "cols": {
        "agent_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "default_tag": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "agent_auth": {
      "cols": {
        "agent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "external_agent_id": {
          "label": "L3"
        },
        "google_refresh_token": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "provider": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "agent_case_allowed_tag_association": {
      "cols": {
        "agent_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "default_tag": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "agent_custom_config_association": {
      "cols": {
        "agent_id": {
          "label": "L1"
        },
        "custom_config_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "agent_notification_setting": {
      "cols": {
        "agent_id": {
          "label": "L1"
        },
        "email_enabled": {
          "label": "L1"
        },
        "event": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "slack_enabled": {
          "label": "L1"
        },
        "system_enabled": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "agent_sar_allowed_tag_association": {
      "cols": {
        "agent_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "default_tag": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "agent_tag_association": {
      "cols": {
        "agent_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "agent_team_association": {
      "cols": {
        "agent_id": {
          "label": "L1"
        },
        "team_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "alembic_history": {
      "cols": {
        "id": {
          "label": "L1"
        },
        "new_version_num": {
          "label": "L1"
        },
        "old_version_num": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "alert": {
      "cols": {
        "alert_type": {
          "default_access": "R",
          "label": "L1"
        },
        "alerting_org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "assigned_at": {
          "default_access": "R",
          "label": "L1"
        },
        "assigned_to_id": {
          "default_access": "R",
          "label": "L1"
        },
        "closing_action_trigger_id": {
          "default_access": "R",
          "label": "L1"
        },
        "collaborating_org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "content": {
          "label": "L3"
        },
        "created_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "deduplication_data": {
          "default_access": "R",
          "label": "L1"
        },
        "deduplication_hash": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "disposition": {
          "label": "L3"
        },
        "disposition_type": {
          "default_access": "R",
          "label": "L1"
        },
        "dispositioned_at": {
          "default_access": "R",
          "label": "L1"
        },
        "end_date": {
          "default_access": "R",
          "label": "L1"
        },
        "external_id": {
          "label": "L3"
        },
        "external_status": {
          "label": "L3"
        },
        "group_by_external_id": {
          "label": "L3"
        },
        "group_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "group_by_types": {
          "default_access": "R",
          "label": "L1"
        },
        "histogram_data": {
          "label": "L2"
        },
        "hits": {
          "label": "L2"
        },
        "hot_storage_expires_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "investigation_data": {
          "label": "L3"
        },
        "is_investigation_validated": {
          "default_access": "R",
          "label": "L1"
        },
        "narrative": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "queue_id": {
          "default_access": "R",
          "label": "L1"
        },
        "retrieved_at": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_execution_id": {
          "default_access": "R",
          "label": "L1"
        },
        "start_date": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "title": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "work_started_on": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "alert_action": {
      "cols": {
        "agent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "alert_id": {
          "default_access": "R",
          "label": "L1"
        },
        "alert_queue_id": {
          "default_access": "R",
          "label": "L1"
        },
        "assigned_to_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "disposition": {
          "label": "L3"
        },
        "disposition_notes": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "is_bulk_action": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "status_changed_to": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "work_started_on": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "alert_action_subdisposition_association": {
      "cols": {
        "alert_action_id": {
          "label": "L1"
        },
        "option_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "alert_action_trigger": {
      "cols": {
        "alert_type": {
          "label": "L1"
        },
        "apply_tags_to_children": {
          "label": "L1"
        },
        "close_alert": {
          "label": "L1"
        },
        "create_article_queue_id": {
          "label": "L1"
        },
        "create_case": {
          "label": "L1"
        },
        "create_case_alert": {
          "label": "L1"
        },
        "create_fincen_ctr": {
          "label": "L1"
        },
        "create_sar": {
          "label": "L1"
        },
        "enable_if_invalid": {
          "label": "L1"
        },
        "enabled_for_bulk_resolutions": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "open_alert": {
          "label": "L1"
        },
        "reassignment_agent_id": {
          "label": "L1"
        },
        "reassignment_queue_id": {
          "label": "L1"
        },
        "reassignment_team_id": {
          "label": "L1"
        },
        "reassignment_type": {
          "label": "L1"
        },
        "remove_tags_from_children": {
          "label": "L1"
        },
        "requeue_agent_assignment": {
          "label": "L1"
        },
        "show_on_alert_status": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "alert_action_trigger_queue_association": {
      "cols": {
        "alert_action_trigger_id": {
          "label": "L1"
        },
        "alert_queue_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "alert_associated_alerts": {
      "cols": {
        "child_alert_id": {
          "label": "L1"
        },
        "parent_alert_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "alert_component_action_trigger": {
      "cols": {
        "id": {
          "label": "L1"
        },
        "resolution": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "alert_entity_association": {
      "cols": {
        "alert_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "resolution": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "alert_event_association": {
      "cols": {
        "alert_id": {
          "default_access": "R",
          "label": "L1"
        },
        "event_external_id": {
          "label": "L3"
        },
        "event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "resolution": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "alert_history": {
      "cols": {
        "action": {
          "label": "L1"
        },
        "alert_history_id": {
          "label": "L1"
        },
        "alert_id": {
          "label": "L1"
        },
        "event_time": {
          "label": "L1"
        },
        "new_value": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "origin_value": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "alert_hit": {
      "cols": {
        "alert_hit_type": {
          "label": "L2"
        },
        "alert_id": {
          "default_access": "R",
          "label": "L1"
        },
        "content": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "description": {
          "label": "L3"
        },
        "end_date": {
          "default_access": "R",
          "label": "L1"
        },
        "generation_status": {
          "default_access": "R",
          "label": "L1"
        },
        "group_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "group_by_types": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "object_source": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_execution_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_schedule_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_validation_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_validation_window_id": {
          "default_access": "R",
          "label": "L1"
        },
        "start_date": {
          "default_access": "R",
          "label": "L1"
        },
        "title": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "alert_hit_device_association": {
      "cols": {
        "alert_hit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "device_external_id": {
          "label": "L3"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "alert_hit_entity_association": {
      "cols": {
        "alert_hit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "alert_hit_event_association": {
      "cols": {
        "alert_hit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "event_external_id": {
          "label": "L3"
        },
        "event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "alert_hit_secondary_objects_association": {
      "cols": {
        "address_id": {
          "label": "L1"
        },
        "alert_hit_id": {
          "label": "L1"
        },
        "blacklist_business_id": {
          "label": "L1"
        },
        "blacklist_user_id": {
          "label": "L1"
        },
        "client_fingerprint_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "email_address_id": {
          "label": "L1"
        },
        "geolocation_id": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "ip_address_id": {
          "label": "L1"
        },
        "phone_number_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "alert_hit_tag_association": {
      "cols": {
        "alert_hit_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "alert_hit_txn_instrument_association": {
      "cols": {
        "alert_hit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_instrument_external_id": {
          "label": "L3"
        },
        "txn_instrument_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "alert_narrative": {
      "cols": {
        "added_to_record_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "alert_id": {
          "default_access": "R",
          "label": "L1"
        },
        "alert_narrative_template_id": {
          "default_access": "R",
          "label": "L1"
        },
        "alert_queue_id": {
          "default_access": "R",
          "label": "L1"
        },
        "archived": {
          "default_access": "R",
          "label": "L1"
        },
        "article_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "edited_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "narrative": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "alert_narrative_template": {
      "cols": {
        "archived": {
          "default_access": "R",
          "label": "L1"
        },
        "content": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "title": {
          "label": "L3"
        },
        "type": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "alert_narrative_template_alert_queue_association": {
      "cols": {
        "alert_narrative_template_id": {
          "label": "L1"
        },
        "alert_queue_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "alert_relevance_rating": {
      "cols": {
        "agent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "alert_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "value": {
          "label": "L2"
        }
      },
      "label": "L2"
    },
    "alert_secondary_objects_association": {
      "cols": {
        "address_id": {
          "label": "L1"
        },
        "alert_id": {
          "label": "L1"
        },
        "blacklist_business_id": {
          "label": "L1"
        },
        "blacklist_user_id": {
          "label": "L1"
        },
        "client_fingerprint_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "email_address_id": {
          "label": "L1"
        },
        "geolocation_id": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "ip_address_id": {
          "label": "L1"
        },
        "phone_number_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "alert_txn_instrument_association": {
      "cols": {
        "alert_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "resolution": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_instrument_external_id": {
          "label": "L3"
        },
        "txn_instrument_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "alert_verification_result_association": {
      "cols": {
        "alert_id": {
          "label": "L1"
        },
        "resolution": {
          "label": "L1"
        },
        "verification_result_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "annotation_transformation_config": {
      "cols": {
        "config": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "is_draft": {
          "label": "L1"
        },
        "last_ran_time": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "stream_id": {
          "label": "L1"
        },
        "unit21_object": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "annotation_transformation_execution": {
      "cols": {
        "api_validation_version": {
          "default_access": "R",
          "label": "L1"
        },
        "bytes_processed": {
          "default_access": "R",
          "label": "L1"
        },
        "config_id": {
          "default_access": "R",
          "label": "L1"
        },
        "errors": {
          "label": "L3"
        },
        "failure_code": {
          "default_access": "R",
          "label": "L1"
        },
        "failure_count": {
          "default_access": "R",
          "label": "L1"
        },
        "finished_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "is_dry_run": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "progress_updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "pull_based_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "queued_at": {
          "default_access": "R",
          "label": "L1"
        },
        "retry_count": {
          "default_access": "R",
          "label": "L1"
        },
        "started_at": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "success_count": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "api_key": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_by": {
          "default_access": "R",
          "label": "L1"
        },
        "deactivated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "deactivated_by": {
          "default_access": "R",
          "label": "L1"
        },
        "hashed_key": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "is_active": {
          "default_access": "R",
          "label": "L1"
        },
        "label": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "permissions": {
          "default_access": "R",
          "label": "L1"
        },
        "prefix": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "version": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "api_request": {
      "cols": {
        "alert_id": {
          "default_access": "R",
          "label": "L1"
        },
        "case_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_by": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "event_external_id": {
          "label": "L3"
        },
        "event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "external_id": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "key": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "request_data": {
          "label": "L3"
        },
        "txn_instrument_external_id": {
          "label": "L3"
        },
        "txn_instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "article": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "external_id": {
          "label": "L3"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "object_source": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "request_data": {
          "label": "L3"
        },
        "statistics": {
          "label": "L2"
        },
        "type": {
          "default_access": "R",
          "label": "L1"
        },
        "u21_status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "article_deadline": {
      "cols": {
        "absolute_due_date": {
          "label": "L1"
        },
        "article_id": {
          "label": "L1"
        },
        "config_id": {
          "label": "L1"
        },
        "derived_due_date": {
          "label": "L1"
        },
        "duration": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "start_date": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "article_deadline_config": {
      "cols": {
        "calc_start_date": {
          "default_access": "R",
          "label": "L1"
        },
        "conditions": {
          "default_access": "R",
          "label": "L1"
        },
        "duration": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "notify_before": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "priority": {
          "default_access": "R",
          "label": "L1"
        },
        "title": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "warn_before": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "article_editor_association": {
      "cols": {
        "agent_id": {
          "label": "L1"
        },
        "article_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "article_sar_association": {
      "cols": {
        "article_id": {
          "label": "L1"
        },
        "sar_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "article_tag_association": {
      "cols": {
        "article_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "article_upload_association": {
      "cols": {
        "article_id": {
          "label": "L1"
        },
        "upload_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "article_watcher_association": {
      "cols": {
        "agent_id": {
          "label": "L1"
        },
        "article_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "association_table_deletes": {
      "cols": {
        "deleted_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "left_column_id": {
          "label": "L1"
        },
        "left_column_name": {
          "label": "L1"
        },
        "right_column_id": {
          "label": "L1"
        },
        "right_column_name": {
          "label": "L1"
        },
        "synced_at": {
          "label": "L1"
        },
        "tablename": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "attachment": {
      "cols": {
        "description": {
          "label": "L3"
        },
        "embed_url": {
          "label": "L3"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "file_size": {
          "default_access": "R",
          "label": "L1"
        },
        "icon_url": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "is_external": {
          "default_access": "R",
          "label": "L1"
        },
        "media_type": {
          "default_access": "R",
          "label": "L1"
        },
        "mime_type": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "verification_form_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "audit_trail": {
      "cols": {
        "action_trigger_id": {
          "label": "L2"
        },
        "action_type": {
          "label": "L2"
        },
        "alert_action_id": {
          "label": "L2"
        },
        "api_event": {
          "label": "L2"
        },
        "author_id": {
          "label": "L2"
        },
        "case_action_id": {
          "label": "L2"
        },
        "content": {
          "label": "L2"
        },
        "content_object": {
          "label": "L2"
        },
        "created_at": {
          "label": "L2"
        },
        "entity_action_id": {
          "label": "L2"
        },
        "id": {
          "label": "L2"
        },
        "org_id": {
          "label": "L2"
        },
        "unit_id": {
          "label": "L2"
        },
        "updated_at": {
          "label": "L2"
        },
        "verification_form_id": {
          "label": "L2"
        },
        "whitelist_duration": {
          "label": "L2"
        }
      },
      "label": "L2"
    },
    "audit_trail_agent_association": {
      "cols": {
        "agent_id": {
          "label": "L2"
        },
        "audit_trail_id": {
          "label": "L2"
        },
        "label": {
          "label": "L2"
        }
      },
      "label": "L2"
    },
    "audit_trail_alert_association": {
      "cols": {
        "alert_id": {
          "label": "L2"
        },
        "audit_trail_id": {
          "label": "L2"
        },
        "label": {
          "label": "L2"
        }
      },
      "label": "L2"
    },
    "audit_trail_alert_narrative_association": {
      "cols": {
        "alert_narrative_id": {
          "label": "L2"
        },
        "audit_trail_id": {
          "label": "L2"
        },
        "label": {
          "label": "L2"
        }
      },
      "label": "L2"
    },
    "audit_trail_alert_queue_association": {
      "cols": {
        "alert_queue_id": {
          "label": "L2"
        },
        "audit_trail_id": {
          "label": "L2"
        },
        "label": {
          "label": "L2"
        }
      },
      "label": "L2"
    },
    "audit_trail_case_association": {
      "cols": {
        "audit_trail_id": {
          "label": "L2"
        },
        "case_id": {
          "label": "L2"
        },
        "label": {
          "label": "L2"
        }
      },
      "label": "L2"
    },
    "audit_trail_entity_association": {
      "cols": {
        "audit_trail_id": {
          "label": "L2"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "label": "L2"
        },
        "label": {
          "label": "L2"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "label": "L2"
        }
      },
      "label": "L3"
    },
    "audit_trail_event_association": {
      "cols": {
        "audit_trail_id": {
          "label": "L2"
        },
        "event_external_id": {
          "label": "L3"
        },
        "event_id": {
          "label": "L2"
        },
        "label": {
          "label": "L2"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "audit_trail_rule_association": {
      "cols": {
        "audit_trail_id": {
          "label": "L2"
        },
        "label": {
          "label": "L2"
        },
        "rule_id": {
          "label": "L2"
        }
      },
      "label": "L2"
    },
    "audit_trail_sar_filing_association": {
      "cols": {
        "audit_trail_id": {
          "label": "L2"
        },
        "label": {
          "label": "L2"
        },
        "sar_filing_id": {
          "label": "L2"
        }
      },
      "label": "L2"
    },
    "audit_trail_txn_instrument_association": {
      "cols": {
        "audit_trail_id": {
          "label": "L2"
        },
        "label": {
          "label": "L2"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_instrument_external_id": {
          "label": "L3"
        },
        "txn_instrument_id": {
          "label": "L2"
        }
      },
      "label": "L3"
    },
    "audit_trail_upload_association": {
      "cols": {
        "audit_trail_id": {
          "label": "L2"
        },
        "label": {
          "label": "L2"
        },
        "upload_id": {
          "label": "L2"
        }
      },
      "label": "L2"
    },
    "blacklist": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "deactivated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "type": {
          "default_access": "R",
          "label": "L1"
        },
        "u21_global": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "use_as_whitelist": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "blacklist_business": {
      "cols": {
        "blacklist_id": {
          "default_access": "R",
          "label": "L1"
        },
        "business_name": {
          "label": "L3"
        },
        "city": {
          "label": "L3"
        },
        "corporate_tax_id": {
          "label": "L3"
        },
        "country": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "dba_name": {
          "label": "L3"
        },
        "deactivated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "md5_hash": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "state": {
          "label": "L3"
        },
        "street": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "zip_code": {
          "label": "L3"
        }
      },
      "label": "L3"
    },
    "blacklist_cidr": {
      "cols": {
        "blacklist_id": {
          "default_access": "R",
          "label": "L1"
        },
        "cidr": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "deactivated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "source": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "blacklist_inet": {
      "cols": {
        "blacklist_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "deactivated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "ip_address": {
          "label": "L3"
        },
        "ip_address_text": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "source": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "blacklist_string": {
      "cols": {
        "blacklist_id": {
          "default_access": "R",
          "label": "L1"
        },
        "blacklist_value": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "deactivated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "blacklist_upload": {
      "cols": {
        "description": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "md5_hash": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "blacklist_user": {
      "cols": {
        "alias_first_name": {
          "label": "L3"
        },
        "alias_last_name": {
          "label": "L3"
        },
        "alias_middle_name": {
          "label": "L3"
        },
        "blacklist_id": {
          "default_access": "R",
          "label": "L1"
        },
        "city": {
          "label": "L3"
        },
        "client_fingerprint": {
          "label": "L3"
        },
        "country": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "date_of_birth": {
          "label": "L3"
        },
        "deactivated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "email": {
          "label": "L3"
        },
        "entity_status": {
          "label": "L3"
        },
        "entity_subtype": {
          "label": "L3"
        },
        "first_name": {
          "label": "L3"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "ip_address": {
          "label": "L3"
        },
        "last_name": {
          "label": "L3"
        },
        "md5_hash": {
          "default_access": "R",
          "label": "L1"
        },
        "middle_name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "phone_number": {
          "label": "L3"
        },
        "registered_at": {
          "label": "L3"
        },
        "ssn": {
          "label": "L3"
        },
        "state": {
          "label": "L3"
        },
        "street": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "zip_code": {
          "label": "L3"
        }
      },
      "label": "L3"
    },
    "case": {
      "cols": {
        "assigned_to_id": {
          "default_access": "R",
          "label": "L1"
        },
        "case_type": {
          "default_access": "R",
          "label": "L1"
        },
        "collaborating_org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "description": {
          "label": "L3"
        },
        "disposition": {
          "label": "L3"
        },
        "disposition_type": {
          "default_access": "R",
          "label": "L1"
        },
        "dispositioned_at": {
          "default_access": "R",
          "label": "L1"
        },
        "end_date": {
          "default_access": "R",
          "label": "L1"
        },
        "hot_storage_expires_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "queue_id": {
          "default_access": "R",
          "label": "L1"
        },
        "start_date": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "title": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "work_started_on": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "case_action": {
      "cols": {
        "agent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "alert_queue_id": {
          "default_access": "R",
          "label": "L1"
        },
        "assigned_to_id": {
          "default_access": "R",
          "label": "L1"
        },
        "case_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "disposition": {
          "label": "L3"
        },
        "disposition_notes": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "status_changed_to": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "work_started_on": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "case_action_subdisposition_association": {
      "cols": {
        "case_action_id": {
          "label": "L1"
        },
        "option_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "case_action_trigger": {
      "cols": {
        "apply_tags_to_children": {
          "label": "L1"
        },
        "apply_tags_to_related_alerts": {
          "label": "L1"
        },
        "case_type": {
          "label": "L1"
        },
        "close_case": {
          "label": "L1"
        },
        "create_article_queue_id": {
          "label": "L1"
        },
        "create_filing": {
          "label": "L1"
        },
        "enable_if_invalid": {
          "label": "L1"
        },
        "enabled_for_bulk_resolutions": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "open_case": {
          "label": "L1"
        },
        "reassignment_agent_id": {
          "label": "L1"
        },
        "reassignment_queue_id": {
          "label": "L1"
        },
        "reassignment_team_id": {
          "label": "L1"
        },
        "reassignment_type": {
          "label": "L1"
        },
        "remove_tags_from_children": {
          "label": "L1"
        },
        "remove_tags_from_related_alerts": {
          "label": "L1"
        },
        "requeue_agent_assignment": {
          "label": "L1"
        },
        "show_on_case_status": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "case_action_trigger_queue_association": {
      "cols": {
        "case_action_trigger_id": {
          "label": "L1"
        },
        "case_queue_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "case_alert_association": {
      "cols": {
        "alert_id": {
          "label": "L1"
        },
        "case_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "case_entity_association": {
      "cols": {
        "case_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "case_event_association": {
      "cols": {
        "case_id": {
          "label": "L1"
        },
        "event_external_id": {
          "label": "L3"
        },
        "event_id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "case_history": {
      "cols": {
        "action": {
          "label": "L1"
        },
        "case_history_id": {
          "label": "L1"
        },
        "case_id": {
          "label": "L1"
        },
        "event_time": {
          "label": "L1"
        },
        "new_value": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "origin_value": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "censorship": {
      "cols": {
        "agent_id": {
          "label": "L1"
        },
        "censored_fields": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "created_by_id": {
          "label": "L1"
        },
        "deleted_at": {
          "label": "L1"
        },
        "deleted_by_id": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "label": "L1"
        },
        "target_type": {
          "label": "L1"
        },
        "team_id": {
          "label": "L1"
        },
        "uncensor_fields": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        },
        "updated_by_id": {
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "censorship_agent_association": {
      "cols": {
        "agent_id": {
          "label": "L1"
        },
        "censorship_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "censorship_field_association": {
      "cols": {
        "can_uncensor": {
          "label": "L1"
        },
        "censorship_id": {
          "label": "L1"
        },
        "field_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "censorship_team_association": {
      "cols": {
        "censorship_id": {
          "label": "L1"
        },
        "team_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "chart": {
      "cols": {
        "assigned_chart_format_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "dashboard_id": {
          "default_access": "R",
          "label": "L1"
        },
        "definition_id": {
          "default_access": "R",
          "label": "L1"
        },
        "definition_override": {
          "label": "L3"
        },
        "description": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "title": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "chart_definition": {
      "cols": {
        "content": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "default_chart_format_id": {
          "label": "L1"
        },
        "description": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "title": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "chart_definition_filter_options": {
      "cols": {
        "chart_definition_id": {
          "label": "L1"
        },
        "chart_filter_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "chart_definition_format_options": {
      "cols": {
        "chart_definition_id": {
          "label": "L1"
        },
        "chart_format_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "chart_filter": {
      "cols": {
        "column_name": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "filter_name": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "is_tag": {
          "label": "L1"
        },
        "relationship_name": {
          "label": "L1"
        },
        "table_name": {
          "label": "L1"
        },
        "value_type": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "chart_format": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "title": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "client_fingerprint": {
      "cols": {
        "client_fingerprint": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "first_seen": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "last_seen": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "cold_storage_update_request": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "key": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "request_data": {
          "label": "L3"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "table_id": {
          "default_access": "R",
          "label": "L1"
        },
        "table_name": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "comment": {
      "cols": {
        "article_id": {
          "default_access": "R",
          "label": "L1"
        },
        "author_id": {
          "default_access": "R",
          "label": "L1"
        },
        "content": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "device_external_id": {
          "label": "L3"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "event_external_id": {
          "label": "L3"
        },
        "event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "instrument_external_id": {
          "label": "L3"
        },
        "instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "custom_checklist": {
      "cols": {
        "alert_default_checklist": {
          "default_access": "R",
          "label": "L1"
        },
        "archived": {
          "default_access": "R",
          "label": "L1"
        },
        "definition": {
          "label": "L3"
        },
        "description": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "modified_at": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "tag_id": {
          "default_access": "R",
          "label": "L1"
        },
        "type": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "custom_checklist_submission": {
      "cols": {
        "alert_queue_id": {
          "default_access": "R",
          "label": "L1"
        },
        "article_id": {
          "default_access": "R",
          "label": "L1"
        },
        "content": {
          "label": "L3"
        },
        "custom_checklist_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "modified_at": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "custom_checklist_template": {
      "cols": {
        "content": {
          "label": "L3"
        },
        "field": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "modified_at": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "custom_config": {
      "cols": {
        "definition": {
          "label": "L3"
        },
        "description": {
          "label": "L3"
        },
        "flag": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "modified_at": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "type": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "custom_data_setting": {
      "cols": {
        "data_type": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "key_path": {
          "label": "L3"
        },
        "org_enums_type": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "semantic_type": {
          "default_access": "R",
          "label": "L1"
        },
        "unit21_data_classifier": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "custom_profile_question": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "description": {
          "label": "L3"
        },
        "format": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "custom_profile_submission": {
      "cols": {
        "agent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_profile_question_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "submission_content": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "dashboard": {
      "cols": {
        "charts_layout": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "end_date": {
          "default_access": "R",
          "label": "L1"
        },
        "filtered_org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "filtered_unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "start_date": {
          "default_access": "R",
          "label": "L1"
        },
        "time_bucket": {
          "default_access": "R",
          "label": "L1"
        },
        "title": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "dashboard_applied_filters": {
      "cols": {
        "chart_filter_id": {
          "default_access": "R",
          "label": "L1"
        },
        "chart_filter_value": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "dashboard_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "data_setting": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "data_setting_type": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "enum_set": {
          "label": "L3"
        },
        "exported": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "key_path": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "recent_values": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit21_data_classifier": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "user_facing_label": {
          "label": "L3"
        }
      },
      "label": "L3"
    },
    "datafile": {
      "cols": {
        "file_size": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "md5_hash": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "statistics": {
          "label": "L2"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "status_changed_at": {
          "default_access": "R",
          "label": "L1"
        },
        "status_message": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "device": {
      "cols": {
        "app_version": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "external_id": {
          "label": "L3"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "manufacturer": {
          "label": "L3"
        },
        "model": {
          "label": "L3"
        },
        "network_carrier": {
          "label": "L3"
        },
        "network_cellular": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "os_name": {
          "label": "L3"
        },
        "os_version": {
          "label": "L3"
        },
        "registered_at": {
          "label": "L3"
        },
        "status": {
          "label": "L3"
        },
        "subtype": {
          "label": "L3"
        },
        "timezone": {
          "label": "L3"
        },
        "type": {
          "label": "L3"
        },
        "u21_status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "update_hash": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "device_tag_association": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "device_id": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "disposition": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "disposition_label": {
          "label": "L3"
        },
        "disposition_type": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "is_default": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "dormant_activity": {
      "cols": {
        "content": {
          "label": "L2"
        },
        "dormancy_days": {
          "label": "L2"
        },
        "end_time": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "external_id": {
          "label": "L3"
        },
        "group_type": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "registered_at": {
          "default_access": "R",
          "label": "L1"
        },
        "start_time": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "dow_jones_snapshot_metadata": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "snapshot_id": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "dow_jones_snapshot_update": {
      "cols": {
        "action": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "dj_response": {
          "label": "L1"
        },
        "dj_status": {
          "label": "L1"
        },
        "exception": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "rows_affected": {
          "label": "L1"
        },
        "snapshot_metadata_id": {
          "label": "L1"
        },
        "snapshot_update_id": {
          "label": "L1"
        },
        "status": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "dumbledore_spooled_config": {
      "cols": {
        "event_type": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "dynamic_column_calculation_execution": {
      "cols": {
        "calculated_at": {
          "label": "L1"
        },
        "column_definition_id": {
          "label": "L1"
        },
        "execution_end": {
          "label": "L1"
        },
        "execution_start": {
          "label": "L1"
        },
        "execution_type": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "dynamic_column_definition": {
      "cols": {
        "additional_query_fields": {
          "label": "L3"
        },
        "calculation_interval_hours": {
          "label": "L3"
        },
        "column_object": {
          "label": "L3"
        },
        "column_value_type": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "expression": {
          "label": "L3"
        },
        "expression_type": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "is_active": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "options": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "dynamic_column_variable": {
      "cols": {
        "column_definition_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "latest_execution_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "value_bool": {
          "label": "L3"
        },
        "value_list": {
          "label": "L3"
        },
        "value_number": {
          "label": "L3"
        },
        "value_string": {
          "label": "L3"
        }
      },
      "label": "L3"
    },
    "email_address": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "email_address": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "entity": {
      "cols": {
        "account_holder_name": {
          "label": "L3"
        },
        "corporate_tax_id": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "date_of_birth": {
          "label": "L3"
        },
        "doing_business_as": {
          "label": "L3"
        },
        "external_id": {
          "label": "L3"
        },
        "first_name": {
          "label": "L3"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "gender": {
          "label": "L3"
        },
        "histogram_data": {
          "label": "L2"
        },
        "hot_storage_expires_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "internal_entity_type": {
          "label": "L3"
        },
        "internal_status": {
          "label": "L3"
        },
        "last_name": {
          "label": "L3"
        },
        "marked_for_deletion": {
          "default_access": "R",
          "label": "L1"
        },
        "middle_name": {
          "label": "L3"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "registered_at": {
          "label": "L3"
        },
        "registered_country": {
          "label": "L3"
        },
        "registered_state": {
          "label": "L3"
        },
        "risk_score": {
          "label": "L2"
        },
        "source": {
          "label": "L3"
        },
        "ssn": {
          "label": "L3"
        },
        "status": {
          "label": "L3"
        },
        "type": {
          "label": "L3"
        },
        "u21_status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "update_hash": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "user_name": {
          "label": "L3"
        },
        "website": {
          "label": "L3"
        }
      },
      "label": "L3"
    },
    "entity_action": {
      "cols": {
        "agent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "disposition": {
          "label": "L3"
        },
        "disposition_notes": {
          "label": "L3"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "entity_action_subdisposition_association": {
      "cols": {
        "entity_action_id": {
          "label": "L1"
        },
        "option_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "entity_action_trigger": {
      "cols": {
        "id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "entity_consortium": {
      "cols": {
        "consortium_hashes": {
          "label": "L2"
        },
        "consortium_id": {
          "label": "L2"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "matches": {
          "label": "L2"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "entity_device_association": {
      "cols": {
        "device_id": {
          "label": "L1"
        },
        "entity_id": {
          "label": "L1"
        },
        "first_seen": {
          "label": "L1"
        },
        "last_seen": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "entity_parent_child_association": {
      "cols": {
        "child_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "last_registered_at": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "parent_id": {
          "label": "L1"
        },
        "relationship_config_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "entity_risk_score": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "risk_formula_id": {
          "default_access": "R",
          "label": "L1"
        },
        "score": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "entity_risk_value": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "metric_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "value": {
          "label": "L2"
        }
      },
      "label": "L3"
    },
    "entity_tag_association": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "entity_id": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "entity_to_entity_relationship_config": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "degree": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "directed": {
          "default_access": "R",
          "label": "L1"
        },
        "external_id": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "risk_type": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "entity_to_instrument_relationship_config": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "degree": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "external_id": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "risk_type": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "entity_txn_instrument_association": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "entity_id": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "last_registered_at": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "relationship_config_id": {
          "label": "L1"
        },
        "txn_instrument_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "event": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "event_time": {
          "label": "L3"
        },
        "external_id": {
          "label": "L3"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "hot_status": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "parent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "type": {
          "default_access": "R",
          "label": "L1"
        },
        "u21_status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "event_action_trigger": {
      "cols": {
        "id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "event_parent_child_association": {
      "cols": {
        "child_id": {
          "label": "L1"
        },
        "parent_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "event_tag_association": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "event_id": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "external_api_response_cache": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "http_method": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "response": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "url": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "external_connection": {
      "cols": {
        "external_connection_type": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "info_to_query_secrets_manager": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "field": {
      "cols": {
        "display_name": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "name": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "file_exports": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "export_type": {
          "label": "L1"
        },
        "exported_ids": {
          "label": "L1"
        },
        "file_name": {
          "label": "L1"
        },
        "file_size": {
          "label": "L1"
        },
        "filters": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "request_source": {
          "label": "L1"
        },
        "requested_by_id": {
          "label": "L1"
        },
        "status": {
          "label": "L1"
        },
        "status_message": {
          "label": "L1"
        },
        "task_uuid": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "geolocation": {
      "cols": {
        "address_id": {
          "default_access": "R",
          "label": "L1"
        },
        "autonomous_system_number": {
          "label": "L3"
        },
        "autonomous_system_organization": {
          "label": "L3"
        },
        "city": {
          "label": "L3"
        },
        "country": {
          "label": "L3"
        },
        "country_iso": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "external_id": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "ip_address_id": {
          "default_access": "R",
          "label": "L1"
        },
        "latitude": {
          "label": "L3"
        },
        "longitude": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "postal_code": {
          "label": "L3"
        },
        "subdivision_0_iso": {
          "label": "L3"
        },
        "txn_instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "global_entity_whitelist": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "expiration": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "global_txn_instrument_whitelist": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "expiration": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_instrument_external_id": {
          "label": "L3"
        },
        "txn_instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "health_check": {
      "cols": {
        "application_name": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "instance_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "impersonation_log": {
      "cols": {
        "additional_details": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "ended_at": {
          "default_access": "R",
          "label": "L1"
        },
        "from_agent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "impersonation_action": {
          "default_access": "R",
          "label": "L1"
        },
        "impersonation_reason": {
          "default_access": "R",
          "label": "L1"
        },
        "support_ticket_id": {
          "default_access": "R",
          "label": "L1"
        },
        "to_agent_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "instrument_tag_association": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        },
        "txn_instrument_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "internal_event": {
      "cols": {
        "entity_id": {
          "label": "L1"
        },
        "first_reviewed_on": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "internal_event_name": {
          "label": "L1"
        },
        "internal_event_type": {
          "label": "L1"
        },
        "location": {
          "label": "L1"
        },
        "review_reason": {
          "label": "L1"
        },
        "review_status": {
          "label": "L1"
        },
        "review_updated_on": {
          "label": "L1"
        },
        "source": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "ip_address": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "first_seen": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "ip_address": {
          "label": "L3"
        },
        "last_seen": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "manual_bulk_action": {
      "cols": {
        "action_trigger_id": {
          "default_access": "R",
          "label": "L1"
        },
        "agent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "notes": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "query_id": {
          "default_access": "R",
          "label": "L1"
        },
        "subdisposition_options": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "manual_bulk_action_execution": {
      "cols": {
        "alerts_count": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "end_time": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "is_manual_execution": {
          "label": "L1"
        },
        "manual_bulk_action_id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "start_time": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "ml_entity_risk_result": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "ml_model_id": {
          "default_access": "R",
          "label": "L1"
        },
        "ml_run_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "value": {
          "label": "L2"
        },
        "window_end": {
          "default_access": "R",
          "label": "L1"
        },
        "window_start": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "ml_model": {
      "cols": {
        "config": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "data_version": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "last_date": {
          "label": "L1"
        },
        "last_hit_data": {
          "label": "L1"
        },
        "model_name": {
          "label": "L1"
        },
        "model_version": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "status": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "ml_priority_alert_result": {
      "cols": {
        "alert_hit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "ml_model_id": {
          "default_access": "R",
          "label": "L1"
        },
        "ml_run_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "value": {
          "label": "L2"
        }
      },
      "label": "L2"
    },
    "ml_priority_alert_score": {
      "cols": {
        "alert_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "is_high_risk": {
          "label": "L2"
        },
        "max_response_score": {
          "label": "L2"
        },
        "ml_model_id": {
          "default_access": "R",
          "label": "L1"
        },
        "ml_priority_alert_result_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "shap_values": {
          "label": "L2"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L2"
    },
    "ml_run": {
      "cols": {
        "celery_task_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "namespace": {
      "cols": {
        "connection_id": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "name": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "pull_frequency": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "native_data_setting": {
      "cols": {
        "action_event_field": {
          "label": "L1"
        },
        "address_field": {
          "label": "L1"
        },
        "entity_field": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "instrument_field": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "txn_event_field": {
          "label": "L1"
        },
        "unit21_data_classifier": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "note": {
      "cols": {
        "article_id": {
          "default_access": "R",
          "label": "L1"
        },
        "author_id": {
          "default_access": "R",
          "label": "L1"
        },
        "content": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "notification": {
      "cols": {
        "content": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_type": {
          "default_access": "R",
          "label": "L1"
        },
        "error": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "mode": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "notification_agent_association": {
      "cols": {
        "agent_id": {
          "label": "L1"
        },
        "notification_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "org": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "display_name": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "is_parent": {
          "label": "L1"
        },
        "is_unit21_customer": {
          "label": "L1"
        },
        "name": {
          "label": "L1"
        },
        "parent_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "org_count": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "tag_count": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "org_custom_data_settings_config": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "data_type": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "enum_set": {
          "label": "L3"
        },
        "exported": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "key_path": {
          "label": "L3"
        },
        "org_enums_type": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "semantic_type": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit21_data_classifier": {
          "default_access": "R",
          "label": "L1"
        },
        "user_facing_label": {
          "label": "L3"
        }
      },
      "label": "L3"
    },
    "org_email": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "domain": {
          "label": "L1"
        },
        "email": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "org_risk_weight": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "metric_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "weight": {
          "label": "L2"
        }
      },
      "label": "L2"
    },
    "org_setting": {
      "cols": {
        "alert_config": {
          "default_access": "R",
          "label": "L1"
        },
        "api_config": {
          "default_access": "R",
          "label": "L1"
        },
        "case_config": {
          "default_access": "R",
          "label": "L1"
        },
        "consortium_config": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "currency_config": {
          "default_access": "R",
          "label": "L1"
        },
        "customer_secrets": {
          "label": "L3"
        },
        "data_config": {
          "default_access": "R",
          "label": "L1"
        },
        "datafile_schema_config": {
          "default_access": "R",
          "label": "L1"
        },
        "encryption_keys": {
          "label": "L3"
        },
        "features": {
          "default_access": "R",
          "label": "L1"
        },
        "home_currency_code": {
          "default_access": "R",
          "label": "L1"
        },
        "hot_storage_config": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "kyt_config": {
          "default_access": "R",
          "label": "L1"
        },
        "link_analysis_config": {
          "default_access": "R",
          "label": "L1"
        },
        "login_modes": {
          "default_access": "R",
          "label": "L1"
        },
        "lumos_config": {
          "default_access": "R",
          "label": "L1"
        },
        "org_enums": {
          "default_access": "R",
          "label": "L1"
        },
        "org_enums_config": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "query_builder_config": {
          "default_access": "R",
          "label": "L1"
        },
        "risk_rating_config": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_execution_config": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_relevance_metric_config": {
          "default_access": "R",
          "label": "L1"
        },
        "sar_config": {
          "default_access": "R",
          "label": "L1"
        },
        "scenario_config": {
          "default_access": "R",
          "label": "L1"
        },
        "storage_config": {
          "label": "L3"
        },
        "storage_resources": {
          "default_access": "R",
          "label": "L1"
        },
        "support_url": {
          "default_access": "R",
          "label": "L1"
        },
        "tag_limit": {
          "default_access": "R",
          "label": "L1"
        },
        "unit21_id_config": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "verification_config": {
          "default_access": "R",
          "label": "L1"
        },
        "verification_custom_data_config": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "org_switcher_log": {
      "cols": {
        "created_at": {
          "label": "L2"
        },
        "default_agent_id": {
          "label": "L2"
        },
        "ended_at": {
          "label": "L2"
        },
        "id": {
          "label": "L2"
        },
        "switched_to_agent_id": {
          "label": "L2"
        }
      },
      "label": "L2"
    },
    "org_txn_histogram": {
      "cols": {
        "histogram_data": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "txn_count": {
          "label": "L1"
        },
        "txn_type_count": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "parsed_account_number_routing_number": {
      "cols": {
        "account_number": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "equality_hash": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "instrument_external_id": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "parsed_routing_number_format": {
          "default_access": "R",
          "label": "L1"
        },
        "routing_number": {
          "label": "L3"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "parsed_historical_email_address": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "parsed_domain": {
          "label": "L3"
        },
        "parsed_email_hash": {
          "default_access": "R",
          "label": "L1"
        },
        "parsed_numeric_free_username": {
          "label": "L3"
        },
        "parsed_username": {
          "label": "L3"
        },
        "raw": {
          "label": "L3"
        },
        "short_term_domain_service": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "permission": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "description": {
          "label": "L1"
        },
        "feature": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "is_staff_permission": {
          "label": "L1"
        },
        "name": {
          "label": "L1"
        },
        "type": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "permission_agent_association": {
      "cols": {
        "agent_id": {
          "label": "L1"
        },
        "permission_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "permission_team_association": {
      "cols": {
        "permission_id": {
          "label": "L1"
        },
        "team_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "phone_number": {
      "cols": {
        "country": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "external_id": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "is_verified": {
          "default_access": "R",
          "label": "L1"
        },
        "number": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "physical_id": {
      "cols": {
        "address_id": {
          "default_access": "R",
          "label": "L1"
        },
        "country": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "dob": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "expires_at": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "id_number": {
          "label": "L3"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "registered_on": {
          "label": "L3"
        },
        "state": {
          "label": "L3"
        },
        "status": {
          "label": "L3"
        },
        "type": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "precomputed_chart_data": {
      "cols": {
        "chart_object": {
          "default_access": "R",
          "label": "L1"
        },
        "content": {
          "label": "L2"
        },
        "end_time": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "start_time": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L2"
    },
    "pull_based_datafile": {
      "cols": {
        "bytes_processed": {
          "default_access": "R",
          "label": "L1"
        },
        "date_created": {
          "default_access": "R",
          "label": "L1"
        },
        "date_updated": {
          "default_access": "R",
          "label": "L1"
        },
        "failure_code": {
          "default_access": "R",
          "label": "L1"
        },
        "file_name": {
          "label": "L3"
        },
        "file_size": {
          "default_access": "R",
          "label": "L1"
        },
        "file_type": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "legacy_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "md5_hash": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "process_end_time": {
          "default_access": "R",
          "label": "L1"
        },
        "process_start_time": {
          "default_access": "R",
          "label": "L1"
        },
        "processed_records": {
          "default_access": "R",
          "label": "L1"
        },
        "queued_at": {
          "default_access": "R",
          "label": "L1"
        },
        "s3_bucket": {
          "default_access": "R",
          "label": "L1"
        },
        "s3_path": {
          "label": "L3"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "stream_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "queue": {
      "cols": {
        "archived_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_by": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_checklist_id": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "high_threshold": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "low_threshold": {
          "default_access": "R",
          "label": "L1"
        },
        "org_default_queue": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "queue_deadline_config_id": {
          "default_access": "R",
          "label": "L1"
        },
        "sort_by": {
          "default_access": "R",
          "label": "L1"
        },
        "title": {
          "label": "L3"
        },
        "type": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "queue_deadline_config": {
      "cols": {
        "duration": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "notify_before": {
          "label": "L1"
        },
        "warn_before": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "queue_team_association": {
      "cols": {
        "alert_queue_id": {
          "label": "L1"
        },
        "team_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "risk_formula": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "default_formula": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "title": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "risk_formula_metric_weight": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "risk_formula_id": {
          "label": "L1"
        },
        "risk_metric_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        },
        "weight": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "risk_formula_metric_weight_mapping": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "function": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "input": {
          "label": "L1"
        },
        "metric_weight_id": {
          "label": "L1"
        },
        "output": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "risk_metric": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "type": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "risk_metric_stats_by_entity": {
      "cols": {
        "age_in_months": {
          "default_access": "R",
          "label": "L1"
        },
        "alert_count": {
          "default_access": "R",
          "label": "L1"
        },
        "case_count": {
          "default_access": "R",
          "label": "L1"
        },
        "cross_border_transaction_count": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "instrument_count": {
          "default_access": "R",
          "label": "L1"
        },
        "kyc_alert_count": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_event_count": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "risk_metric_stats_by_org": {
      "cols": {
        "avg_cb_txn_count_per_entity": {
          "label": "L1"
        },
        "avg_instrument_count_per_entity": {
          "label": "L1"
        },
        "avg_txn_count_per_entity": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "stddev_cb_txn_count_per_entity": {
          "label": "L1"
        },
        "stddev_instrument_count_per_entity": {
          "label": "L1"
        },
        "stddev_txn_count_per_entity": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "rule": {
      "cols": {
        "activated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "actual_created_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "alert_queue_id": {
          "default_access": "R",
          "label": "L1"
        },
        "content": {
          "label": "L3"
        },
        "created_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "customer_support_involved": {
          "default_access": "R",
          "label": "L1"
        },
        "deploy_end_date": {
          "default_access": "R",
          "label": "L1"
        },
        "deploy_start_date": {
          "default_access": "R",
          "label": "L1"
        },
        "derived_from_id": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "execution_engine": {
          "default_access": "R",
          "label": "L1"
        },
        "execution_frequency": {
          "default_access": "R",
          "label": "L1"
        },
        "external_id": {
          "label": "L3"
        },
        "generates_alerts": {
          "default_access": "R",
          "label": "L1"
        },
        "group_by_types": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "is_synchronous": {
          "default_access": "R",
          "label": "L1"
        },
        "migrated_from_id": {
          "default_access": "R",
          "label": "L1"
        },
        "options": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "relevance_score": {
          "default_access": "R",
          "label": "L1"
        },
        "runs_independently": {
          "default_access": "R",
          "label": "L1"
        },
        "runs_on_org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "scenario_id": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "template_id": {
          "default_access": "R",
          "label": "L1"
        },
        "title": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "visible_to_child_org": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "rule_alert_association": {
      "cols": {
        "alert_id": {
          "label": "L1"
        },
        "rule_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "rule_blacklist_association": {
      "cols": {
        "blacklist_id": {
          "label": "L1"
        },
        "rule_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "rule_case_association": {
      "cols": {
        "case_id": {
          "label": "L1"
        },
        "rule_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "rule_entity_association": {
      "cols": {
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "rule_entity_whitelist_association": {
      "cols": {
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "expiration": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "rule_event_association": {
      "cols": {
        "event_external_id": {
          "label": "L3"
        },
        "event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "rule_execution": {
      "cols": {
        "end_time": {
          "default_access": "R",
          "label": "L1"
        },
        "exec_range_end": {
          "default_access": "R",
          "label": "L1"
        },
        "exec_range_start": {
          "default_access": "R",
          "label": "L1"
        },
        "execution_type": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_id": {
          "default_access": "R",
          "label": "L1"
        },
        "start_time": {
          "default_access": "R",
          "label": "L1"
        },
        "statistics": {
          "label": "L2"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L2"
    },
    "rule_execution_entity_association": {
      "cols": {
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_execution_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "rule_execution_schedule_association": {
      "cols": {
        "execution_type": {
          "label": "L1"
        },
        "rule_execution_id": {
          "label": "L1"
        },
        "rule_schedule_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "rule_metric": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "description": {
          "label": "L1"
        },
        "display_name": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "name": {
          "label": "L1"
        },
        "type": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "rule_metric_value": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "failure_reason": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_metric_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_by": {
          "default_access": "R",
          "label": "L1"
        },
        "value": {
          "label": "L2"
        }
      },
      "label": "L3"
    },
    "rule_migration_parent_child_association": {
      "cols": {
        "child_shadow_rule_id": {
          "default_access": "R",
          "label": "L1"
        },
        "failure_reason": {
          "label": "L3"
        },
        "latest_transformer_version": {
          "default_access": "R",
          "label": "L1"
        },
        "parent_active_rule_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "rule_promotion_metrics": {
      "cols": {
        "actual_imported_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "error_content": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "imported_at": {
          "default_access": "R",
          "label": "L1"
        },
        "imported_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "num_missing_blacklist": {
          "label": "L2"
        },
        "num_missing_event_entity_tags": {
          "label": "L2"
        },
        "num_missing_rule_tags": {
          "label": "L2"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "rule_query": {
      "cols": {
        "agent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "db_engine": {
          "default_access": "R",
          "label": "L1"
        },
        "error_result": {
          "label": "L3"
        },
        "execution_end": {
          "default_access": "R",
          "label": "L1"
        },
        "execution_start": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "query_group_id": {
          "default_access": "R",
          "label": "L1"
        },
        "query_string": {
          "label": "L3"
        },
        "rule_execution_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_schedule_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_validation_id": {
          "default_access": "R",
          "label": "L1"
        },
        "success_rows_returned": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "window_end": {
          "default_access": "R",
          "label": "L1"
        },
        "window_start": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "rule_relevance_metric": {
      "cols": {
        "alerts_with_cases": {
          "label": "L1"
        },
        "alerts_with_negative_disposition": {
          "label": "L1"
        },
        "alerts_with_negative_survey": {
          "label": "L1"
        },
        "alerts_with_positive_disposition": {
          "label": "L1"
        },
        "alerts_with_positive_survey": {
          "label": "L1"
        },
        "alerts_with_sars": {
          "label": "L1"
        },
        "alerts_with_webhooks": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "rule_id": {
          "label": "L1"
        },
        "total_alerts": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "rule_schedule": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "execution_completed_at": {
          "label": "L1"
        },
        "execution_started_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "retry_count": {
          "label": "L1"
        },
        "rule_id": {
          "label": "L1"
        },
        "schedule_status": {
          "label": "L1"
        },
        "schedule_type": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        },
        "window_end": {
          "label": "L1"
        },
        "window_start": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "rule_schedule_shadow": {
      "cols": {
        "claimed_at": {
          "label": "L1"
        },
        "claimed_by": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "execution_completed_at": {
          "label": "L1"
        },
        "execution_started_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "original_schedule_id": {
          "label": "L1"
        },
        "retry_count": {
          "label": "L1"
        },
        "rule_id": {
          "label": "L1"
        },
        "schedule_status": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        },
        "window_end": {
          "label": "L1"
        },
        "window_group": {
          "label": "L1"
        },
        "window_start": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "rule_scheduler_run_per_rule": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "execution_completed_at": {
          "label": "L1"
        },
        "execution_started_at": {
          "label": "L1"
        },
        "rule_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "rule_template": {
      "cols": {
        "content": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "description": {
          "label": "L1"
        },
        "display_name": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "industries": {
          "label": "L1"
        },
        "popularity_score": {
          "label": "L1"
        },
        "popularity_stats": {
          "label": "L1"
        },
        "title": {
          "label": "L1"
        },
        "type": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "rule_txn_instrument_association": {
      "cols": {
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_id": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_instrument_external_id": {
          "label": "L3"
        },
        "txn_instrument_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "rule_txn_instrument_whitelist_association": {
      "cols": {
        "expiration": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_id": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_instrument_external_id": {
          "label": "L3"
        },
        "txn_instrument_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "rule_validation": {
      "cols": {
        "completed_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "exec_range_end": {
          "default_access": "R",
          "label": "L1"
        },
        "exec_range_start": {
          "default_access": "R",
          "label": "L1"
        },
        "hash_key": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "progress": {
          "default_access": "R",
          "label": "L1"
        },
        "results": {
          "label": "L3"
        },
        "rule_content": {
          "label": "L3"
        },
        "rule_id": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "rule_validation_histogram": {
      "cols": {
        "category": {
          "default_access": "R",
          "label": "L1"
        },
        "category_sum": {
          "label": "L2"
        },
        "category_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "category_transfer_direction": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_validation_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L2"
    },
    "rule_validation_result": {
      "cols": {
        "alert_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "column_name": {
          "default_access": "R",
          "label": "L1"
        },
        "column_type": {
          "default_access": "R",
          "label": "L1"
        },
        "column_value": {
          "label": "L2"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "hit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "rule_validation_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L2"
    },
    "rule_validation_window": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "processed_at": {
          "label": "L1"
        },
        "rule_id": {
          "label": "L1"
        },
        "rule_validation_id": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        },
        "window_end": {
          "label": "L1"
        },
        "window_start": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "sar_entity_association": {
      "cols": {
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "sar_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "sar_event_association": {
      "cols": {
        "event_external_id": {
          "label": "L3"
        },
        "event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "sar_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "sar_filing": {
      "cols": {
        "archived": {
          "default_access": "R",
          "label": "L1"
        },
        "car_filing_pending": {
          "default_access": "R",
          "label": "L1"
        },
        "content": {
          "label": "L3"
        },
        "created_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "description": {
          "label": "L3"
        },
        "first_alert_created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "hot_storage_expires_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "lock": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "queue_id": {
          "default_access": "R",
          "label": "L1"
        },
        "report_type": {
          "default_access": "R",
          "label": "L1"
        },
        "reviewer_id": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "status_updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "sar_filing_history": {
      "cols": {
        "action": {
          "label": "L1"
        },
        "event_time": {
          "label": "L1"
        },
        "new_value": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "origin_value": {
          "label": "L1"
        },
        "sar_filing_history_id": {
          "label": "L1"
        },
        "sar_filing_id": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "sar_filing_submission": {
      "cols": {
        "bsa_id": {
          "default_access": "R",
          "label": "L1"
        },
        "bsa_tracking_id": {
          "default_access": "R",
          "label": "L1"
        },
        "content": {
          "label": "L3"
        },
        "generated_xml": {
          "label": "L3"
        },
        "generated_xml_filename": {
          "default_access": "R",
          "label": "L1"
        },
        "generated_xml_md5": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "sar_filing_id": {
          "default_access": "R",
          "label": "L1"
        },
        "submission_date": {
          "default_access": "R",
          "label": "L1"
        },
        "submission_result_message": {
          "label": "L3"
        },
        "submit_to_fincen": {
          "default_access": "R",
          "label": "L1"
        },
        "submitted_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "sar_filing_template": {
      "cols": {
        "agent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "content": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "tag_id": {
          "default_access": "R",
          "label": "L1"
        },
        "template_type": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "sar_narrative_template": {
      "cols": {
        "content": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "tag_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "scenario": {
      "cols": {
        "content": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "name": {
          "label": "L1"
        },
        "scenario_type": {
          "label": "L1"
        },
        "window_type": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "scheduled_polling_log": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "last_polled_time": {
          "label": "L1"
        },
        "scheduled_polling_type_name": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "scheduled_polling_type": {
      "cols": {
        "name": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "session": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "expires_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "session_id": {
          "label": "L1"
        },
        "session_type": {
          "label": "L1"
        },
        "unit_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "sixtran_jobs": {
      "cols": {
        "last_runtime": {
          "label": "L1"
        },
        "starting_id": {
          "label": "L1"
        },
        "status": {
          "label": "L1"
        },
        "synced_table": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "slack_meta": {
      "cols": {
        "access_token": {
          "label": "L3"
        },
        "agent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "bot_access_token": {
          "label": "L3"
        },
        "bot_user_id": {
          "default_access": "R",
          "label": "L1"
        },
        "channel": {
          "label": "L3"
        },
        "channel_id": {
          "default_access": "R",
          "label": "L1"
        },
        "configuration_url": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "scope": {
          "default_access": "R",
          "label": "L1"
        },
        "team_id": {
          "default_access": "R",
          "label": "L1"
        },
        "team_name": {
          "label": "L3"
        },
        "type": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "url": {
          "label": "L3"
        },
        "user_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "stats_reporter_log": {
      "cols": {
        "content": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "error": {
          "label": "L3"
        },
        "host": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "stream": {
      "cols": {
        "annotation_transformation_config_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "expected_column_set": {
          "default_access": "R",
          "label": "L1"
        },
        "file_validation_mode": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "last_ran": {
          "default_access": "R",
          "label": "L1"
        },
        "mode": {
          "default_access": "R",
          "label": "L1"
        },
        "namespace_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "source_object_name": {
          "label": "L3"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "validation_setting": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "stream_extract_and_load_execution": {
      "cols": {
        "error_reason": {
          "label": "L3"
        },
        "finished_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "row_count": {
          "default_access": "R",
          "label": "L1"
        },
        "started_at": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "stream_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "subdisposition": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "title": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "subdisposition_action_trigger_association": {
      "cols": {
        "action_trigger_id": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "subdisposition_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "subdisposition_option": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "subdisposition_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "table_view_config": {
      "cols": {
        "agent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "columns": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "type_classifier": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "tag": {
      "cols": {
        "color": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "type": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "team": {
      "cols": {
        "alert_assignment_limit": {
          "default_access": "R",
          "label": "L1"
        },
        "allow_assign_others_alerts": {
          "default_access": "R",
          "label": "L1"
        },
        "allow_listing_unassigned_alerts": {
          "default_access": "R",
          "label": "L1"
        },
        "allow_listing_unassigned_entities": {
          "default_access": "R",
          "label": "L1"
        },
        "allow_rule_deployment": {
          "default_access": "R",
          "label": "L1"
        },
        "allow_self_assigning_alerts": {
          "default_access": "R",
          "label": "L1"
        },
        "allow_tag_create": {
          "default_access": "R",
          "label": "L1"
        },
        "allow_tag_edit": {
          "default_access": "R",
          "label": "L1"
        },
        "allow_viewing_entity_page_of_unassigned_alerts": {
          "default_access": "R",
          "label": "L1"
        },
        "allow_viewing_unassigned_alerts": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "roles_list": {
          "default_access": "R",
          "label": "L1"
        },
        "self_signup_enabled": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "team_alert_assignment_tag_association": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        },
        "team_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "team_allowed_tag_association": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "tag_id": {
          "label": "L1"
        },
        "team_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "txn_event": {
      "cols": {
        "amount": {
          "label": "L3"
        },
        "digital_data_link": {
          "default_access": "R",
          "label": "L1"
        },
        "event_time": {
          "label": "L3"
        },
        "exchange_rate": {
          "label": "L3"
        },
        "external_fee": {
          "label": "L3"
        },
        "external_txn_type": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "internal_fee": {
          "label": "L3"
        },
        "internal_txn_type": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "received_amount": {
          "label": "L3"
        },
        "received_currency": {
          "label": "L3"
        },
        "receiver_entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "receiver_id": {
          "label": "L3"
        },
        "receiver_instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "receiver_source": {
          "default_access": "R",
          "label": "L1"
        },
        "sender_entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "sender_id": {
          "label": "L3"
        },
        "sender_instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "sender_source": {
          "default_access": "R",
          "label": "L1"
        },
        "sent_amount": {
          "label": "L3"
        },
        "sent_currency": {
          "label": "L3"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "transaction_hash": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "usd_conversion_notes": {
          "label": "L3"
        }
      },
      "label": "L3"
    },
    "txn_instrument": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "external_id": {
          "label": "L3"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "gateway": {
          "default_access": "R",
          "label": "L1"
        },
        "histogram_data": {
          "label": "L2"
        },
        "hot_storage_expires_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "instrument_subtype": {
          "label": "L3"
        },
        "instrument_type": {
          "label": "L3"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "parent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "registered_at": {
          "label": "L3"
        },
        "request_data": {
          "label": "L3"
        },
        "source": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "label": "L3"
        },
        "u21_status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "update_hash": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "u21_agent_auth": {
      "cols": {
        "agent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "expires_at": {
          "default_access": "R",
          "label": "L1"
        },
        "refresh_token": {
          "label": "L3"
        }
      },
      "label": "L3"
    },
    "undirected_entity_entity_to_entity_relationship": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "first_entity_id": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "last_registered_at": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        },
        "relationship_config_id": {
          "label": "L1"
        },
        "second_entity_id": {
          "label": "L1"
        },
        "updated_at": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "unit": {
      "cols": {
        "created_at": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "name": {
          "label": "L1"
        },
        "org_id": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "upload": {
      "cols": {
        "archived_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "original_file_name": {
          "label": "L3"
        },
        "stored_in": {
          "default_access": "R",
          "label": "L1"
        },
        "type": {
          "default_access": "R",
          "label": "L1"
        },
        "u21_status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "uploaded_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "url": {
          "label": "L3"
        }
      },
      "label": "L3"
    },
    "verification_form": {
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "form_data": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "session_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "verification_result": {
      "cols": {
        "content": {
          "default_access": "R",
          "label": "L1"
        },
        "continuous_monitoring": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "formatted_response": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "object_source": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "override_key_used": {
          "default_access": "R",
          "label": "L1"
        },
        "source": {
          "default_access": "R",
          "label": "L1"
        },
        "suppressed_provider_ids": {
          "default_access": "R",
          "label": "L1"
        },
        "type": {
          "default_access": "R",
          "label": "L1"
        },
        "u21_status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "verification_form_id": {
          "default_access": "R",
          "label": "L1"
        },
        "verification_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "verification_workflow_execution_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "verification_service": {
      "cols": {
        "available_on_demand": {
          "label": "L1"
        },
        "configuration_options": {
          "label": "L1"
        },
        "created_at": {
          "label": "L1"
        },
        "end_outcomes": {
          "label": "L1"
        },
        "external_id": {
          "label": "L1"
        },
        "icon_url": {
          "label": "L1"
        },
        "id": {
          "label": "L1"
        },
        "name": {
          "label": "L1"
        },
        "submission_sections": {
          "label": "L1"
        },
        "type": {
          "label": "L1"
        }
      },
      "default_access": "R",
      "label": "L1"
    },
    "verification_workflow": {
      "cols": {
        "alert_queue_id": {
          "default_access": "R",
          "label": "L1"
        },
        "content": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "description": {
          "label": "L3"
        },
        "entity_type": {
          "default_access": "R",
          "label": "L1"
        },
        "external_id": {
          "label": "L3"
        },
        "flow_chart": {
          "default_access": "R",
          "label": "L1"
        },
        "forms_config": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "is_enabled": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_setting_id": {
          "default_access": "R",
          "label": "L1"
        },
        "synchronous": {
          "default_access": "R",
          "label": "L1"
        },
        "title": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "verification_workflow_execution": {
      "cols": {
        "alert_id": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "end_action": {
          "default_access": "R",
          "label": "L1"
        },
        "end_time": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "is_success": {
          "default_access": "R",
          "label": "L1"
        },
        "message": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "start_time": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "verification_form_id": {
          "default_access": "R",
          "label": "L1"
        },
        "verification_workflow_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    },
    "webhook": {
      "cols": {
        "action_types": {
          "default_access": "R",
          "label": "L1"
        },
        "callback_count": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "created_by_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "is_active": {
          "default_access": "R",
          "label": "L1"
        },
        "is_deleted": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "secret_key": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "url": {
          "label": "L3"
        }
      },
      "label": "L3"
    },
    "webhook_callback_attempt": {
      "cols": {
        "action_type": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "event_created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "message": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "request_body": {
          "label": "L3"
        },
        "retries": {
          "default_access": "R",
          "label": "L1"
        },
        "status_code": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "url": {
          "label": "L3"
        },
        "webhook_id": {
          "default_access": "R",
          "label": "L1"
        }
      },
      "label": "L3"
    }
  }
}`);

export const mockGetDataLabelsDeltaLakeResponse = JSON.parse(`
{
  "silver": {
    "action_event": {
      "label": "L3",
      "cols": {
        "action_content": {
          "label": "L3"
        },
        "action_type": {
          "label": "L3"
        },
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "event_time": {
          "label": "L3"
        },
        "external_id": {
          "label": "L3"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "label": "L3"
        },
        "u21_status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "action_event_tag_association": {
      "label": "L3",
      "cols": {
        "action_event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_partition_pushdown": {
          "label": "L3"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "tag_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "address": {
      "label": "L3",
      "cols": {
        "action_event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "building_number": {
          "label": "L3"
        },
        "city": {
          "label": "L3"
        },
        "country": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "equality_hash": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_partition_pushdown": {
          "label": "L3"
        },
        "primary_object_type": {
          "default_access": "R",
          "label": "L1"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "state": {
          "label": "L3"
        },
        "street": {
          "label": "L3"
        },
        "txn_event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "type": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_number": {
          "label": "L3"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "zip_code": {
          "label": "L3"
        }
      }
    },
    "client_fingerprint": {
      "label": "L3",
      "cols": {
        "action_event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "client_fingerprint": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_partition_pushdown": {
          "label": "L3"
        },
        "primary_object_type": {
          "default_access": "R",
          "label": "L1"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "device": {
      "label": "L3",
      "cols": {
        "app_version": {
          "label": "L3"
        },
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "date_first_seen": {
          "label": "L3"
        },
        "external_id": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "manufacturer": {
          "label": "L3"
        },
        "model": {
          "label": "L3"
        },
        "network_carrier": {
          "label": "L3"
        },
        "network_cellular": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "os_name": {
          "label": "L3"
        },
        "os_version": {
          "label": "L3"
        },
        "registered_at": {
          "label": "L3"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "label": "L3"
        },
        "subtype": {
          "label": "L3"
        },
        "timezone": {
          "label": "L3"
        },
        "type": {
          "label": "L3"
        },
        "u21_status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "device_tag_association": {
      "label": "L3",
      "cols": {
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_partition_pushdown": {
          "label": "L3"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "tag_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "email_address": {
      "label": "L3",
      "cols": {
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "email_address": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_partition_pushdown": {
          "label": "L3"
        },
        "primary_object_type": {
          "default_access": "R",
          "label": "L1"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "entity": {
      "label": "L3",
      "cols": {
        "account_holder_name": {
          "label": "L3"
        },
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "business_name": {
          "label": "L3"
        },
        "corporate_tax_id": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "date_first_seen": {
          "label": "L3"
        },
        "date_of_birth": {
          "label": "L3"
        },
        "doing_business_as": {
          "label": "L3"
        },
        "external_id": {
          "label": "L3"
        },
        "first_name": {
          "label": "L3"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "gender": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "internal_entity_type": {
          "label": "L3"
        },
        "internal_status": {
          "label": "L3"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "last_name": {
          "label": "L3"
        },
        "middle_name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "registered_at": {
          "label": "L3"
        },
        "registered_country": {
          "label": "L3"
        },
        "registered_state": {
          "label": "L3"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "source": {
          "label": "L3"
        },
        "ssn": {
          "label": "L3"
        },
        "status": {
          "label": "L3"
        },
        "type": {
          "label": "L3"
        },
        "u21_status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "user_name": {
          "label": "L3"
        },
        "website": {
          "label": "L3"
        }
      }
    },
    "entity_consortium": {
      "label": "L3",
      "cols": {
        "blocked": {
          "default_access": "R",
          "label": "L1"
        },
        "blocked_amount": {
          "label": "L2"
        },
        "blocked_reasons": {
          "label": "L2"
        },
        "consortium_hashes": {
          "label": "L2"
        },
        "consortium_id": {
          "label": "L2"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_external_id": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "last_seen": {
          "default_access": "R",
          "label": "L1"
        },
        "last_synced": {
          "default_access": "R",
          "label": "L1"
        },
        "matches": {
          "label": "L2"
        },
        "number_of_matched_orgs": {
          "label": "L2"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "registered_at": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "entity_device_association": {
      "label": "L3",
      "cols": {
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "entity_entity_relationship": {
      "label": "L3",
      "cols": {
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "first_entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "relationship_config_external_id": {
          "label": "L3"
        },
        "second_entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "entity_instrument_relationship": {
      "label": "L3",
      "cols": {
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "relationship_config_external_id": {
          "label": "L3"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "entity_tag_association": {
      "label": "L3",
      "cols": {
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_partition_pushdown": {
          "label": "L3"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "tag_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "entity_txn_event_association": {
      "label": "L3",
      "cols": {
        "amount": {
          "label": "L3"
        },
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "data_setting_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "flow": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "geolocation": {
      "label": "L3",
      "cols": {
        "action_event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "autonomous_system_number": {
          "label": "L3"
        },
        "autonomous_system_organization": {
          "label": "L3"
        },
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "city": {
          "label": "L3"
        },
        "country": {
          "label": "L3"
        },
        "country_iso": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "ip_address_id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "latitude": {
          "label": "L3"
        },
        "longitude": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "postal_code": {
          "label": "L3"
        },
        "primary_object_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_partition_pushdown": {
          "label": "L3"
        },
        "primary_object_type": {
          "default_access": "R",
          "label": "L1"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "subdivision_0_iso": {
          "label": "L3"
        },
        "txn_event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "instrument": {
      "label": "L3",
      "cols": {
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "date_first_seen": {
          "label": "L3"
        },
        "external_id": {
          "label": "L3"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "gateway": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "instrument_subtype": {
          "label": "L3"
        },
        "instrument_type": {
          "label": "L3"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "parent_id": {
          "default_access": "R",
          "label": "L1"
        },
        "registered_at": {
          "label": "L3"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "source": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "label": "L3"
        },
        "u21_status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "instrument_tag_association": {
      "label": "L3",
      "cols": {
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_partition_pushdown": {
          "label": "L3"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "tag_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "instrument_txn_event_association": {
      "label": "L3",
      "cols": {
        "amount": {
          "label": "L3"
        },
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "data_setting_id": {
          "default_access": "R",
          "label": "L1"
        },
        "flow": {
          "default_access": "R",
          "label": "L1"
        },
        "instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "ip_address": {
      "label": "L3",
      "cols": {
        "action_event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "ip_address": {
          "label": "L3"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_partition_pushdown": {
          "label": "L3"
        },
        "primary_object_type": {
          "default_access": "R",
          "label": "L1"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "ip_asn": {
      "default_access": "R",
      "label": "L1",
      "cols": {
        "autonomous_system_number": {
          "label": "L1"
        },
        "autonomous_system_organization": {
          "label": "L1"
        },
        "end": {
          "label": "L1"
        },
        "start": {
          "label": "L1"
        }
      }
    },
    "ip_city": {
      "default_access": "R",
      "label": "L1",
      "cols": {
        "city": {
          "label": "L1"
        },
        "country": {
          "label": "L1"
        },
        "end": {
          "label": "L1"
        },
        "geoname_id": {
          "label": "L1"
        },
        "latitude": {
          "label": "L1"
        },
        "longitude": {
          "label": "L1"
        },
        "postal_code": {
          "label": "L1"
        },
        "start": {
          "label": "L1"
        },
        "subdivision_0_iso": {
          "label": "L1"
        }
      }
    },
    "parsed_historical_email_address": {
      "label": "L3",
      "cols": {
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "parsed_domain": {
          "label": "L3"
        },
        "parsed_email_hash": {
          "default_access": "R",
          "label": "L1"
        },
        "parsed_numeric_free_username": {
          "label": "L3"
        },
        "parsed_username": {
          "label": "L3"
        },
        "primary_object_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_type": {
          "default_access": "R",
          "label": "L1"
        },
        "raw": {
          "label": "L3"
        },
        "short_term_domain_service": {
          "default_access": "R",
          "label": "L1"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "phone_number": {
      "label": "L3",
      "cols": {
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "number": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_partition_pushdown": {
          "label": "L3"
        },
        "primary_object_type": {
          "default_access": "R",
          "label": "L1"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "physical_id": {
      "label": "L3",
      "cols": {
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "country": {
          "label": "L3"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "dob": {
          "label": "L3"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "expires_at": {
          "label": "L3"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "id_number": {
          "label": "L3"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_partition_pushdown": {
          "label": "L3"
        },
        "primary_object_type": {
          "default_access": "R",
          "label": "L1"
        },
        "registered_on": {
          "label": "L3"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "state": {
          "label": "L3"
        },
        "status": {
          "label": "L3"
        },
        "type": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "tag": {
      "label": "L3",
      "cols": {
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "name": {
          "label": "L3"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "type": {
          "label": "L3"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    },
    "txn_event": {
      "label": "L3",
      "cols": {
        "amount": {
          "label": "L3"
        },
        "amount_string": {
          "label": "L3"
        },
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "custom_data": {
          "label": "L3"
        },
        "device_id": {
          "default_access": "R",
          "label": "L1"
        },
        "event_time": {
          "label": "L3"
        },
        "exchange_rate": {
          "label": "L3"
        },
        "exchange_rate_string": {
          "label": "L3"
        },
        "external_fee": {
          "label": "L3"
        },
        "external_fee_string": {
          "label": "L3"
        },
        "external_id": {
          "label": "L3"
        },
        "external_txn_type": {
          "label": "L3"
        },
        "from_datafile_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "internal_fee": {
          "label": "L3"
        },
        "internal_fee_string": {
          "label": "L3"
        },
        "internal_txn_type": {
          "label": "L3"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "received_amount": {
          "label": "L3"
        },
        "received_amount_string": {
          "label": "L3"
        },
        "received_currency": {
          "label": "L3"
        },
        "receiver_entity_external_id": {
          "label": "L3"
        },
        "receiver_entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "receiver_instrument_external_id": {
          "label": "L3"
        },
        "receiver_instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "receiver_source": {
          "default_access": "R",
          "label": "L1"
        },
        "sender_entity_external_id": {
          "label": "L3"
        },
        "sender_entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "sender_instrument_external_id": {
          "label": "L3"
        },
        "sender_instrument_id": {
          "default_access": "R",
          "label": "L1"
        },
        "sender_source": {
          "default_access": "R",
          "label": "L1"
        },
        "sent_amount": {
          "label": "L3"
        },
        "sent_amount_string": {
          "label": "L3"
        },
        "sent_currency": {
          "label": "L3"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "status": {
          "default_access": "R",
          "label": "L1"
        },
        "transaction_hash": {
          "label": "L3"
        },
        "u21_status": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        },
        "usd_conversion_notes": {
          "label": "L3"
        }
      }
    },
    "txn_event_tag_association": {
      "label": "L3",
      "cols": {
        "bronze_table_external_id": {
          "label": "L3"
        },
        "bronze_table_object": {
          "default_access": "R",
          "label": "L1"
        },
        "bronze_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_offset": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_partition": {
          "default_access": "R",
          "label": "L1"
        },
        "kafka_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "primary_object_partition_pushdown": {
          "label": "L3"
        },
        "silver_timestamp": {
          "default_access": "R",
          "label": "L1"
        },
        "tag_id": {
          "default_access": "R",
          "label": "L1"
        },
        "txn_event_id": {
          "default_access": "R",
          "label": "L1"
        },
        "unit_id": {
          "default_access": "R",
          "label": "L1"
        },
        "updated_at": {
          "default_access": "R",
          "label": "L1"
        }
      }
    }
  },
  "gold_data_signals": {
    "entity_historic_risk_score": {
      "label": "L2",
      "cols": {
        "created_at": {
          "default_access": "R",
          "label": "L1"
        },
        "entity_id": {
          "default_access": "R",
          "label": "L1"
        },
        "id": {
          "default_access": "R",
          "label": "L1"
        },
        "org_id": {
          "default_access": "R",
          "label": "L1"
        },
        "risk_model_id": {
          "default_access": "R",
          "label": "L1"
        },
        "score": {
          "label": "L2"
        },
        "snapshot": {
          "label": "L2"
        },
        "source": {
          "default_access": "R",
          "label": "L1"
        }
      }
    }
  }
}`);
