import { Modal } from "bootstrap";
import { useEffect, useReducer, useRef, useState, useMemo } from "react";

import {
  ACCESS_DURATION,
  ACCESS_TYPE,
  DATA_ACCESS,
  DELTA_LAKE_ACCESS_TYPE,
  DELTA_LAKE_ENV,
  PGPARK_ACCESS_TYPE,
  PGPARK_COLS_WITH_FORBIDDEN_WRITE_ACCESS,
  IMPERSONATION_ENV,
  PGPARK_ENV,
} from "../../constants";
import ConfirmationModal from "./ConfirmationModal";
import Loading from "../Loading";
import {
  fetchApprovers,
  fetchLabeledDatabaseSchemas,
  submitRequests,
} from "../../sideEffects";
import {
  formatAccessType,
  formatDuration,
  formatEnvironment,
  formatUser,
} from "../../utils";

import DatabaseAccessInput from "./DatabaseAccessInput";
import databaseAccessReducer, {
  ACCESS_GROUP,
  DATABASE_ACCESS_ACTION,
} from "./databaseAccessReducer";

const shouldConfirmSubmission = (reason) => {
  // Confirm submission if the reason does not contain any links to documentation
  const pagerdutyLink = "unit21[.]pagerduty[.]com/";
  const shortcutLink = "app[.]shortcut[.]com/unit21/";
  const slackLink = "unit21chat[.]slack[.]com/archives/";
  const zendeskLink = "unit21[.]zendesk[.]com/agent/tickets/";
  const linkRegExp = new RegExp(
    `${pagerdutyLink}|${shortcutLink}|${slackLink}|${zendeskLink}`
  );
  return reason.search(linkRegExp) === -1;
};

const serializeFormData = (formData) => ({
  access_type: formData.get("access-type"),
  reason: formData.get("reason"),
  duration: parseInt(formData.get("duration"), 10),
  requested_approver: { id: formData.get("approver") },
});

export const serializeDatabaseAccessTables = ({
  accessType,
  schema,
  noAccessTables,
  readAccessTables,
  writeAccessTables,
}) => {
  // List of table names for which full read access is requested
  const readAccessTableNames = readAccessTables.map(({ name }) => ({ name }));

  // List of columns for which read or read/write access is requested
  const partialAccessTables = [];
  for (const table of noAccessTables) {
    if (table.default_access === DATA_ACCESS.READ) {
      // Full read access to the table is granted by default
      readAccessTableNames.push({ name: table.name });
      continue;
    }

    // Request read access to the columns for which read access is granted by default, if any
    const defaultReadAccessCols = [];
    for (const colName in table.cols) {
      if (table.cols[colName].default_access === DATA_ACCESS.READ) {
        defaultReadAccessCols.push({ name: colName, access: DATA_ACCESS.READ });
      }
    }
    if (Object.values(defaultReadAccessCols).length) {
      partialAccessTables.push({
        name: table.name,
        cols: defaultReadAccessCols,
      });
    }
  }

  // List of table names for which full read and write access is requested
  const writeAccessTableNames = [];
  for (const table of writeAccessTables) {
    if (
      !(
        accessType === ACCESS_TYPE.PGPARK &&
        PGPARK_COLS_WITH_FORBIDDEN_WRITE_ACCESS[table.name]
      )
    ) {
      // If there are no columns with forbidden write access in the table, request full
      // read/write access to the table
      writeAccessTableNames.push({ name: table.name });
    } else {
      const colAccess = [];

      for (const colName in table.cols) {
        if (
          accessType === ACCESS_TYPE.PGPARK &&
          PGPARK_COLS_WITH_FORBIDDEN_WRITE_ACCESS[table.name].includes(colName)
        ) {
          // If write access to this column is forbidden, request read access to it
          colAccess.push({ name: colName, access: DATA_ACCESS.READ });
        } else {
          // If write access to *other* column(s) in this table is forbidden, request read/write
          // access to this column
          colAccess.push({ name: colName, access: DATA_ACCESS.READ_WRITE });
        }
      }
      partialAccessTables.push({ name: table.name, cols: colAccess });
    }
  }

  return {
    schema,
    partial_access_tables: partialAccessTables,
    read_tables: readAccessTableNames,
    read_write_tables: writeAccessTableNames,
  };
};

function RequestForm({ createAlert }) {
  const [accessType, setAccessType] = useState(ACCESS_TYPE.IMPERSONATION);
  const [submissionIsPending, setSubmissionIsPending] = useState(false);
  const [approvers, setApprovers] = useState([]);

  const [databaseAccess, dispatch] = useReducer(databaseAccessReducer, null);

  const FILTERED_ACCESS_DURATION = useMemo(() => {
    if (accessType === ACCESS_TYPE.IMPERSONATION) {
      return ACCESS_DURATION;
    }

    // Means DB access, need to filter out anything longer than a week
    return ACCESS_DURATION.filter(e => e<= 60*60*24*7);
  }, [accessType]);

  const confirmationModalRef = useRef(null);
  const formRef = useRef(null);
  const reasonInputRef = useRef(null);
  const submitButtonRef = useRef(null);

  const environments = {
    [ACCESS_TYPE.IMPERSONATION]: IMPERSONATION_ENV,
    [ACCESS_TYPE.PGPARK]: PGPARK_ENV,
    [ACCESS_TYPE.DELTA_LAKE]: DELTA_LAKE_ENV,
  }[accessType];

  const resetDatabaseAccessToDefault = () => {
    dispatch({ type: DATABASE_ACCESS_ACTION.RESET_TO_DEFAULT });
  };

  const updateDatabaseAccess = ({ tableName, from, to }) => {
    dispatch({ type: DATABASE_ACCESS_ACTION.MOVE_TABLE, tableName, from, to });
  };

  const bulkUpdateDatabaseAccess = ({ from, to, withSearchTerm }) => {
    dispatch({
      type: DATABASE_ACCESS_ACTION.BULK_MOVE_TABLES,
      from,
      to,
      withSearchTerm,
    });
  };

  const setSchema = (schema) => {
    dispatch({ type: DATABASE_ACCESS_ACTION.SET_SCHEMA, schema });
  };

  const handleSubmit = (submitEvent) => {
    submitEvent.preventDefault();
    if (submissionIsPending) {
      return;
    }

    if (shouldConfirmSubmission(reasonInputRef.current.value)) {
      const modal = Modal.getOrCreateInstance(confirmationModalRef.current, {
        backdrop: "static",
        focus: false,
      });
      modal.show(submitButtonRef.current);
    } else {
      submitRequest();
    }
  };

  async function submitRequest() {
    setSubmissionIsPending(true);

    // If there is an open confirmation modal, close it.
    const modal = Modal.getInstance(confirmationModalRef.current);
    modal?.hide();

    const formData = new FormData(formRef.current);
    const requestDetails = serializeFormData(formData);

    if ([ACCESS_TYPE.DELTA_LAKE, ACCESS_TYPE.PGPARK].includes(accessType)) {
      requestDetails.database_access = {
        access_type: formData.get("db-access-type"),
      };

      if (
        [DELTA_LAKE_ACCESS_TYPE.TABLE, PGPARK_ACCESS_TYPE.TABLE].includes(
          requestDetails.database_access.access_type
        )
      ) {
        Object.assign(
          requestDetails.database_access,
          serializeDatabaseAccessTables({
            accessType,
            schema: databaseAccess.schema,
            noAccessTables: databaseAccess[ACCESS_GROUP.NONE],
            readAccessTables: databaseAccess[ACCESS_GROUP.READ],
            writeAccessTables: databaseAccess[ACCESS_GROUP.READ_WRITE],
          })
        );
      }
    }

    const requestDetailsArray = [];
    for (const environment of formData.getAll("environment")) {
      requestDetailsArray.push({
        ...requestDetails,
        environment,
      });
    }

    try {
      await submitRequests(requestDetailsArray);
      createAlert({
        content: "Request submitted successfully!",
        theme: "success",
        duration: 10000,
      });
      resetDatabaseAccessToDefault();
      formRef.current.reset();
      // Force the table search input reset to take effect
      document.getElementById("table-search-button")?.click();
    } catch (e) {
      createAlert({
        content: e.message,
        theme: "danger",
        duration: 10000,
      });
    } finally {
      setSubmissionIsPending(false);
    }
  }

  // Load approvers for access type whenever access type changes
  useEffect(() => {
    setApprovers([]);

    fetchApprovers(accessType)
      .then((fetchedApprovers) => {
        setApprovers(fetchedApprovers);
      })
      .catch((e) => {
        createAlert({
          content: e.message,
          theme: "danger",
          duration: 10000,
        });
      });
  }, [accessType, createAlert]);

  // Load data labels whenever access type is updated to PGPARK or DELTA_LAKE
  useEffect(() => {
    if ([ACCESS_TYPE.DELTA_LAKE, ACCESS_TYPE.PGPARK].includes(accessType)) {
      fetchLabeledDatabaseSchemas(accessType)
        .then((labeledDatabaseSchemas) => {
          dispatch({
            type: DATABASE_ACCESS_ACTION.INIT,
            labeledDatabaseSchemas,
          });
        })
        .catch((e) => {
          createAlert({
            content: e.message,
            theme: "danger",
            duration: 10000,
          });
        });
    }
  }, [accessType, createAlert]);

  if (!approvers?.length) {
    return <Loading />;
  }

  if (
    [ACCESS_TYPE.DELTA_LAKE, ACCESS_TYPE.PGPARK].includes(accessType) &&
    databaseAccess === null
  ) {
    return <Loading />;
  }

  const submitButton = (
    <button
      ref={submitButtonRef}
      type="submit"
      className="btn btn-primary w-100"
    >
      Submit Request
    </button>
  );

  const pendingSubmitButton = (
    <button
      type="submit"
      className="btn btn-primary w-100 disabled"
      aria-disabled="true"
      style={{ cursor: "not-allowed" }}
    >
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      &nbsp;Submitting...
    </button>
  );

  return (
    <>
      <ConfirmationModal
        modalRef={confirmationModalRef}
        submitRequest={submitRequest}
      />
      <div
        data-testid="request-form"
        className="bg-light rounded-2 p-3"
        style={{
          minWidth: "40%",
          width: "fit-content",
        }}
      >
        <h1 className="mb-4 display-6 text-center">
          Request Production Access
        </h1>
        <form name="request" ref={formRef} onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="access-type" className="form-label">
              Access type
            </label>
            <select
              id="access-type"
              name="access-type"
              className="form-select"
              required
              value={accessType}
              onChange={({ target: { value } }) => {
                setAccessType(value);
              }}
              autoFocus
            >
              {Object.values(ACCESS_TYPE).map((accessType) => (
                <option key={accessType} value={accessType}>
                  {formatAccessType(accessType)}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="environment" className="form-label">
              Environment
            </label>
            <select
              id="environment"
              name="environment"
              className="form-select"
              style={{ height: "25vh" }}
              multiple
              required
            >
              {environments.map((environment) => (
                <option
                  key={environment}
                  value={environment}
                  data-testid={`environment-${environment}`}
                >
                  {formatEnvironment(environment)}
                </option>
              ))}
            </select>
          </div>
          {[ACCESS_TYPE.DELTA_LAKE, ACCESS_TYPE.PGPARK].includes(
            accessType
          ) && (
            <DatabaseAccessInput
              accessType={accessType}
              schema={databaseAccess.schema}
              noAccessTables={databaseAccess[ACCESS_GROUP.NONE]}
              readAccessTables={databaseAccess[ACCESS_GROUP.READ]}
              readWriteAccessTables={databaseAccess[ACCESS_GROUP.READ_WRITE]}
              schemaNames={databaseAccess.schemaNames}
              updateAccess={updateDatabaseAccess}
              bulkUpdateAccess={bulkUpdateDatabaseAccess}
              resetAccessToDefault={resetDatabaseAccessToDefault}
              setSchema={setSchema}
            />
          )}
          <div className="mb-4">
            <label htmlFor="reason" className="form-label">
              Reason
            </label>
            <textarea
              ref={reasonInputRef}
              id="reason"
              name="reason"
              className="form-control"
              rows="4"
              maxLength="2590"
              aria-describedby="reason-help"
              required
            ></textarea>
            <div id="reason-help" className="form-text">
              Include links to any relevant documentation (support tickets,
              etc.)
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="duration" className="form-label">
              Access duration
            </label>
            <select
              id="duration"
              name="duration"
              className="form-select"
              required
            >
              <option value="">--Please select an option--</option>
              {FILTERED_ACCESS_DURATION.map((duration) => (
                <option key={duration} value={duration}>
                  {formatDuration(duration)}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="approver" className="form-label">
              Approver
            </label>
            <select
              id="approver"
              name="approver"
              className="form-select"
              required
            >
              <option value="">--Please select an option--</option>
              {approvers.map((approver) => (
                <option key={approver.id} value={approver.id}>
                  {formatUser(approver)}
                </option>
              ))}
            </select>
          </div>
          {submissionIsPending ? pendingSubmitButton : submitButton}
        </form>
      </div>
    </>
  );
}

export default RequestForm;
