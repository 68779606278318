import { DATA_ACCESS } from "../../constants";

import { sortByName } from "../../utils";

export const DATABASE_ACCESS_ACTION = {
  BULK_MOVE_TABLES: "BULK_MOVE_TABLES",
  INIT: "INIT",
  MOVE_TABLE: "MOVE_TABLE",
  RESET_TO_DEFAULT: "RESET_TO_DEFAULT",
  SET_SCHEMA: "SET_SCHEMA",
};

export const ACCESS_GROUP = {
  NONE: "NONE",
  READ: "READ",
  READ_WRITE: "READ_WRITE",
};

// Extremely simple search function over table names: includes a table
// if searchTerm is unset or if the table name contains searchTerm.
export const applySearch = ({ searchTerm, tables }) => {
  return tables.filter(({ name }) => !searchTerm || name.includes(searchTerm));
};

function databaseAccessReducer(databaseAccess, action) {
  switch (action.type) {
    case DATABASE_ACCESS_ACTION.INIT: {
      const { labeledDatabaseSchemas } = action;

      const schemaNames = Object.keys(labeledDatabaseSchemas);
      // Initially, select the first schema
      const schema = schemaNames[0];
      const labeledDatabaseSchema = labeledDatabaseSchemas[schema];

      // Set the default database access initially
      const defaultNone = [];
      const defaultRead = [];
      const defaultReadWrite = [];

      for (const tableName in labeledDatabaseSchema) {
        switch (labeledDatabaseSchema[tableName].default_access) {
          case DATA_ACCESS.READ:
            defaultRead.push({
              name: tableName,
              ...labeledDatabaseSchema[tableName],
            });
            break;
          case DATA_ACCESS.READ_WRITE:
            defaultReadWrite.push({
              name: tableName,
              ...labeledDatabaseSchema[tableName],
            });
            break;
          default:
            defaultNone.push({
              name: tableName,
              ...labeledDatabaseSchema[tableName],
            });
        }
      }

      defaultNone.sort(sortByName);
      defaultRead.sort(sortByName);
      defaultReadWrite.sort(sortByName);

      return {
        labeledDatabaseSchemas,
        schemaNames,
        schema,
        [ACCESS_GROUP.NONE]: defaultNone,
        [ACCESS_GROUP.READ]: defaultRead,
        [ACCESS_GROUP.READ_WRITE]: defaultReadWrite,
      };
    }

    case DATABASE_ACCESS_ACTION.RESET_TO_DEFAULT: {
      if (!databaseAccess) return databaseAccess;

      const defaultNone = [];
      const defaultRead = [];
      const defaultReadWrite = [];

      for (const table of [
        ...databaseAccess[ACCESS_GROUP.NONE],
        ...databaseAccess[ACCESS_GROUP.READ],
        ...databaseAccess[ACCESS_GROUP.READ_WRITE],
      ]) {
        switch (table.default_access) {
          case DATA_ACCESS.READ:
            defaultRead.push(table);
            break;
          case DATA_ACCESS.READ_WRITE:
            defaultReadWrite.push(table);
            break;
          default:
            defaultNone.push(table);
        }
      }

      defaultNone.sort(sortByName);
      defaultRead.sort(sortByName);
      defaultReadWrite.sort(sortByName);

      return {
        ...databaseAccess,
        [ACCESS_GROUP.NONE]: defaultNone,
        [ACCESS_GROUP.READ]: defaultRead,
        [ACCESS_GROUP.READ_WRITE]: defaultReadWrite,
      };
    }

    case DATABASE_ACCESS_ACTION.MOVE_TABLE: {
      const { from, to, tableName } = action;

      if (!databaseAccess) return databaseAccess;

      const fromAccessGroup = databaseAccess[from];
      const toAccessGroup = databaseAccess[to];

      // Remove table from fromAccessGroup
      const tableIndex = fromAccessGroup.findIndex(
        ({ name }) => name === tableName
      );
      if (tableIndex === -1) {
        console.error(`Table ${tableName} not found in access group ${from}`);
        return databaseAccess;
      }
      const updatedFromAccessGroup = [
        ...fromAccessGroup.slice(0, tableIndex),
        ...fromAccessGroup.slice(tableIndex + 1),
      ];

      // Add table to toAccessGroup
      const updatedToAccessGroup = [
        ...toAccessGroup,
        fromAccessGroup[tableIndex],
      ];
      updatedToAccessGroup.sort(sortByName);

      return {
        ...databaseAccess,
        [from]: updatedFromAccessGroup,
        [to]: updatedToAccessGroup,
      };
    }

    case DATABASE_ACCESS_ACTION.BULK_MOVE_TABLES: {
      const { from, to, withSearchTerm } = action;

      if (!databaseAccess) return databaseAccess;

      const fromAccessGroup = databaseAccess[from];
      const toAccessGroup = databaseAccess[to];

      const fromAccessGroupWithSearch = applySearch({
        searchTerm: withSearchTerm,
        tables: fromAccessGroup,
      });

      // Add the tables (with search applied) from fromAccessGroup to toAccessGroup
      const updatedToAccessGroup = [
        ...fromAccessGroupWithSearch,
        ...toAccessGroup,
      ];
      updatedToAccessGroup.sort(sortByName);

      // Remove the tables (with search applied) from fromAccessGroup
      const updatedFromAccessGroup = [...fromAccessGroup];
      for (const tableToRemove of fromAccessGroupWithSearch) {
        const i = updatedFromAccessGroup.findIndex(
          (table) => table.name === tableToRemove.name
        );
        if (i !== -1) {
          updatedFromAccessGroup.splice(i, 1);
          continue;
        }
      }

      return {
        ...databaseAccess,
        [from]: updatedFromAccessGroup,
        [to]: updatedToAccessGroup,
      };
    }

    case DATABASE_ACCESS_ACTION.SET_SCHEMA: {
      const { schema } = action;

      if (!databaseAccess) return databaseAccess;

      // If the schema is not being changed, do nothing
      if (schema === databaseAccess.schema) return databaseAccess;

      const labeledDatabaseSchema =
        databaseAccess.labeledDatabaseSchemas[schema];

      // Set the default database access initially
      const defaultNone = [];
      const defaultRead = [];
      const defaultReadWrite = [];

      for (const tableName in labeledDatabaseSchema) {
        switch (labeledDatabaseSchema[tableName].default_access) {
          case DATA_ACCESS.READ:
            defaultRead.push({
              name: tableName,
              ...labeledDatabaseSchema[tableName],
            });
            break;
          case DATA_ACCESS.READ_WRITE:
            defaultReadWrite.push({
              name: tableName,
              ...labeledDatabaseSchema[tableName],
            });
            break;
          default:
            defaultNone.push({
              name: tableName,
              ...labeledDatabaseSchema[tableName],
            });
        }
      }

      defaultNone.sort(sortByName);
      defaultRead.sort(sortByName);
      defaultReadWrite.sort(sortByName);

      return {
        ...databaseAccess,
        schema,
        [ACCESS_GROUP.NONE]: defaultNone,
        [ACCESS_GROUP.READ]: defaultRead,
        [ACCESS_GROUP.READ_WRITE]: defaultReadWrite,
      };
    }

    default:
      throw new Error("Unknown action type", action.type);
  }
}

export default databaseAccessReducer;
