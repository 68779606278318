import { DateTime } from "luxon";

import {
  ACCESS_TYPE,
  REQUEST_ACTIONS,
  SESSION_STORAGE_AUTH_NONCE_KEY,
  SESSION_STORAGE_IDENTITY_KEY,
} from "./constants";
import { getUserToken } from "./utils";

const API_URL = process.env.REACT_APP_API_URL;

const getErrorMessageFromResponse = async (response) => {
  try {
    const { error } = await response.json();
    return error;
  } catch (e) {
    return null;
  }
};

/* Check whether the user is authenticated.
 * If they are, return the authentication TTL; if not, throw an error.
 */
export const checkAuthed = async () => {
  const token = getUserToken();
  const response = await fetch(`${API_URL}/lookupToken`, {
    method: "GET",
    mode: "cors",
    headers: { Accept: "application/json", "X-Vault-Token": token },
    cache: "no-store",
  });

  if (!response.ok) {
    console.error(
      `Authentication check failed: ${response.status} (${response.statusText})`
    );
    const errorMessage = await getErrorMessageFromResponse(response);
    if (errorMessage) {
      console.error(errorMessage);
    }
    throw new Error("Authentication check failed");
  }

  const { ttl } = await response.json();
  return ttl;
};

/* Request an authorization URL to begin the OIDC login flow.
 */
export const login = async () => {
  const response = await fetch(`${API_URL}/login`, {
    method: "POST",
    mode: "cors",
    headers: { Accept: "text/plain" },
  });

  if (!response.ok) {
    console.error(`Login failed: ${response.status} (${response.statusText})`);
    const errorMessage = await getErrorMessageFromResponse(response);
    if (errorMessage) {
      console.error(errorMessage);
    }
    throw new Error("Login failed");
  }

  const authUrl = await response.text();
  const authParams = new URL(authUrl).searchParams;
  const nonce = authParams.get("nonce");
  if (nonce === null) {
    throw new Error("Malformed authorization URL");
  }
  sessionStorage.setItem(SESSION_STORAGE_AUTH_NONCE_KEY, nonce);
  window.location.assign(authUrl);
};

/* Request an authorization URL to begin the OIDC login flow.
 */
export const loginCallback = async ({ code, state }) => {
  const nonce = sessionStorage.getItem(SESSION_STORAGE_AUTH_NONCE_KEY);
  if (nonce === null) {
    throw new Error("Missing nonce");
  }
  const response = await fetch(`${API_URL}/login/callback`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({ nonce, code, state }),
  });

  if (!response.ok) {
    console.error(`Login failed: ${response.status} (${response.statusText})`);
    const errorMessage = await getErrorMessageFromResponse(response);
    if (errorMessage) {
      console.error(errorMessage);
    }
    throw new Error("Login failed");
  }

  const { token, entityId, leaseDuration, policies } = await response.json();
  const identity = JSON.stringify({
    token,
    entityId,
    policies,
  });
  sessionStorage.setItem(SESSION_STORAGE_IDENTITY_KEY, identity);
  sessionStorage.removeItem(SESSION_STORAGE_AUTH_NONCE_KEY);
  return { authTTL: leaseDuration, userId: entityId, policies };
};

/* Get the list of request approvers.
 */
export const fetchApprovers = async (accessType) => {
  const token = getUserToken();
  const response = await fetch(
    `${API_URL}/approvers?access-request-type=${accessType}`,
    {
      method: "GET",
      mode: "cors",
      headers: { Accept: "application/json", "X-Vault-Token": token },
    }
  );

  if (!response.ok) {
    console.error(
      `Fetching approvers failed: ${response.status} (${response.statusText})`
    );
    const errorMessage = await getErrorMessageFromResponse(response);
    if (errorMessage) {
      console.error(errorMessage);
      throw new Error(`Failed to fetch approvers! ${errorMessage}`);
    }
    throw new Error("Failed to fetch approvers!");
  }

  const approvers = await response.json();
  return approvers;
};

/* Submit access requests.
 */
export const submitRequests = async (requestDetailsArray) => {
  const token = getUserToken();
  const response = await fetch(`${API_URL}/requests`, {
    method: "POST",
    mode: "cors",
    headers: { "Content-Type": "application/json", "X-Vault-Token": token },
    body: JSON.stringify(requestDetailsArray),
  });

  if (!response.ok) {
    console.error(
      `Submitting request failed: ${response.status} (${response.statusText})`
    );
    const errorMessage = await getErrorMessageFromResponse(response);
    if (errorMessage) {
      console.error(errorMessage);
      throw new Error(`Failed to submit request! ${errorMessage}`);
    }
    throw new Error("Failed to submit request!");
  }

  return true;
};

/* Get the list of past requests.
 */
export const fetchRequestHistory = async () => {
  const token = getUserToken();
  const response = await fetch(`${API_URL}/requests`, {
    method: "GET",
    mode: "cors",
    headers: { Accept: "application/json", "X-Vault-Token": token },
  });

  if (!response.ok) {
    console.error(
      `Fetching request history failed: ${response.status} (${response.statusText})`
    );
    const errorMessage = await getErrorMessageFromResponse(response);
    if (errorMessage) {
      console.error(errorMessage);
      throw new Error(`Failed to fetch requests! ${errorMessage}`);
    }
    throw new Error("Failed to fetch requests!");
  }

  const requests = await response.json();
  if (requests === null) {
    return [];
  }
  return requests;
};

/* Get the list of requests waiting for approval.
 */
export const fetchRequestsPendingApproval = async () => {
  const token = getUserToken();
  const response = await fetch(`${API_URL}/requests/approvals`, {
    method: "GET",
    mode: "cors",
    headers: { Accept: "application/json", "X-Vault-Token": token },
  });

  if (!response.ok) {
    console.error(
      `Fetching requests pending approval failed: ${response.status} (${response.statusText})`
    );
    const errorMessage = await getErrorMessageFromResponse(response);
    if (errorMessage) {
      console.error(errorMessage);
      throw new Error(`Failed to fetch requests! ${errorMessage}`);
    }
    throw new Error("Failed to fetch requests!");
  }

  const requests = await response.json();
  if (requests === null) {
    return [];
  }
  return requests;
};

/* Get the list of past approvals.
 */
export const fetchApprovalHistory = async () => {
  const token = getUserToken();
  const response = await fetch(`${API_URL}/requests/approvals/history`, {
    method: "GET",
    mode: "cors",
    headers: { Accept: "application/json", "X-Vault-Token": token },
  });

  if (!response.ok) {
    console.error(
      `Fetching approval history failed: ${response.status} (${response.statusText})`
    );
    const errorMessage = await getErrorMessageFromResponse(response);
    if (errorMessage) {
      console.error(errorMessage);
      throw new Error(`Failed to fetch requests! ${errorMessage}`);
    }
    throw new Error("Failed to fetch requests!");
  }

  const approvals = await response.json();
  if (approvals === null) {
    return [];
  }
  return approvals;
};

/* Update the status (approve, reject, or cancel) of a request.
 */
export const updateRequestStatus = async ({
  requestId,
  action,
  rejectionReason = "",
}) => {
  const token = getUserToken();
  const response = await fetch(`${API_URL}/requests/${requestId}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Vault-Token": token,
    },
    body: JSON.stringify({
      action,
      rejection_reason: rejectionReason,
    }),
  });

  if (!response.ok) {
    console.error(
      `Updating request status failed: ${response.status} (${response.statusText})`
    );
    const updateVerb = {
      [REQUEST_ACTIONS.APPROVE]: "approve",
      [REQUEST_ACTIONS.REJECT]: "reject",
      [REQUEST_ACTIONS.CANCEL]: "cancel",
    }[action];

    const errorMessage = await getErrorMessageFromResponse(response);
    if (errorMessage) {
      console.error(errorMessage);
      throw new Error(`Failed to ${updateVerb} request! ${errorMessage}`);
    }
    throw new Error(`Failed to ${updateVerb} request!`);
  }

  const updatedRequest = await response.json();
  return updatedRequest;
};

/* Get the audit list of requests.
 */
export const fetchRequestAudit = async ({ offset = 0, filters = "" }) => {
  const token = getUserToken();
  const response = await fetch(
    `${API_URL}/requests/audit?offset=${offset}&${filters}`,
    {
      method: "GET",
      mode: "cors",
      headers: { Accept: "application/json", "X-Vault-Token": token },
    }
  );

  if (!response.ok) {
    console.error(
      `Fetching request audit failed: ${response.status} (${response.statusText})`
    );
    const errorMessage = await getErrorMessageFromResponse(response);
    if (errorMessage) {
      console.error(errorMessage);
      throw new Error(`Failed to fetch requests! ${errorMessage}`);
    }
    throw new Error("Failed to fetch requests!");
  }

  const { requests, total } = await response.json();
  if (requests === null) {
    return { requests: [], total };
  }
  return { requests, total };
};

/* Download the audit list of requests in CSV format.
 */
export const exportRequestAudit = async ({ filters = "" }) => {
  const token = getUserToken();
  const response = await fetch(`${API_URL}/requests/audit/export?${filters}`, {
    method: "GET",
    mode: "cors",
    headers: { Accept: "text/csv", "X-Vault-Token": token },
  });

  if (!response.ok) {
    console.error(
      `Fetching request audit failed: ${response.status} (${response.statusText})`
    );
    const errorMessage = await getErrorMessageFromResponse(response);
    if (errorMessage) {
      console.error(errorMessage);
    }
    throw new Error("Failed to fetch requests!");
  }

  // This is a hacky way of downloading the response to a file.
  const requestsBlob = await response.blob();
  const requestsUrl = window.URL.createObjectURL(requestsBlob);
  const tempA = document.createElement("a");
  tempA.href = requestsUrl;
  const timestamp = DateTime.now().toFormat("yyyyMMddHHmmss");
  const formattedFilters = filters.replaceAll("&", "_");
  tempA.download = `request_audit_${timestamp}_${formattedFilters}.csv`;
  document.body.appendChild(tempA);
  tempA.click();
  tempA.remove();
};

/* Get the audit list of accesses.
 */
export const fetchAccessEndAudit = async ({ offset = 0, filters }) => {
  const token = getUserToken();
  const response = await fetch(
    `${API_URL}/requests/audit/accessEnd?offset=${offset}&${filters}`,
    {
      method: "GET",
      mode: "cors",
      headers: { Accept: "application/json", "X-Vault-Token": token },
    }
  );

  if (!response.ok) {
    console.error(
      `Fetching access end audit failed: ${response.status} (${response.statusText})`
    );
    const errorMessage = await getErrorMessageFromResponse(response);
    if (errorMessage) {
      console.error(errorMessage);
      throw new Error(`Failed to fetch access end audit! ${errorMessage}`);
    }
    throw new Error("Failed to fetch access end audit!");
  }

  let {
    requests,
    total,
    ongoing_access: ongoingAccess,
  } = await response.json();
  if (requests === null) {
    requests = [];
  }
  if (ongoingAccess === null) {
    ongoingAccess = [];
  }
  return { requests, total, ongoingAccess };
};

/* Get the labeled database schema(s).
 */
export const fetchLabeledDatabaseSchemas = async (accessType) => {
  const database = accessType === ACCESS_TYPE.PGPARK ? "pgpark" : "deltalake";

  const token = getUserToken();
  const response = await fetch(`${API_URL}/datalabels/${database}`, {
    method: "GET",
    mode: "cors",
    headers: { Accept: "application/json", "X-Vault-Token": token },
  });

  if (!response.ok) {
    console.error(
      `Fetching labeled ${database} schemas failed: ${response.status} (${response.statusText})`
    );
    const errorMessage = await getErrorMessageFromResponse(response);
    if (errorMessage) {
      console.error(errorMessage);
      throw new Error(
        `Failed to fetch labeled ${database} schemas! ${errorMessage}`
      );
    }
    throw new Error(`Failed to fetch labeled ${database} schemas!`);
  }

  const labels = await response.json();
  return labels;
};

/* Get the database credentials for a request with active database access.
 */
export const fetchDatabaseCredentials = async (requestId) => {
  const token = getUserToken();
  const response = await fetch(`${API_URL}/requests/${requestId}/credentials`, {
    method: "GET",
    mode: "cors",
    headers: { Accept: "application/json", "X-Vault-Token": token },
  });

  let credentials = null;
  if (response.ok) {
    credentials = await response.json();
  }
  return credentials;
};
