import { DateTime } from "luxon";
import { Tooltip } from "bootstrap";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export const localTimestampToUTC = (localTimestamp) => {
  if (!localTimestamp) return "";
  const localDateTime = DateTime.fromISO(localTimestamp);
  return localDateTime.setZone("utc").toFormat("yyyy-MM-dd'T'T");
};

export const utcTimestampToLocal = (utcTimestamp) => {
  if (!utcTimestamp) return "";
  const utcDateTime = DateTime.fromISO(utcTimestamp, { zone: "utc" });
  return utcDateTime.toLocal().toFormat("yyyy-MM-dd'T'T");
};

function ButtonWithTooltip({ tooltipText, tooltipPlacement, ...props }) {
  const ref = useRef(null);

  useEffect(() => {
    Tooltip.getOrCreateInstance(ref.current);
  }, []);

  const handleDismissal = (e) => {
    if (e.key === "Esc" || e.key === "Escape") {
      e.preventDefault();
      const tooltip = Tooltip.getInstance(ref.current);
      tooltip.hide();
    }
  };

  return (
    <button
      {...props}
      data-bs-toggle="tooltip"
      data-bs-placement={tooltipPlacement}
      data-bs-title={tooltipText}
      onKeyDown={handleDismissal}
      ref={ref}
    />
  );
}

// `defaultFilters` should be a string in the URL search parameter format, containing *only*
// key-value pairs corresponding to the filter inputs
function Sidebar({ defaultFilters = "", fetchIsPending, requestCount }) {
  const [formState, setFormState] = useState({
    dirty: false,
    invalidFields: [],
  });
  const navigate = useNavigate();
  const formRef = useRef(null);

  const parsedDefaultFilters = new URLSearchParams(defaultFilters);
  const defaultEndedAtStart = utcTimestampToLocal(
    parsedDefaultFilters.get("ended-at-start")
  );
  const defaultEndedAtEnd = utcTimestampToLocal(
    parsedDefaultFilters.get("ended-at-end")
  );

  const validateForm = () => {
    const invalidFields = [];
    const formData = new FormData(formRef.current);

    // Verify that the start time is before the end time
    const start = DateTime.fromISO(formData.get("ended-at-start"));
    const end = DateTime.fromISO(formData.get("ended-at-end"));
    if (start > end) {
      invalidFields.push("ended-at-start", "ended-at-end");
    }

    return invalidFields;
  };

  // Validate the form and check if the current filters are different from the default filters
  const maybeUpdateFormState = () => {
    const invalidFields = validateForm();
    const formData = new FormData(formRef.current);
    const dirty =
      formData.get("ended-at-start") !== defaultEndedAtStart ||
      formData.get("ended-at-end") !== defaultEndedAtEnd;
    setFormState({ dirty, invalidFields });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formState.dirty || fetchIsPending) {
      return;
    }

    const invalidFields = validateForm();
    if (invalidFields.length !== 0) {
      setFormState({ dirty: true, invalidFields });
      const firstInvalidElement = formRef.current.querySelector(
        `[name="${invalidFields[0]}"`
      );
      firstInvalidElement.focus();
      return;
    }
    setFormState({ dirty: false, invalidFields: [] });

    const formData = new FormData(formRef.current);
    const filters = new URLSearchParams(formData);
    // Convert the timestamps to UTC
    filters.set(
      "ended-at-start",
      localTimestampToUTC(filters.get("ended-at-start"))
    );
    filters.set(
      "ended-at-end",
      localTimestampToUTC(filters.get("ended-at-end"))
    );

    navigate(`../access_end_audit?${filters.toString()}`);
  };

  let applyButton;
  if (fetchIsPending) {
    applyButton = (
      <button
        type="submit"
        className="btn btn-primary ms-1 disabled"
        form="search-filter-form"
        aria-disabled="true"
        style={{ cursor: "not-allowed", pointerEvents: "auto" }}
      >
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        &nbsp;Loading...
      </button>
    );
  } else if (!formState.dirty) {
    applyButton = (
      <ButtonWithTooltip
        type="submit"
        className="btn btn-primary ms-1 disabled"
        form="search-filter-form"
        aria-disabled="true"
        style={{ cursor: "not-allowed", pointerEvents: "auto" }}
        tooltipPlacement="bottom"
        tooltipText="No changes to apply"
      >
        Apply
      </ButtonWithTooltip>
    );
  } else {
    applyButton = (
      <button
        type="submit"
        className="btn btn-primary ms-1"
        form="search-filter-form"
      >
        Apply
      </button>
    );
  }

  return (
    <div data-testid="access-end-audit-sidebar">
      <div className="card text-bg-light mx-auto h-100">
        <h5
          className="card-header d-flex justify-content-between"
          id="sidebar-header"
        >
          <span>Access End Audit</span>
          {requestCount !== undefined && (
            <span role="status" className="badge bg-secondary">
              {requestCount} result{requestCount === 1 ? "" : "s"}
            </span>
          )}
        </h5>
        <div
          className="card-body"
          style={{
            overflowY: "scroll",
          }}
        >
          <p className="card-text">
            Use this tool to verify that all access that was&nbsp;
            <strong>expected</strong> to end in a given period&nbsp;
            <strong>has now</strong> ended.
          </p>
          <p className="card-text">
            Note that this tool will not tell you when access ended, if it
            ended; it simply checks whether there is access at this time.
          </p>
          <hr />
          <form
            id="search-filter-form"
            aria-labelledby="sidebar-header"
            name="search-filter"
            ref={formRef}
            onInput={maybeUpdateFormState}
            onSubmit={handleSubmit}
          >
            <label className="form-label" htmlFor="ended-at-start">
              Access should have ended after
            </label>
            <input
              aria-errormessage="ended-at-start-invalid-feedback"
              aria-invalid={formState.invalidFields.includes("ended-at-start")}
              className={
                formState.invalidFields.includes("ended-at-start")
                  ? "form-control is-invalid"
                  : "form-control mb-3"
              }
              type="datetime-local"
              id="ended-at-start"
              name="ended-at-start"
              defaultValue={defaultEndedAtStart}
              required={true}
            />
            <div
              id="ended-at-start-invalid-feedback"
              className="invalid-feedback mb-2"
            >
              After time must be earlier than before time.
            </div>
            <label className="form-label" htmlFor="ended-at-end">
              Access should have ended before
            </label>
            <input
              aria-errormessage="ended-at-end-invalid-feedback"
              aria-invalid={formState.invalidFields.includes("ended-at-end")}
              className={
                formState.invalidFields.includes("ended-at-end")
                  ? "form-control is-invalid"
                  : "form-control mb-1"
              }
              type="datetime-local"
              id="ended-at-end"
              name="ended-at-end"
              defaultValue={defaultEndedAtEnd}
              required={true}
            />
            <div
              id="ended-at-end-invalid-feedback"
              className="invalid-feedback"
            >
              After time must be earlier than before time.
            </div>
          </form>
        </div>
        <div className="card-footer d-flex justify-content-center">
          {applyButton}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
